import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CountdownAlertComponent } from 'src/app/utility/countdown-alert/countdown-alert/countdown-alert.component'
import { AlertDialogComponent } from '../utility/alert-dialog/alert-dialog.component';
import { ContractReplaceOrNoComponent } from '../utility/contractReplaceAlert/contract-replace-or-no.component';
import { PromptDialogComponent } from '../utility/prompt-dialog/prompt-dialog.component';

@Injectable({
    providedIn: 'root'
  })
  export class EdiUtilityService {
  
    constructor(public dialog: MatDialog) { }
  
    //open prompt
  
    openPrompt(promptTitle: string, promptMessage: string) {
  
      const promptDialogRef = this.dialog.open(PromptDialogComponent, {
        disableClose: true,
        data: {
          promptTitle: promptTitle,
          promptMessage: promptMessage
        }
      });
  
      return promptDialogRef.afterClosed();
    }
  
  
    //show respective alerts
    showAlert(alertType: any, alertTitle: any, alertMessage: any) {
     const alertDialogRef= this.dialog.open(AlertDialogComponent, {
      disableClose: true,
        data: {
          alertType: alertType,
          alertTitle: alertTitle,
          alertMessage: alertMessage
        }, width: '20%'
      });
  
      return alertDialogRef.afterClosed();
  
    }
  
    //show respective alerts
    showCountDownAlert(alertType: any, alertTitle: any, alertMessage: any) {
      const alertDialogRef= this.dialog.open(CountdownAlertComponent, {
        disableClose: true,
         data: {
           alertType: alertType,
           alertTitle: alertTitle,
           alertMessage: alertMessage
         }, width: '20%'
       });
   
       return alertDialogRef.afterClosed();
   
     }
  
     showContractReplacementAlert(alertType: any, alertTitle: any, alertMessage: any) {
      const alertDialogRef= this.dialog.open(ContractReplaceOrNoComponent, {
        disableClose: true,
         data: {
           alertType: alertType,
           alertTitle: alertTitle,
           alertMessage: alertMessage
         }, width: '20%'
       });
   
       return alertDialogRef.afterClosed();
   
     }
  
     AllowOnlyNumber(event: any) {
  
      var inputValue = String.fromCharCode(event.keyCode);
      // Allow numbers, alpahbets, space, underscore
      if (/[0-9]/.test(inputValue)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
     
    AllowOnlyCharacter(event: any) {
  
      // Allow numbers, alpahbets, space, underscore
      if (/^[A-Z-a-z]$/.test(event.key)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  
    AllowDecimal(event: any) {
  
      // Allow numbers, alpahbets, space, underscore
      if (/^[0-9-.]$/.test(event.key)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
  
  
  }