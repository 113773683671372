<span class="status-badge" 
    [class.status-error]="type === 'error'"
    [class.status-success]="type === 'success'"
    [class.status-inprogress]="type === 'inprogress'"
    [class.status-pending]="type === 'pending'"
    [style.cursor]="needCursor ? 'pointer':'default'"
    
     >

    {{ btnLabel }}
</span>