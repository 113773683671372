import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  private detailsSubject = new BehaviorSubject<any>(null);
  details$ = this.detailsSubject.asObservable();

  setDetails(details: any) {
    this.detailsSubject.next(details);
  }
}
