<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div class="dialog-headline">
    <mat-label style="float:left;">Map Vendor</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<!-- <h2 mat-dialog-title style="background-color: rgb(38, 142, 226);">Install Angular</h2> -->
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

    <!-- user form taking values -->
    <mat-tab-group
    color="primary"
    mat-stretch-tabs
    [(selectedIndex)]="selectedIndex"
    style="height: 100%;"
  >
  <mat-tab disabled label="Vendor Information">
    <div style="height: 100%; display: flex; flex-direction: column;">
      <app-vendor-information
        [receivedVendorInfo]="dbVendorInfoData"
        [isFromMapping]="true"
        [isUpdate]="isUpdate"
        [isCreate]="isCreate"
        (getFullMasterVendor)="getFullMasterVendor($event)"
        [disableFields]="true"
        #vendorInformation
        style="flex: 1; overflow: auto;"
      >
      </app-vendor-information>
    </div>
  </mat-tab>

  <mat-tab disabled label="Vendor Connection">
    <div style="height: 100%; display: flex; flex-direction: column;">
      <app-vendor-connection
        [receivedVendorConn]="dbVendorConnection"
        [disableFields]="true"
        #vendorConnection
        [isFromMapping]="true"
        style="flex: 1; overflow: auto;"
      >
      </app-vendor-connection>
    </div>
  </mat-tab>

  <mat-tab disabled label="Vendor Fields">
    <div style="height: 100%; display: flex; flex-direction: column;">
      <app-vendor-fields
        [receivedVendorFields]="dbVendorFields"
        [disableFields]="true"
        #vendorFields
        style="flex: 1; overflow: auto;"
      >
      </app-vendor-fields>
    </div>
  </mat-tab>
</mat-tab-group>

  


</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">

  <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
  80px;height: 40px;color: #FFFFFF; border-radius:
  4px;font-size: 14px;text-align: center;line-height: 20px;">
  Close
</button>

<button mat-stroked-button  *ngIf="selectedIndex > 0" (click)="previousStep()" style="background-color: #6C747E;width:
  80px;height: 40px;color: #FFFFFF; border-radius:
  4px;font-size: 14px;text-align: center;line-height: 20px;">
  <mat-icon>arrow_back</mat-icon>Back
</button>

<button mat-flat-button color="primary" (click)="moveToNextTab()"
*ngIf="selectedIndex!=maxNumberOfTabs"
    style="background-color: #F59120;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
   > Next<mat-icon>arrow_forward</mat-icon></button>

<button mat-flat-button color="primary"
*ngIf="selectedIndex==2 && !isUpdate" (click)="mapFullVendorWithNPI();"
    style="background-color: #F59120;width:
    130px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
   > Map with NPINo<mat-icon></mat-icon></button>

<button mat-flat-button color="primary"
*ngIf="selectedIndex==0 && isUpdate" (click)="updateVendorInformation();" matTooltip="Update vendor information"
    style="background-color: #F59120;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
  > Update<mat-icon></mat-icon></button>

<button mat-flat-button color="primary"
*ngIf="selectedIndex==1 && isUpdate" (click)="updateVendorConnection();" matTooltip="Update vendor connection"
    style="background-color: #F59120;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
  > Update<mat-icon></mat-icon></button>




</mat-dialog-actions>
