import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ediconstant } from 'src/app/constant/ediconstant';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class GetFilesNowService {

  constructor(private httpClient: HttpClient,private errorHandlerService: ErrorHandlerService) { }

  getNowFileDownload(): Observable<any> {
    return this.httpClient.post<any>(ediconstant.fileDownloadGetNowScheduler, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getNowPriceUpdate832(): Observable<any> {
    return this.httpClient.get<any>(ediconstant.getNowPrice832Scheduler, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getNowOrder810(): Observable<any> {
    return this.httpClient.get<any>(ediconstant.getNowOrder810Scheduler, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getNowOrder855(): Observable<any> {
    return this.httpClient.get<any>(ediconstant.getNowOrder855Scheduler, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

}
