import { Pagination } from "./Pagination";

export class StoreRequest {

    NPINo: string="";
    pseudoName: string="";
    isEdiv2: boolean = false;
    isMpos: boolean = false;
    isPrimeRxCloud: boolean = false;
    pagination: Pagination = new Pagination;
}