<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">

        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header [icon]="'assets/images/icons/icon-admin-blue.png'" [title]="'Pharmacy Information'"
                [breadcrumb]="''"></app-page-header>
        </div>

    </div>
</div>

<mat-card>
    <br>
    <div class="row page-titles margin-left">
        <div class="form-group row">
            <h3>Applications</h3>
            <div class="col-md-2 ml-2 mt-3">
                <mat-checkbox [(ngModel)]="store.isMpos" name="ePrimePOS" disabled>
                    PrimeRX POS Pharmacy ?
                </mat-checkbox>
            </div>
            <div class="col-md-2 ml-2 mt-3">
                <mat-checkbox [(ngModel)]="store.isEdiv2" name="EDIV2" disabled>
                    Edi Cloud Pharmacy ?
                </mat-checkbox>
            </div>

            <div class="col-md-2 ml-2 mt-3">
                <mat-checkbox [(ngModel)]="store.isPrimeRxCloud" name="isPrimeRxCloud" disabled>
                    PrimeRxCloud Pharmacy ?
                </mat-checkbox>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-2 ml-2 mt-3">
                <mat-form-field>
                    <input matInput placeholder="No.Of terminals" name="noOfTerminals" #noOfTerminals="ngModel"
                        id="noOfTerminals" [(ngModel)]="store.terminal" required disabled>

                </mat-form-field>
            </div>
        </div>


        <div fxLayout="form-group row">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Store No" name="storeNo" #storeNo="ngModel" id="storeNo"
                            [(ngModel)]="store.NPINo" required disabled>
                        <mat-error *ngIf="storeNo.invalid && (storeNo.dirty || storeNo.touched)"
                            class="invalid-feedback">
                            Please enter store name
                        </mat-error>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Short Name" name="ShortName" #ShortName="ngModel" id="ShortName"
                            [(ngModel)]="store.pseudoName" required disabled>

                    </mat-form-field>
                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Store Name" name="storeName" #storeName="ngModel" id="storeName"
                            [(ngModel)]="store.storeName" required disabled>
                        <mat-error *ngIf="storeName.invalid && (storeName.dirty || storeName.touched)"
                            class="invalid-feedback">
                            Please enter store name
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div fxLayout="form-group row">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox [(ngModel)]="store.isActive" name="isEnabled" disabled>
                        Enabled
                    </mat-checkbox>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">

                </div>
            </div>
        </div>

        <br><br>

        <div fxLayout="row wrap">
            
            <h3>Address</h3>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Address 1." name="address1" #address1="ngModel" id="address1"
                            [(ngModel)]="store.address1" disabled>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Address 2" name="address2" #address2="ngModel" id="address2"
                            [(ngModel)]="store.address2" disabled>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Owner" name="owner" #owner="ngModel" id="owner"
                            [(ngModel)]="store.owner" disabled>

                    </mat-form-field>

                </div>
            </div>
        </div>


        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="City" name="city" #city="ngModel" id="city"
                            [(ngModel)]="store.city" disabled>

                    </mat-form-field>
                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <mat-select placeholder="Select state" #state="ngModel" name="state" [(ngModel)]="store.state"
                            disabled>
                            <mat-option *ngFor="let eachType of stateArray" [value]="eachType.abbreviation">
                                {{ eachType.stateName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Zip Code" name="zipCode" #zipCode="ngModel" id="zipCode"
                            [(ngModel)]="store.zipCode" required maxlength="5" minlength="5"
                            ng-pattern="[0-9-_!@#$%&*-+()=,.?/';:]" disabled>

                    </mat-form-field>
                </div>
            </div>
        </div>


        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Contact Name" name="contactName" #contactName="ngModel"
                            id="contactName" [(ngModel)]="store.contactName" disabled>

                    </mat-form-field>
                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Contact Number" name="contactNumber" #contactNumber="ngModel"
                            id="contactNumber" [(ngModel)]="store.contactNo" required minlength="10"
                            mask='(000)000-0000' disabled>

                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Website" name="website" #website="ngModel" id="wbsite"
                            [(ngModel)]="store.website" disabled
                            pattern="[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}(.[a-z]{2,4})?\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?">

                    </mat-form-field>
                </div>
            </div>
        </div>



        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Fax" name="fax" #fax="ngModel" id="fax" [(ngModel)]="store.fax"
                            required mask='(000)000-0000' minlength="10" disabled>

                    </mat-form-field>
                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">


                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">

                </div>
            </div>
        </div>



        <div fxLayout="row wrap">
            <br>
            <h3>Admin</h3>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
                            [(ngModel)]="storeAdmin.userName" disabled>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Last Name" name="lastName" #lastName="ngModel" id="lastName"
                            [(ngModel)]="storeAdmin.lName" disabled>
                    </mat-form-field>

                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="First Name" name="firstName" #firstName="ngModel" id="firstName"
                            [(ngModel)]="storeAdmin.fName" disabled>

                    </mat-form-field>

                </div>
            </div>
        </div>


        <div fxLayout="row wrap">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Contact Number" name="contactNumber" #contactNumber="ngModel"
                            id="contactNumber" [(ngModel)]="storeAdmin.contactNo" required minlength="10"
                            mask='(000)000-0000' disabled>

                    </mat-form-field>
                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-checkbox [(ngModel)]="storeAdmin.isActive" name="isActive" disabled>
                        Is Active ?
                    </mat-checkbox>
                </div>
                <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">


                </div>
            </div>
        </div>

    </div>
</mat-card>