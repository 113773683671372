<div fxLayout="row wrap" style="padding-left: 15px;padding-right: 15px;padding-top: 15px">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" style="gap: 5px;">

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <app-page-header
                [icon]="'assets/images/icons/Icon-service-config-blue.png'"
                [title]="'Pharmacy Provider Configuration'"
                [breadcrumb]="''"
                style="margin: 0; padding: 0;"
            ></app-page-header>
            </div>
           
            <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                <mat-radio-group [(ngModel)]="searchProvider.providerID" name="selection">
                    <div fxFlex="auto" style="margin-right: 15px;">
                        <mat-radio-button value="1" style="margin: 0; padding-top: 5px;" class="custom-radio">
                            Twilio
                        </mat-radio-button>
                    </div>
                    <div fxFlex="auto">
                        <mat-radio-button value="2" style="margin: 0; padding-top: 5px;" class="custom-radio">
                            Bandwidth
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
            
            <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                <button  class="custom-blue-button"   type="button" id="btnSearch" style="width: 85px; height: 40px;"
                (click)="SearchProviderData()">
                <mat-icon color="white">search</mat-icon>Search
                </button>
            </div> -->
            
            
        </div>
        
       
        
        <div fxFlex="auto" fxLayoutAlign="end center" style="margin: 0; padding: 0;">
            <button class="custom-orange-button" type="button" id="btnOpenCreateOrganizationModal" matTooltip="Create New Pharamacy Provider Configuration"
                    style="width: 100px; height: 45px; margin: 0; padding: 0;" (click)="OpenProviderDetailsModal()">
                Create New
            </button>
        </div>
        
    </div>
</div>

<div fxLayout="row wrap" style="padding: 8px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;">
            <div fxFlex="auto" fxLayout="row" fxLayoutAlign="end center" class="search-container" style="text-align: right;">
                <fieldset style="display: flex; align-items: center; gap: 15px;">
                    <div style="position: relative; display: inline-block;">
                        <input type="text" name="NPI" #NPI="ngModel" id="NPI" 
                           [(ngModel)]="searchProvider.identifier" 
                           style="width: 150px;" placeholder="Pharmacy NPI #" maxlength="10" minlength="10" mask='0000000000'
                           (keypress)="keyPressNumeric($event)" matTooltip="Pharmacy NPI #"/>
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
                            *ngIf="searchProvider.identifier.trim().length>0" (click)="searchProvider.identifier=''" 
                            style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); width: 20px; height: 20px; min-width: 20px; line-height: 20px;">
                            <mat-icon style="font-size: 12px; line-height: 20px;color: #0C7BB8;font-weight: bold;">close</mat-icon>
                        </button>
                    </div>
                    
                    <div style="position: relative; display: inline-block;">
                        <input type="text" name="nabp" #nabp="ngModel" id="nabp" 
                           [(ngModel)]="searchProvider.nabp" 
                           style="width: 150px;" placeholder="NABP" maxlength="7" minlength="7" mask='0000000000'
                           (keypress)="keyPressNumeric($event)" matTooltip="NABP" />
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
                            *ngIf="searchProvider.nabp.trim().length>0" (click)="searchProvider.nabp=''" 
                            style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); width: 20px; height: 20px; min-width: 20px; line-height: 20px;">
                            <mat-icon style="font-size: 12px; line-height: 20px;color: #0C7BB8;font-weight: bold;">close</mat-icon>
                        </button>
                    </div>

                    <div style="position: relative; display: inline-block;">
                        <input type="text" name="pharmacyName" #pharmacyName="ngModel" id="pharmacyName" 
                               [(ngModel)]="searchProvider.pharmacyName" 
                               style="width: 200px;" placeholder="Pharmacy Name" matTooltip="Pharmacy Name"/>
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
                            *ngIf="searchProvider.pharmacyName.trim().length>0" (click)="searchProvider.pharmacyName=''" 
                            style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); width: 20px; height: 20px; min-width: 20px; line-height: 20px;">
                            <mat-icon style="font-size: 12px; line-height: 20px;color: #0C7BB8;font-weight: bold;">close</mat-icon>
                        </button>
                    </div>
                    
                    <div style="position: relative; display: inline-block;">
                        <input type="text" name="tollFreePhoneNumber" #tollFreePhoneNumber="ngModel" id="tollFreePhoneNumber" 
                           [(ngModel)]="searchProvider.tollFreePhoneNumber" 
                           style="width: 150px;" placeholder="Toll Free #" maxlength="10" minlength="10" mask='0000000000'
                           (keypress)="keyPressNumeric($event)" matTooltip="Toll Free #" />
                        <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
                            *ngIf="searchProvider.tollFreePhoneNumber.trim().length>0" (click)="searchProvider.tollFreePhoneNumber=''" 
                            style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%); width: 20px; height: 20px; min-width: 20px; line-height: 20px;">
                            <mat-icon style="font-size: 12px; line-height: 20px;color: #0C7BB8;font-weight: bold;">close</mat-icon>
                        </button>
                    </div>

                    
                        
                    <mat-form-field style="width: 100px; padding-top: 20px;">
                        <mat-label>Provider</mat-label>
                        <mat-select #providerID="ngModel" name="providerID" [(ngModel)]="searchProvider.providerID" >
                            <mat-option *ngFor="let eachProvider of providersArray" [value]="eachProvider.providerID">
                                {{ eachProvider.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field style="width: 100px; padding-top: 20px;">
                        <mat-label>Status</mat-label>
                        <mat-select #statusCode="ngModel" name="statusCode" [(ngModel)]="activeValue" >
                            <mat-option *ngFor="let eachStatus of activeStatus" [value]="eachStatus.statusID">
                                {{ eachStatus.statusName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button class="custom-blue-button" type="button" id="btnSearch" style="width: 85px; height: 40px;"
                    (click)="SearchProviderData()" matTooltip="Search Pharamcy Provider Configuration">
                    <mat-icon color="white">search</mat-icon>Search
                    </button>
                    <button class="custom-orange-button" type="button" id="btnOpenCreateOrganizationModal" matTooltip="Migrate pharmacies from 'Twilio' to 'Bandwidth'"
                    style="width: 150px; height: 40px; margin: 0; padding: 0;" 
                    (click)="MigrateProvider()"
                    [disabled]="!anySelected">
                        Migrate to Bandwidth
                    </button>
                </fieldset>
                
            </div>
        </div>
        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
           
        </div>
    </div>
</div>


<!-- <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <label style="padding-left: 15px; padding-bottom: 10px; font-size: 14px; font-weight: bold; text-align: left; color: #F59120;">Total Pharamcy Configurations: {{providersNPIArray.length}}</label>
        </div>
    </div>
</div> -->
<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%; overflow-x: auto;">
                        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                            <!--select-->
                            <ng-container matColumnDef="select">
                                <mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox  matTooltip="Select all with provider Twilio" class="custom-checkbox" (change)="toggleSelectAll($event)" [checked]="allSelected" [indeterminate]="someSelected()"></mat-checkbox>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <mat-checkbox class="custom-checkbox" [(ngModel)]="element.selected" 
                                    [disabled]="element.providerID !== 1" 
                                    (change)="updateAnySelected()"></mat-checkbox>
                                </mat-cell>
                            </ng-container>

                            <!-- Serial Number Column -->
                            <ng-container matColumnDef="SerialNo">
                                <mat-header-cell *matHeaderCellDef style="padding: 2px 15px;"> 
                                    Sr. #
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index" style="padding: 2px 15px;"> {{ i + 1 }} </mat-cell>
                            </ng-container>

                            <!-- Identifier Column -->
                             <ng-container matColumnDef="identifier">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Pharmacy NPI # </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;"> {{element.identifier}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="nabp">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> NABP </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;"> {{element.nabp}} </mat-cell>
                            </ng-container>

                            
                            <ng-container matColumnDef="pharmacyName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Pharmacy Name </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;"> {{element.pharmacyName}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="address">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Pharmacy Address </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;"> {{element.address}} </mat-cell>
                            </ng-container>

                            <!-- Provider ID Column -->
                            <!-- <ng-container matColumnDef="providerID">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Provider </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;"> 
                                    {{ element.providerID === 1 ? 'Twilio' : (element.providerID === 2 ? 'Bandwidth' : '') }}
                                </mat-cell>

                            </ng-container> -->
                            <ng-container matColumnDef="providerID">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Provider </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;" [ngClass]="{
                                  'twilio': element.providerID === 1,
                                  'bandwidth': element.providerID === 2,
                                  'unknown-provider': element.providerID !== 1 && element.providerID !== 2
                                }">
                                  {{ element.providerID === 1 ? 'Twilio' : (element.providerID === 2 ? 'Bandwidth' : '') }}
                                </mat-cell>
                              </ng-container>
                              
                           
                            <!-- Active Column -->
                            <ng-container matColumnDef="active">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Active </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;">
                                    <mat-checkbox [checked]="element.active" disabled></mat-checkbox>
                                </mat-cell>
                            </ng-container>
                    
                            <!-- Toll Free Phone Number Column -->
                            <ng-container matColumnDef="tollFreePhoneNumber">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px;"> Toll Free # </mat-header-cell>
                                <mat-cell *matCellDef="let element"  mask='(000)000-0000' style="padding: 2px 15px;"> 
                                    ({{ element.tollFreePhoneNumber | slice:0:3 }}){{ element.tollFreePhoneNumber | slice:3:6 }}-{{ element.tollFreePhoneNumber | slice:6 }}
                                </mat-cell>
                            </ng-container>
                           
                    
                            <!-- Action Column -->
                            <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef style="padding: 2px 15px;"> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="padding: 2px 15px;">
                                    <app-table-edit-button [imageSrc]="''" [tooltip]="'Edit Pharamacy Provider Configuration'" [color]="'primary'" (buttonClick)="OpenProviderUpdateModal(element)">
                                    </app-table-edit-button>
                                    <!-- <app-table-delete-button [imageSrc]="''" [tooltip]="'Delete Provider'" [color]="'primary'" (buttonClick)="DeleteProviderDetails(element)">
                                    </app-table-delete-button> -->
                                </mat-cell>
                            </ng-container>
                    
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
                            </mat-row>
                        </mat-table>
                    </div>
                    

                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>