<form #vendorConnectionForm="ngForm" autocomplete="off">
  <br>

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Vendor Code" name="vendorCode" #vendorCode="ngModel" id="vendorCode"
            [(ngModel)]="vendorConnection.vendorCode" required disabled>
          <mat-error *ngIf="vendorCode.invalid && (vendorCode.dirty || vendorCode.touched)" class="invalid-feedback">
            Please enter vendor code
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
      </div>

    </div>
  </div>
  <!-- hiding the ftp fields if the diabled is true because we have to show it in the mapping form not in master form -->
  <div fxLayout="row wrap"> <!--3767-->
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Ftp Url" name="url" #url="ngModel" id="url" [(ngModel)]="vendorConnection.url"
            required [disabled]="isFromMapping"> <!--3767-->
          <mat-error *ngIf="url.invalid && (url.dirty || url.touched)" class="invalid-feedback">
            Please enter ftp url
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

        <mat-form-field>
          <input matInput placeholder="Ftp Port" (keypress)="AllowOnlyNumber($event)" name="port" #port="ngModel"
            id="port" [disabled]="isFromMapping" [(ngModel)]="vendorConnection.port" required><!--3767-->
          <mat-error *ngIf="port.invalid && (port.dirty || port.touched)" class="invalid-feedback">
            Please enter Ftp port
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <mat-label>Select Connection Mode</mat-label>
          <mat-select #ftpConnectionType="ngModel" id="ftpConnectionType" name="ftpConnectionType"
            (selectionChange)="onConnectionTypeChange();" [(ngModel)]="vendorConnection.ftpConnectionType" required [disabled]="isFromMapping"><!--3767-->
            <mat-option value="FTP">FTP</mat-option>
            <mat-option value="SFTP">SFTP</mat-option>
            <mat-option value="Local">Local</mat-option>

          </mat-select>


          <mat-error *ngIf="ftpConnectionType.invalid && (ftpConnectionType.dirty || ftpConnectionType.touched)"
            class="invalid-feedback">
            Please select ftp type
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <mat-label>Select Connection type</mat-label>
          <mat-select #connectionType="ngModel" id="connectionType" name="connectionType"
            [(ngModel)]="vendorConnection.connectionType" [required]="vendorConnection.ftpConnectionType=='FTP'" [disabled]="vendorConnection.ftpConnectionType=='SFTP' || vendorConnection.ftpConnectionType=='Local'">
            <mat-option value="ACTIVE">ACTIVE</mat-option>
            <mat-option value="PASSIVE">PASSIVE</mat-option>
            <mat-option value="Default">Default</mat-option>

          </mat-select>


          <mat-error *ngIf="connectionType.invalid && (connectionType.dirty || connectionType.touched)"
            class="invalid-feedback">
            Please select connection type
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Local Outbound Dir Name" name="localOutboundDirName"
            #localOutboundDirName="ngModel" id="localOutboundDirName"
            [(ngModel)]="vendorConnection.localOutboundDirName" [disabled]="disableFields">
        </mat-form-field>
      </div>

      <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Local Inbound Dir Name" name="localInboundDirName" #localInboundDirName="ngModel"
            id="localInboundDirName" [(ngModel)]="vendorConnection.localInboundDirName" [disabled]="disableFields">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="PO File(850) Format" name="poFileFormat" #poFileFormat="ngModel"
            id="poFileFormat" [(ngModel)]="vendorConnection.poFileFormat" [disabled]="disableFields">
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
