<!-- card for header bar-->
<div fxLayout="row wrap" style="padding: 15px;width: 100%;">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header
            [icon]="'assets/images/icons/icon-EDI-blue.jpeg'"
            [title]="'Scheduler'"
            [breadcrumb]="''"
            ></app-page-header>
        </div>
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100"></div>
        
        
        
    </div>
  </div>
  
  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                            <!-- Checkbox Column -->
            
                            <ng-container color="primary" matColumnDef="IntervalType">
                                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Scheduler Type </mat-header-cell>
                                <mat-cell color="primary" *matCellDef="let element"> {{element.IntervalType}} </mat-cell>
                            </ng-container>
            
                            <ng-container color="primary" matColumnDef="CronExpression">
                                <mat-header-cell color="primary" [fxHide]="true" *matHeaderCellDef mat-sort-header>Cron Expression
                                </mat-header-cell>
                                <mat-cell color="primary" [fxHide]="true" *matCellDef="let element"> {{element.CronExpression}} </mat-cell>
                            </ng-container>
            
                            <ng-container matColumnDef="CronDescription">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Scheduler Interval</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.CronDescription}} </mat-cell>
                            </ng-container>
            
            
                            <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef> Edit </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <!-- <button mat-icon-button matTooltip="Edit {{element.IntervalType}}"
                                        (click)="openCronDialog(element);" color="primary">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button> -->
                                    <app-table-edit-button
                                    [imageSrc]="''"
                                    [tooltip]="'Edit ' + element.IntervalType"
                                    [color]="'primary'"
                                    (buttonClick)="openCronDialog(element)">
                                    </app-table-edit-button>
                                </mat-cell>
                            </ng-container>
            
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
            
                        </mat-table>
                    </div>

                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
                </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>