import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { catchError, map } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { ErrorHandlerService } from '../error-handler.service';
@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }


  createFullVendor(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.createFullVendorUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getMasterVendor(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.getMasterVendorUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getMasterVendorConnectionAndField(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.getMasterVendorConnAndFieldsUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getVendorConnectionAndField(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.getVendorConnAndFieldsUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getVendorData(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.getVendorUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getVendorWithContractData(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.getVendorWithContractUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  saveVendorWithContractData(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.saveVendorWithContractUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getFullActiveVendorsByCode(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.getFullActiveVendorsByCodeUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  mapFullVendorWithNPI(vendorData: any) {
    return this.httpClient.post<any>(ediconstant.mapFullVendorWithNPINoUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  deleteMasterVendor(vendorData: any) {

    return this.httpClient.post<any>(ediconstant.deleteMasterVendorUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  updateMasterVendor(vendorData: any) {

    return this.httpClient.post<any>(ediconstant.updateMasterVendorUrl, vendorData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  updateVendorConnection(vendorConnData: any) {

    return this.httpClient.post<any>(ediconstant.updateVendorConnectionUrl, vendorConnData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  updateVendorInformation(vendorInfoData: any) {

    return this.httpClient.post<any>(ediconstant.updateVendorInformationUrl, vendorInfoData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getFTPScheduler() {

    return this.httpClient.get<any>(ediconstant.getFTPSchedulerUrl, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  SaveFTPScheduler(storeGeneralConfigData: any) {

    return this.httpClient.post<any>(ediconstant.saveFTPSchedulerUrl, storeGeneralConfigData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  createVendorContract(vendorWithContarct: any) {

    return this.httpClient.post<any>(ediconstant.saveVendorWithContractUrl, vendorWithContarct, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  deleteVendorContract(vendorWithContarct: any) {

    return this.httpClient.post<any>(ediconstant.deleteVendorWithContractUrl, vendorWithContarct, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  testVendorFtpConnection(vendorWithContarct: any) {
    return this.httpClient.post<any>(ediconstant.testVendorFtpConnectionUrl, vendorWithContarct, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  checkIfContractAlreadyExists(vendorWithContarct: any) {

    return this.httpClient.post<any>(ediconstant.checkIfContractAlreadyExists, vendorWithContarct, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
}
