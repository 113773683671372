<div class="dialog-headline">
  <!-- <mat-label style="float:left;">{{headerTitle}}</mat-label> -->
  <mat-label style="float:left;">Add Account</mat-label>

  <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

  <form #contractModalForm="ngForm" autocomplete="off">

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Account Name" name="contractName" #contractName="ngModel" id="contractName"
              [(ngModel)]="contract.contractName" required>
            <mat-error *ngIf="contractName.invalid && (contractName.dirty || contractName.touched)"
              class="invalid-feedback">
              Please Enter Account Name
            </mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>


    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="FTP/SFTP Username" name="contractUsername" #contractUsername="ngModel"
              id="contractUsername" [(ngModel)]="contract.contractUsername" required>
            <mat-error *ngIf="contractUsername.invalid && (contractUsername.dirty || contractUsername.touched)"
              class="invalid-feedback">
              Please Enter FTP/SFTP Username
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100">
        </div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="FTP/SFTP Password" name="contractPassword" #contractPassword="ngModel"
              autocomplete="new-password" id="contractPassword" [(ngModel)]="contract.contractPassword" required
              [type]="hideOldPwd ? 'password' : 'text'">
            <!-- <mat-icon class="pointer" matSuffix (click)="hideOldPwd = !hideOldPwd">{{hideOldPwd ?
                'visibility_off' :
                'visibility'}}
            </mat-icon> -->
            <img class="pointer" *ngIf="!hideOldPwd" width="24px" style="padding-bottom: 10px;text-align: right;"
              matSuffix src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
              (click)="hideOldPwd = !hideOldPwd">
            <img class="pointer" *ngIf="hideOldPwd" width="24px" style="padding-bottom: 10px;text-align: right;"
              matSuffix src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
              (click)="hideOldPwd = !hideOldPwd">
            <mat-error *ngIf="contractPassword.invalid && (contractPassword.dirty || contractPassword.touched)"
              class="invalid-feedback">
              Please Enter FTP/SFTP Password
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>


    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <button mat-flat-button matTooltip="Test Connection" style="background-color: #0C7BB8;"
            (click)="testVendorFtpSftpConnection()" color="primary"
            [disabled]="contract.contractName.length==0 || contract.contractUsername.length==0 || contract.contractPassword.length==0"
            type="button">
            Test Connection
          </button>
        </div>
      </div>
    </div>
    <br>
    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="ISA Interchange Sender ID" name="contractIsaInterchangeSenderId"
              #contractIsaInterchangeSenderId="ngModel" id="contractIsaInterchangeSenderId"
              [(ngModel)]="contract.contractIsaInterchangeSenderId" required>
            <mat-error
              *ngIf="contractIsaInterchangeSenderId.invalid && (contractIsaInterchangeSenderId.dirty || contractIsaInterchangeSenderId.touched)"
              class="invalid-feedback">
              Please Enter ISA Interchange Sender ID
            </mat-error>
          </mat-form-field>

        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="App Sender Code" name="contractAppSenderCode" #contractAppSenderCode="ngModel"
              id="contractAppSenderCode" [(ngModel)]="contract.contractAppSenderCode" required>
            <mat-error
              *ngIf="contractAppSenderCode.invalid && (contractAppSenderCode.dirty || contractAppSenderCode.touched)"
              class="invalid-feedback">
              Please Enter App Sender Code
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Indentification Code By" name="contractIdentificationCodeBy"
              #contractIdentificationCodeBy="ngModel" id="contractIdentificationCodeBy"
              [(ngModel)]="contract.contractIdentificationCodeBy" required>
            <mat-error
              *ngIf="contractIdentificationCodeBy.invalid && (contractIdentificationCodeBy.dirty || contractIdentificationCodeBy.touched)"
              class="invalid-feedback">
              Please Enter Indentification Code By
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100">
        </div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Price File Format" name="contractPriceFileFormat"
              #contractPriceFileFormat="ngModel" id="contractPriceFileFormat"
              [(ngModel)]="contract.contractPriceFileFormat" required>
            <mat-error
              *ngIf="contractPriceFileFormat.invalid && (contractPriceFileFormat.dirty || contractPriceFileFormat.touched)"
              class="invalid-feedback">
              Please Price File Format
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <br>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Invoice File Format" name="contractFileFormat810"
              #contractFileFormat810="ngModel" id="contractFileFormat810" [(ngModel)]="contract.contractFileFormat810"
              required>
            <mat-error
              *ngIf="contractFileFormat810.invalid && (contractFileFormat810.dirty || contractFileFormat810.touched)"
              class="invalid-feedback">
              Please Enter Invoice File Format
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100">
        </div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="ACK File Format" name="contractAckFileFormat" #contractAckFileFormat="ngModel"
              id="contractAckFileFormat" [(ngModel)]="contract.contractAckFileFormat" required>
            <mat-error
              *ngIf="contractAckFileFormat.invalid && (contractAckFileFormat.dirty || contractAckFileFormat.touched)"
              class="invalid-feedback">
              Please Enter ACK File Format
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <br>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Outbound Dir Name" name="contractOutboundDirName"
              #contractOutboundDirName="ngModel" id="contractOutboundDirName"
              [(ngModel)]="contract.contractOutboundDirName" required>
            <mat-error
              *ngIf="contractOutboundDirName.invalid && (contractOutboundDirName.dirty || contractOutboundDirName.touched)"
              class="invalid-feedback">
              Please Enter Outbound Dir Name
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100">
        </div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Inbound Dir Name" name="contractInboundDirName"
              #contractInboundDirName="ngModel" id="contractInboundDirName"
              [(ngModel)]="contract.contractInboundDirName" required>
            <mat-error
              *ngIf="contractInboundDirName.invalid && (contractInboundDirName.dirty || contractInboundDirName.touched)"
              class="invalid-feedback">
              Please Enter Inbound Dir Name
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <br>
  </form>
</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">
  <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width: 
      80px;height: 40px;color: #FFFFFF; border-radius: 
      4px;font-size: 14px;text-align: center;line-height: 20px;">
    Close
  </button>

  <button mat-flat-button color="primary" (click)="createContract()" style="background-color: #F59120;width: 
      80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
    [disabled]="contractModalForm.invalid">Create</button>
</mat-dialog-actions>