import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, catchError, map, of, startWith, switchMap, tap } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { PharmacyConfiguration } from 'src/app/models/ProviderConfiguraion/PharmacyConfiguration';
import { ProviderService } from 'src/app/services/provider.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-service-provider-modal',
  templateUrl: './service-provider-modal.component.html',
  styleUrls: ['./service-provider-modal.component.css']
})
export class ServiceProviderModalComponent implements OnInit {

  pharmacyConfiguration: PharmacyConfiguration;
  npiCtrl: FormControl;
  filteredNPI: Observable<any[]>;
  providerNpiArray: any[] = [];
  token: string;
  tokenData: any;
  expiryTime: number = 0;
  providersArray: any[] = [];
  hideAccountSID: boolean = true;
  hideAuthToken: boolean = true;

  constructor( @Inject(MAT_DIALOG_DATA) public receivedData: any,
  private utilityService: UtilityService,
  private providerService: ProviderService,
  private dialogService: MatDialogRef<ServiceProviderModalComponent>,) { }

  ngOnInit(): void {
    this.token = sessionStorage.getItem(constant.AUTH0_TOKEN_KEY) || ''
    this.getAllProviders();

    if(this.receivedData!=null)
    {
      this.providerNpiArray=this.receivedData.allConfiguration;
      this.pharmacyConfiguration=this.receivedData.currentConfiguration;
      //this.tokenData=this.receivedData.tokenData;
      //this.token=this.receivedData.tokenData.access_token;
    }
    
if(this.pharmacyConfiguration == undefined)
  {
    this.pharmacyConfiguration={
      identifierType: 1,
      identifier: "",
      providerID: 1,
      pharmacyName: "",
      accountSID: "",
      authToken: "",
      active: true,
      tollFreePhoneNumber: "",
      address: "",
      nabp:""
      
    }
  }

    // this.IntializeConfigCtrl();
    // this.OnNpiSelection(this.pharmacyConfiguration.Identifier);
    // this.pharmacyConfiguration.PharmacyName="'jhjjkhj"
    
  }

  //Load providers'list
  getAllProviders(){
    const includeInActive:boolean=false;
    // if (Date.now() > this.expiryTime) {
    //   this.getAuthToken().pipe(
    //     switchMap(token => this.providerService.GetAllProviders(token, includeInActive))
    //   ).subscribe({
    //     next: (response) => {
    //       if (response) {
    //         this.providersArray = response;
    //         console.log("Received all providers")
    //       }
    //       else
    //       {
    //         console.log("Unable to received all providers")
    //         this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not load providers list");
    //       }
    //     },
    //     error: (errorResponse) => {
    //       //console.error(errorResponse);
    //       this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while loading providers list");
    //     }
    //   });
    // }
    // else
    // {
      this.providerService.GetAllProviders(this.token, includeInActive).subscribe({
        next: (response) => {
          if (response) {
            this.providersArray = response;
            console.log("Received all providers")
          }
          else
          {
            console.log("Unable to received all providers")
            this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not load providers list");
          }
        },
        error: (errorResponse) => {
          console.error(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while loading providers list");
        }
      });
    //}
  }
  //

  //#region For autocomplete NPI list
  ClearNPI() {
    this.npiCtrl.setValue("");
    this.pharmacyConfiguration.identifier = this.npiCtrl.value;
  }

  
  ClearNotMatchingNpi() {

    const npi = this.npiCtrl.value;
    const selctedNpi = this.providerNpiArray.find(provider => provider.identifier === npi)
    if (selctedNpi == undefined || selctedNpi == "" || selctedNpi == null) {
      this.npiCtrl.setValue("");
      this.pharmacyConfiguration.identifier = this.npiCtrl.value;
    }
  }

  OnNpiSelection(identifier: string) {
    const selectedProvider = this.providerNpiArray.find(provider => provider.identifier === identifier);
    if (selectedProvider) {
      this.pharmacyConfiguration.pharmacyName = selectedProvider.pharmacyName;
    } else {
      console.error('Pharmacy not found for identifier:', identifier);
      this.pharmacyConfiguration.pharmacyName = "";
    }
  }

  filterNpi(npi: string) {
    return this.providerNpiArray.filter(provider =>
      provider.identifier.includes(npi)
    );
  }

  IntializeConfigCtrl() {

    if (this.pharmacyConfiguration && this.pharmacyConfiguration.identifier) {
      this.npiCtrl = new FormControl(this.pharmacyConfiguration.identifier);

    } else {

      this.npiCtrl = new FormControl();
    }

    if (!this.npiCtrl.value) {
      this.npiCtrl.setValue("");
    }

    this.filteredNPI = this.npiCtrl.valueChanges.pipe(
      startWith(this.npiCtrl.value),
      map((npi) =>
        npi ? this.filterNpi(npi) : this.providerNpiArray.slice()
      )
    );
  }

  //#endregion autocomplete NPI list

  //#region Get Auth0 token
  // getAuthToken(): Observable<string> {
  //   return new Observable<string>((observer) => {
  //     this.providerService.getToken().subscribe({
  //       next: (response) => {
  //         if (response) {
  //           this.token = response.access_token;
  //           this.tokenData = response;
  //           this.expiryTime = Date.now() + (this.tokenData.expires_in * 1000);
  //           observer.next(this.token);
  //           observer.complete();
  //         } else {
  //           this.token = "";
  //           observer.error(new Error("Failed to retrieve token"));
  //         }
  //       },
  //       error: (error) => {
  //         this.token = "";
  //         console.error(error);
  //         observer.error(error);
  //       }
  //     });
  //   });
  // }
  //#endregion
  
  //Check if provider configuration already exists for the NPI enterd
  checkNPINoExists() {
   
  }

  isNPINoExists(): Observable<boolean> {
    // Create an Observable to handle the asynchronous nature of the HTTP request
    return new Observable<boolean>((observer) => {

      const lst = this.providerNpiArray.filter(item => item.identifier === this.pharmacyConfiguration.identifier);
      if (lst.length > 0) {
        const isNPINoExists=true;
        observer.next(isNPINoExists);
        observer.complete();
      }
      else
      {
        const isNPINoExists=false;
        observer.next(isNPINoExists);
        observer.complete();
      }

    });
  }

  // getPharmacyName() {
  //   console.log("Provider NPI Array:", this.providerNpiArray);
  //   console.log("Pharmacy Configuration Identifier:", this.pharmacyConfiguration.identifier);

  //   const lst = this.providerNpiArray.filter(item => item.identifier === this.pharmacyConfiguration.identifier);

  //   console.log("Filtered List:", lst);

  //   if (lst.length > 0) {
  //       this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Provider configuration is already available for this NPI");
  //   }
  //   else{
  //     if (this.pharmacyConfiguration.identifier.trim().length>0)
  //       this.pharmacyConfiguration.pharmacyName="Test Pharmacy "+this.pharmacyConfiguration.identifier
  //   }
    
  // }
  checkAndCallGetPharmacyName() {

    // if(!this.isNPINoExists())
    // {
    //   this.pharmacyConfiguration.pharmacyName = "";
    //   this.pharmacyConfiguration.address = "";
    //   if (this.pharmacyConfiguration.identifier && this.pharmacyConfiguration.identifier.trim() !== '') {
    //     this.getPharmacyName();
    //   }
    // }
    // else
    // {
    //   this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Configuration alrady exists for this NPI");
    // }

    this.pharmacyConfiguration.pharmacyName = "";
    this.pharmacyConfiguration.address = "";
    if (this.pharmacyConfiguration.nabp && this.pharmacyConfiguration.nabp.trim() !== '') {
      this.getPharmacyName();
    }
    
  }
  getPharmacyName(){
    const includeInActive:boolean=false;
    const identifierType: string="2";
    const IdentifierID:string=this.pharmacyConfiguration.nabp
    // if (Date.now() > this.expiryTime) {
    //   this.getAuthToken().pipe(
    //     switchMap(token => this.providerService.GetPharmacyNameByNpi(token, this.pharmacyConfiguration.identifier))
    //   ).subscribe({
    //     next: (response) => {
    //       if (response) {
    //         const result = response.result ? JSON.parse(response.result) : response;
    //         let pharmacies:any=[];
    //         pharmacies=result;
    //         if(pharmacies?.length>0)
    //         {
    //           this.pharmacyConfiguration.pharmacyName = pharmacies[0].title;
    //           this.pharmacyConfiguration.nabp = pharmacies[0].pharCode;
    //           this.pharmacyConfiguration.address = [
    //             pharmacies[0]?.streetAddress ?? '',
    //             pharmacies[0]?.city ?? '',
    //             pharmacies[0]?.state ?? '',
    //             pharmacies[0]?.country ?? '',
    //             pharmacies[0]?.zipCode ?? ''
    //           ].filter(part => part !== '').join(', ');
            
    //           console.log("Received pharamcy name and address")
    //         }
            
    //       }
    //       else
    //       {
    //         console.log("Unable to received pharamcy name and address")
    //         this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not fetch pharamcy name and address");
    //       }
    //     },
    //     error: (errorResponse) => {
    //       //console.error(errorResponse);
    //       this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while fetching pharamcy name and address");
    //     }
    //   });
    // }
    // else
    // {
      this.providerService.GetPharmacyNameByNpi(this.token, identifierType, IdentifierID).subscribe({
        next: (response) => {
          if (response) {
            const result = response.result ? JSON.parse(response.result) : response;
            let pharmacies:any=[];
            pharmacies=result;
            if(result.statusCode==3)
            {
              this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not fetch pharamcy details for NABP "+this.pharmacyConfiguration.nabp);
            }
            else if(pharmacies?.length>0)
            {
              this.pharmacyConfiguration.pharmacyName = pharmacies[0].title;
              this.pharmacyConfiguration.identifier = pharmacies[0].npi;
              this.pharmacyConfiguration.address = [
                pharmacies[0]?.streetAddress ?? '',
                pharmacies[0]?.city ?? '',
                pharmacies[0]?.state ?? '',
                pharmacies[0]?.country ?? '',
                pharmacies[0]?.zipCode ?? ''
              ].filter(part => part !== '').join(', ');
            
              console.log("Received pharamcy name and address")
            }
          }
          else
          {
            console.log("Unable to received pharamcy name and address")
            this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not fetch pharamcy name and address");
          }
        },
        error: (errorResponse) => {
          console.error(errorResponse);
          //this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while fetching pharamcy name and address");
          this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Could not fetch pharamcy details for NABP "+this.pharmacyConfiguration.nabp);
        }
      });
    //}
  }

  UpdateProvider() {
   
    // if (Date.now() > this.expiryTime) {
    //   this.getAuthToken().pipe(
    //     switchMap(token =>  this.providerService.UpdateProviderConfig(token,this.pharmacyConfiguration))
    //   ).subscribe({
    //     next: (response) => {
          
    //       if (response) {
    //         const result = response.result ? JSON.parse(response.result) : response;
    //         if (result.statusCode==0) {
    //           //this.pharmacyConfiguration.pharmacyName = response.pharmacyName
    //           this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
    //         }
    //         else
    //         {
    //           this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
    //         } 
    //       } else {
    //         this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Provider configuration could not be updated");
    //       }
    //     },
    //     error: (errorResponse) => {
    //       console.error(errorResponse);
    //       this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while updating providers data");
    //     }
    //   });
    // } 
    // else {
      this.providerService.UpdateProviderConfig(this.token,this.pharmacyConfiguration).subscribe({
        next: (response) => {
          if (response) {
            const result = response.result ? JSON.parse(response.result) : response;
            if (result.statusCode==0) {
              this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, "Provider configuration updated successfully");
            } 
            else
            {
              this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
            } 
          } else {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Provider configuration could not be updated");
          }
        },
        error: (errorResponse) => {
          console.error(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while loading providers data");
        }
      });
    //}
     
    
  }

  CreateProvider(pharmacyConfiguration: PharmacyConfiguration){
    //let isNPINoExists=true;
    // const lst = this.providerNpiArray.filter(item => item.identifier === this.pharmacyConfiguration.identifier);
    //   if (lst.length > 0) {
    //     isNPINoExists=true;
    //   }
   
    // if (Date.now() > this.expiryTime) {
    //   this.getAuthToken().pipe(
    //     switchMap(token =>  this.providerService.InsertProviderConfig(token,this.pharmacyConfiguration))
    //   ).subscribe({
    //     next: (response) => {
    //       if (response) {
    //         const result = response.result ? JSON.parse(response.result) : response;
    //         if (result.statusCode==0) {
    //           this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
    //         }
    //         else if (result.statusCode==4) {
    //           this.utilityService.showAlert(constant.KEY_WARNING, constant.FAILURE, result.statusDescription);
    //         } 
    //         else
    //         {
    //           this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
    //         } 
    //       } else {
    //         this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Provider configuration could not be created");
    //       }
    //     },
    //     error: (errorResponse) => {
    //       console.error(errorResponse);
    //       this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while adding providers data");
    //     }
    //   });
    // }
    // else {
      this.providerService.InsertProviderConfig(this.token, this.pharmacyConfiguration).subscribe({
        next: (response) => {
          if (response) {
            const result = response.result ? JSON.parse(response.result) : response;
            if (result.statusCode==0) {
              this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
            } 
            else if (result.statusCode==4) {
              this.utilityService.showAlert(constant.KEY_WARNING, constant.FAILURE, result.statusDescription);
            } 
            else
            {
              this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
            } 
          } else {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Provider configuration could not be created");
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    //}
    
  }

  DeleteProviderDetails(){
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
        // if (Date.now() > this.expiryTime) {
        //   this.getAuthToken().pipe(
        //     switchMap(token =>  this.providerService.DeleteProviderConfig(token,this.pharmacyConfiguration))
        //   ).subscribe({
        //     next: (response) => {
        //       if (response) {
        //         const result = response.result ? JSON.parse(response.result) : response;
        //         if (result.statusCode==0) {
        //           this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
        //         } 
        //         else
        //         {
        //           this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
        //         } 
        //       } else {
        //         this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Provider configuration could not be deleted");
        //       }
        //     },
        //     error: (errorResponse) => {
        //       console.error(errorResponse);
        //       this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, "Error while deleting providers data");
        //     }
        //   });
        // }
        // else
        // {
          this.providerService.DeleteProviderConfig(this.token, this.pharmacyConfiguration).subscribe({
            next: (response) => {
              if (response) {
                const result = response.result ? JSON.parse(response.result) : response;
                if (result.statusCode==0) {
                  this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
                } 
                else
                {
                  this.utilityService.showAlert(constant.KEY_SUCCESS, constant.SUCCESS, result.statusDescription);
                } 
              } else {
                  this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Provider configuration could not be deleted");
                }
            },
            error: (errorResponse) => {
              console.log(errorResponse);
              this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
            }
          });
        //}
      }
    });
   
  }

  AllowOnlyNumber(event: any) {

    var inputValue = String.fromCharCode(event.keyCode);
    // Allow numbers
    if (/[0-9]/.test(inputValue)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  omit_special_char(val: any) {
    var k;
    document.all ? (k = val.keyCode) : (k = val.which);
    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57);
  }

  onPharmacyNpiChange() {
    this.pharmacyConfiguration.pharmacyName="";
    this.pharmacyConfiguration.address = '';
  }


}
