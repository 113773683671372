<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div aria-hidden="true">
    <div class="dialog-headline">
        <mat-label class="headline-title">{{receivedData.headerTitle}}</mat-label>
        <mat-icon class="pointer close-icon" matTooltip="Close this dialog" mat-dialog-close>close</mat-icon>
    </div>

    <form #organizationModalForm="ngForm" autocomplete="off" data-keyboard="false" data-backdrop="static"
        aria-hidden="true">
        <mat-dialog-content class="mat-typography" style="padding-top: 20px;">

            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100"
                        *ngIf="receivedData.headerTitle=='Update Organization'">
                        <mat-form-field>
                            <input matInput placeholder="Organization Id" name="OrganizationId"
                                #OrganizationId="ngModel" id="OrganizationId" [(ngModel)]="Organization.organizationId"
                                disabled>
                            <!-- <mat-error
                                *ngIf="OrganizationId.invalid && (OrganizationId.dirty || OrganizationId.touched)"
                                class="invalid-feedback">
                                Please enter organization id.
                            </mat-error> -->
                        </mat-form-field>

                    </div>

                </div>
            </div>
            <div fxLayout="row wrap">
                <div *ngIf="receivedData.headerTitle=='Update Organization'" fxFlex.gt-lg="20" fxFlex.gt-md="20"
                    fxFlex.gt-xs="100" fxFlex="100"></div>
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <mat-form-field>
                        <input appTrimSpaceInput matInput placeholder="Name" name="Name" #Name="ngModel" id="Name" (keydown.enter)="$event.preventDefault()"
                            [(ngModel)]="Organization.name" required (keypress)="AllowAlphaNumericCharSpace($event)"
                            [disabled]="receivedData.headerTitle=='Update Organization'">
                        <mat-error *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="invalid-feedback">
                            Please enter Organization name
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                        <input matInput placeholder="Address 1." name="Address1" #Address1="ngModel" id="Address1" (keydown.enter)="$event.preventDefault()"
                            [(ngModel)]="Organization.address1" required
                            appTrimSpaceInput>
                        <mat-error *ngIf="Address1.invalid && (Address1.dirty || Address1.touched)"
                            class="invalid-feedback">
                            Please enter Address 1
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <mat-form-field>
                        <input matInput placeholder="Address 2" name="Address2" #Address2="ngModel" id="Address2" (keydown.enter)="$event.preventDefault()"
                            [(ngModel)]="Organization.address2"
                            appTrimSpaceInput>
                    </mat-form-field>

                </div>
            </div>

            <!--Div For State City and Zip--> <!--EPPOS-3755-->
              <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <!--Zip--><!--EPPOS-3826-->
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                      <mat-form-field>
                          <input matInput placeholder="Zip Code" name="Zip" #Zip="ngModel" id="Zip"
                              (blur)="fetchStateAndCity()"
                              [(ngModel)]="Organization.zip" required maxlength="5" minlength="5" mask='00000'>
                          <mat-error *ngIf="Zip.invalid && (Zip.dirty || Zip.touched)" class="invalid-feedback">
                              Please enter zip Code
                          </mat-error>
                      </mat-form-field>
                    </div>

                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

                    <!--City-->
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                      <mat-form-field>
                          <input matInput placeholder="Select City" aria-label="City" [matAutocomplete]="autoCity"
                              [formControl]="cityCtrl" required />
                          <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
                              *ngIf="cityCtrl.value" (click)="ClearCity()">
                              <mat-icon>close</mat-icon>
                          </button>

                          <mat-autocomplete #autoCity="matAutocomplete" (optionSelected)="OnCitySelection()">
                              <mat-option *ngFor="let city of filteredCity | async" [value]="city.name"
                                  [disabled]="city.name === 'No Matching City Found'">
                                  <span>{{ city.name }}</span>
                              </mat-option>
                          </mat-autocomplete>

                          <mat-error *ngIf="cityCtrl.invalid && (cityCtrl.dirty || cityCtrl.touched)"
                              class="invalid-feedback">
                              Please select City
                          </mat-error>
                      </mat-form-field>

                    </div>

                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

                    <!--State-->
                    <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">

                        <mat-form-field>
                            <input matInput placeholder="Select State" aria-label="State" [matAutocomplete]="autoState"
                                [formControl]="stateCtrl" required />
                            <button mat-button matSuffix mat-icon-button aria-label="Clear" matTooltip="Clear"
                                *ngIf="stateCtrl.value" (click)="ClearState()">
                                <mat-icon>close</mat-icon>
                            </button>

                            <mat-autocomplete #autoState="matAutocomplete"
                                (optionSelected)="loadCityForCurrentState(stateCtrl.value)">
                                <mat-option *ngFor="let state of filteredState | async" [value]="state.stateName"
                                    [disabled]="state.stateName === 'No Matching State Found'">
                                    <span>{{ state.stateName }}</span>
                                </mat-option>
                            </mat-autocomplete>

                            <mat-error *ngIf="stateCtrl.invalid && (stateCtrl.dirty || stateCtrl.touched)"
                                class="invalid-feedback">
                                Please select a State
                            </mat-error>
                        </mat-form-field>

                    </div>

                </div>
              </div>
            <!--Div For State City and Zip-->



            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="Contact Person" name="ContactPerson" #ContactPerson="ngModel" (keydown.enter)="$event.preventDefault()"
                                (keypress)="AllowAlphaNumericCharSpace($event)" id="ContactPerson"
                                [(ngModel)]="Organization.contactPerson" required appTrimSpaceInput>
                            <mat-error *ngIf="ContactPerson.invalid && (ContactPerson.dirty || ContactPerson.touched)"
                                class="invalid-feedback">
                                Please enter contact person
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="Phone No" name="PhoneNo" #PhoneNo="ngModel" id="PhoneNo" (keydown.enter)="$event.preventDefault()"
                                [(ngModel)]="Organization.phoneNo" required minlength="10" mask='(000)000-0000'>
                            <mat-error *ngIf="PhoneNo.invalid && (PhoneNo.dirty || PhoneNo.touched)"
                                class="invalid-feedback">
                                Please enter contact Name
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>

            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <!-- <input type="email" matInput placeholder="Email" name="Email" #Email="ngModel" id="Email"
                                [(ngModel)]="Organization.email" required
                                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"> -->


                            <input matInput placeholder="Email" name="Email" #Email="ngModel" id="Email" (keydown.enter)="$event.preventDefault()"
                                [(ngModel)]="Organization.email" required
                                pattern="^(?!.*@.*@)[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$"
                                appTrimSpaceInput>
                            <mat-error *ngIf="Email.invalid && (Email.dirty || Email.touched) || Email.errors?.pattern"
                                class="invalid-feedback">
                                Please enter valid email
                            </mat-error>

                        </mat-form-field>

                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="Website" name="Website" #Website="ngModel" id="Website" (keydown.enter)="$event.preventDefault()"
                                type="url" [(ngModel)]="Organization.website"
                                pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)">
                            <mat-error *ngIf="Website.errors?.pattern" class="invalid-feedback">
                                Please enter valid Website
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

                </div>
            </div>

        </mat-dialog-content>
        <!-- for adding line -->
        <hr>
        <mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">

            <button mat-flat-button color="warn" (click)="DeleteOrganization(Organization)"
                *ngIf="receivedData.headerTitle=='Update Organization'" style="background-color: #6C747E;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
                [disabled]="organizationModalForm.invalid">Delete</button>

            <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
    80px;height: 40px;color: #FFFFFF; border-radius:
    4px;font-size: 14px;text-align: center;line-height: 20px;">
                Close
            </button>

            <button mat-flat-button color="primary" (click)="InsertOrganization(Organization)"
                *ngIf="receivedData.headerTitle=='Create Organization'" style="background-color: #F59120;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
                [disabled]="organizationModalForm.invalid">Create</button>

            <button mat-flat-button color="primary" (click)="UpdateOrganization(Organization)"
                *ngIf="receivedData.headerTitle=='Update Organization'" style="background-color: #F59120;width:
    80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
                [disabled]="organizationModalForm.invalid">Update</button>

        </mat-dialog-actions>

    </form>
</div>
