import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { VendorFields } from 'src/app/models/VendorFields';

@Component({
  selector: 'app-vendor-fields',
  templateUrl: './vendor-fields.component.html',
  styleUrls: ['./vendor-fields.component.css']
})
export class VendorFieldsComponent implements OnInit, OnChanges {

  user: any = {};
  vendorFields: VendorFields = {
    mId: '',
    NPINo: '',
    vendorCode: '',
    segmentSeperator: '',
    elementSeperator: '',
    subElementSeperator: '',
    isaIdQualifierSender: '',
    isaIdQualifierReceiver: '',
    isaInterchangeSenderId: '',
    isaInterchangeReceiverId: '',
    ieaInterchangeControlNo: '',
    isaTestIndicator: '',
    appSenderCode: '',
    appReceiverCode: '',
    POType: '',
    idCodeQualifierBy: 0,
    identificationCodeBy: '',
    idCodeQualifierSE: 0,
    identificationCodeSE: 0,
    productQualifier: '',
    isaInterchangeCtrlNo: '',
    isaAcknowledgementRequest: '',
    version: '',
    standardType: '',
    perName: '',
    perContactFunctionCode: '',
    perCommNumQualifier: '',
    perCommNum: '',
    amtAmountQualifier: '',
    AMT_Amount: '',
    priceItemQualifier: '',
    sellingPriceQualifier: '',
    useVICForEPO: 0,
    isaInterchangeCtrlVerNo: '',
    vendorRefQualifierNo: '',
    vendorRefNo: '',
    storeRefQualifierNo: '',
    storeRefNo: '',
    customerRefQualifierNo: '',
    customerRefNo: '',
    accountNo: '',
    divisionNo: '',
    application: '',
    copyOtherVendorField: '',
    isDelete: false,
    // fileExtension: [],
    isActive: false,
  };

  @ViewChild('vendorFieldsForm') public vendorFieldsForm!: NgForm;
  @Input() disableFields: boolean = false;
  @Input() receivedVendorCode: string = "";
  @Input() receivedVendorFields!: VendorFields;

  constructor() { }

  ngOnInit(): void {

    if (this.receivedVendorFields != undefined) {
      this.vendorFields = this.receivedVendorFields;
    }

  }
  ngOnChanges(changes: SimpleChanges) {

    if (changes['receivedVendorCode'] != undefined) {
      if(changes['receivedVendorCode'].currentValue)
      this.vendorFields.vendorCode = changes['receivedVendorCode'].currentValue;
    }

    if (changes['receivedVendorFields'] != undefined) {
      if(changes['receivedVendorFields'].currentValue)
      this.vendorFields = changes['receivedVendorFields'].currentValue;
    }
  }

  returnVendorFieldsData() {
    let retVendorData = {
      vendorFields: this.vendorFields,
      vendorFieldsForm: this.vendorFieldsForm
    }
    return retVendorData;
  }

}
