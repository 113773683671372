import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { EDIStoreAdminService } from 'src/app/services/edi_storeAdmin.service';
import { MPOSStoreAdminService } from 'src/app/services/mpos_storeAdmin.service';
import { StoreService } from 'src/app/services/store.service';
import { MMSAdminService } from 'src/app/services/mmsAdmin.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-store-admin-modal',
  templateUrl: './mms-admin-modal.component.html',
  styleUrls: ['./mms-admin-modal.component.css']
})
export class MMSAdminModalComponent implements OnInit {


  @Input() modal_title: any;
  @ViewChild('closebutton')
  closebutton!: { nativeElement: { click: () => void; }; };

  state: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, private http: HttpClient,
    private mmsAdminService: MMSAdminService, private router: Router, private EDIstoreAdminService: EDIStoreAdminService
    , private MPOSstoreAdminService: MPOSStoreAdminService, private utilityService: UtilityService,
    private storeService: StoreService, private dialogService: MatDialogRef<MMSAdminModalComponent>) { }
  MMSAdmin: any = {};
  message: any = "";
  storeData: any = {};
  userName: any = '';
  pseudoName: any = 'micromerchantsystems.com';
  hide: boolean = true;
  hideConfirm: boolean = true;
  showDetails: boolean = true;
  showError: boolean = false;
  panelOpenState: boolean = false;

  ngOnInit(): void {
    if (this.receivedData.userData != undefined) {
      this.MMSAdmin = this.receivedData.userData;

      this.storeData.userName = '';
      // this.Store.password = '';
      if (this.MMSAdmin.userName != null) {
        var name = this.MMSAdmin.userName.split("@");
        if (name.length == 2) {
          this.storeData.userName = name[0];
          this.pseudoName = name[1];
        }
      }
    }

  }

  InsertMMSAdmin(user: any) {

    user.modifiedBy = sessionStorage.getItem('userName');
    user.modifiedOn = new Date();

    user.userName = this.storeData.userName + '@' + this.pseudoName;
    user.NPINo = "0000000000";

    this.mmsAdminService.Insert(user).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.message = "MMS admin created successfully";
          this.utilityService.showAlert(constant.SUCCESS, "Created", this.message).subscribe(result => {
            this.dialogService.close(true);
          });

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          if (response.data != undefined && response.data != null) {
            const errorMsg = JSON.parse(response.data);

            this.utilityService.showAlert(constant.FAILURE, constant.FAILURE, errorMsg);
          } else {

            this.utilityService.showAlert(constant.FAILURE, "Failed", "Error in creating MMS admin");
          }
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  UpdateMMSAdmin(user: any) {

    user.userName = this.storeData.userName + '@' + this.pseudoName;

    this.mmsAdminService.Update(user).subscribe(response => {

      if (response.result == constant.SUCCESS) {
        this.message = "MMS admin updated successfully";

        this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {
          this.dialogService.close(true);
        });
      } else {
        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
      }

    }, error => {
      this.utilityService.showAlert(constant.FAILURE, "Failed", "Error in updating MMS admin");
    })

  }

  DeleteMMSAdmin(user: any) {
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };
    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
        user.isDelete = true;
        user.userName = this.storeData.userName + '@' + this.pseudoName;

        this.mmsAdminService.Delete(user).subscribe(response => {

          if (response.result == constant.SUCCESS) {
            this.message = "MMS admin deleted successfully";
            this.utilityService.showAlert(constant.SUCCESS, "Deleted", this.message).subscribe(result => {
              this.dialogService.close(true);
            });
          } else {
            this.utilityService.showAlert("ERROR", "Failed", "Failed to delete MMS admin");
          }
        }, error => {
          this.utilityService.showAlert("ERROR", "Failed", "Failed to delete MMS admin");

          console.log(error);
        })

      } else if (result.isDenied) {
      }
    })



  }

  keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9-_!@#$%&*()-+=,.?/'";: ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}