import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { Store } from 'src/app/models/Store';
import { EDIStoreAdminService } from 'src/app/services/edi_storeAdmin.service';
import { ManageRightsService } from 'src/app/services/manageRights.service';
import { RoleService } from 'src/app/services/role.service';
import { SessionService } from 'src/app/services/session.service';
import { StoreService } from 'src/app/services/store.service';
import { StoreAdminService } from 'src/app/services/storeAdmin.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
class pagination {
  pageNumber!: number;
  pageSize!: number;
}

class healthCheck {
  name!: string;
  health!: boolean;
  onStateFrom!: Date;
  lastExecution!: Date;
}
@Component({
  selector: 'app-health-monitor',
  templateUrl: './health-monitor.component.html',
  styleUrls: ['./health-monitor.component.css']
})
export class HealthMonitorComponent   {


  // // displayedColumns = ['Name', 'Health', 'OnStateFrom', 'LastExecution'];
  // displayedColumns = ['Name', 'Health', 'LastExecution'];

  // pageSizeOptions = [1, 5, 10, 15]
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;
  // dataSource: MatTableDataSource<healthCheck>;
  // selection = new SelectionModel<healthCheck>(true, []);
  // currentPage = 0;
  // pageSize = 10;
  // totalRows = 0;
  // pageData!: pagination;
  // storeArray: any = [];

  // healthCheckAPI: any = {};
  // id = 0;

  // constructor(public modalService: NgbModal, private http: HttpClient, private router: Router,
  //   private storeService: StoreService, private utilityService: UtilityService,
  //   private storeAdminService: StoreAdminService, private roleService: RoleService,
  //   private userService: UserService, private manageRightsService: ManageRightsService,
  //   private ediv2UserService: EDIStoreAdminService, 
  //   private vendorService: EDIVendorService) { }

  // ngOnInit(): void {
  //   this.storeArray = [];

  //   var storeObj: healthCheck = { name: 'Store Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   var storeAdminObj: healthCheck = { name: 'Store Admin Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   var userObj: healthCheck = { name: 'User Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   //   var roleObj: healthCheck = { name: 'Role Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   var manageRightsObj: healthCheck = { name: 'Manage Rights Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   var ediv2Obj: healthCheck = { name: 'EDIV2 User Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   //  var fileDownloadObj: healthCheck = { name: 'File Download Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   var vendorObj: healthCheck = { name: 'Vendor Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };
  //   // var vendroPollerObj: healthCheck = { name: 'Vendor Poller Service', health: false, onStateFrom: new Date(), lastExecution: new Date() };

  //   this.storeArray.push(storeObj);
  //   this.storeArray.push(storeAdminObj);
  //   this.storeArray.push(userObj);
  //   // this.storeArray.push(roleObj);
  //   this.storeArray.push(manageRightsObj);
  //   this.storeArray.push(ediv2Obj);
  //   //  this.storeArray.push(fileDownloadObj);
  //   this.storeArray.push(vendorObj);
  //   //this.storeArray.push(vendroPollerObj);

  //   this.storesOnLoad();
  //   setInterval(() => {
  //     this.storesOnLoad();
  //   }, 300000);

  // }


  // ngOnDestroy() {
  //   if (this.id) {
  //     clearInterval(this.id);
  //   }
  // }
  // storesOnLoad() {
  //   this.pageData = {
  //     pageNumber: this.currentPage + 1,
  //     pageSize: this.pageSize
  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'Store Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.storeService.GetStoresOnLoadPagination(this.pageData).subscribe(Response => {
  //     console.log(Response);

  //     let data = Response.result;
  //     var skinName = this.storeArray.find(x => x.name == "Store Service");

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     });

  //     //  this.storeArray.push(healthCheckAPI);
  //     this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getStoreAdminsOnLoad();


  //   }, error => {

  //     if (error.status == 400) {
  //       this.utilityService.showAlert("ERROR", "Failed", "Error in fetching stores");

  //     } else if (error.status == 500) {
  //       this.utilityService.showAlert("ERROR", "Failed", "Error in fetching stores");

  //     }
  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     });
  //     this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //     this.getStoreAdminsOnLoad();
  //     console.log(error);

  //   })

  // }

  // getStoreAdminsOnLoad() {
  //   this.pageData = {
  //     pageNumber: this.currentPage + 1,
  //     pageSize: this.pageSize
  //   };
  //   var lastExecutionTime = new Date();
  //   var serviceName = 'Store Admin Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.storeAdminService.GetStoreAdminOnLoadPagination(this.pageData).subscribe(Response => {
  //     console.log(Response);
  //     let data = Response.result;

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }
  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     // this.getRolesOnLoad();
  //     this.getUsersOnLoad();
  //   }, error => {

  //     if (error.status == 400) {
  //       this.utilityService.showAlert("ERROR", "Failed", error.error.data);

  //     } else if (error.status == 500) {
  //       this.utilityService.showAlert("ERROR", "Failed", "Internal Server Error");

  //     }
  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getUsersOnLoad();
  //     console.log(error);

  //   })
  // }

  // getUsersOnLoad() {

  //   let user = {
  //     NPINo: '9673535151'
  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'User Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.userService.getUsers(user).subscribe(Response => {
  //     let data = Response.result;

  //     let usersArray = [];
  //     usersArray = JSON.parse(Response.data);
  //     console.log(usersArray.length);
  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (usersArray.length != 0) {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }
  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getRolesByRight();

  //   }, error => {
  //     let usersArray = [];
  //     console.log(usersArray.length);
  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //     this.getRolesByRight();

  //     //  alert("error while fetching the user data")
  //   });
  // }

  // getRolesOnLoad() {
  //   let role = {
  //     NPINo: '9673535151'
  //   };
  //   var lastExecutionTime = new Date();
  //   var serviceName = 'Role Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.roleService.getRolesOnLoad(role).subscribe(res => {

  //     let data = res.result;

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }
  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getRolesByRight();

  //   }, error => {
  //     if (error.status == 400) {
  //       this.utilityService.showAlert("ERROR", "Failed", error.error.data);

  //     } else if (error.status == 500) {
  //       this.utilityService.showAlert("ERROR", "Failed", "Internal Server Error");

  //     }
  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //     this.getRolesByRight();
  //     console.log(error);
  //   });
  // }

  // getRolesByRight() {

  //   let role = {
  //     NPINo: '9673535151',
  //     role: 'Administrator',
  //     roleId: 915
  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'Manage Rights Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.manageRightsService.getRolesByRight(role).subscribe(res => {

  //     let data = res.result;

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }
  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getEDIV2Users();
  //   }, error => {


  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //     this.getEDIV2Users();


  //     // alert("error while fetching the roles")
  //   });
  // }


  // getEDIV2Users() {

  //   let user = {
  //     NPINo: '9673535151',

  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'EDIV2 User Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.ediv2UserService.getEDIV2User(user).subscribe(res => {

  //     let data = res.result;

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     });
  //     //  this.storeArray.push(healthCheckAPI);
  //     this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getVendorData();
  //   }, error => {


  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getVendorData();
  //   });
  // }

  // getFileDownloadData() {

  //   let file = {
  //     NPINo: '9673535151',
  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'File Download Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.fileDownloadService.GetFileDownloadData(file).subscribe(res => {

  //     let data = res.result;

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }
  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getVendorData();
  //   }, error => {


  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     this.getVendorData();

  //   });
  // }

  // getVendorData() {

  //   let vendor = {
  //     NPINo: '9673535151',
  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'Vendor Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.vendorService.GetVendorData(vendor).subscribe(res => {

  //     let data = res.result;

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     if (data == 'SUCCESS') {

  //       healthCheckAPI.health = true;

  //     } else {
  //       healthCheckAPI.health = false;
  //     }
  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //     //  this.getVendorPollerData();
  //   }, error => {

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;

  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;


  //     //  this.getVendorPollerData();
  //   });
  // }

  // getVendorPollerData() {

  //   let vendor = {
  //     NPINo: '9673535151',
  //   };

  //   var lastExecutionTime = new Date();
  //   var serviceName = 'Vendor Poller Service';
  //   let healthCheckAPI: any = {};
  //   let newArray: any = [];
  //   this.vendorService.GetVendorPollerData(vendor).subscribe(res => {

  //     if (res != null) {
  //       let data = res.result;

  //       healthCheckAPI.name = serviceName;
  //       healthCheckAPI.lastExecution = lastExecutionTime;
  //       if (data == 'SUCCESS') {

  //         healthCheckAPI.health = true;

  //       } else {
  //         healthCheckAPI.health = false;
  //       }
  //       newArray.push(healthCheckAPI);

  //       newArray.forEach(element => {
  //         const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //         if (itemIndex > -1) {
  //           this.storeArray[itemIndex] = element;
  //         } else {
  //           this.storeArray = this.storeArray.push(element);
  //         }
  //       }); this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //       this.dataSource.paginator = this.paginator;
  //       this.dataSource.sort = this.sort;

  //     } else {
  //       healthCheckAPI.name = serviceName;
  //       healthCheckAPI.lastExecution = lastExecutionTime;
  //       healthCheckAPI.health = false;

  //       newArray.push(healthCheckAPI);

  //       newArray.forEach(element => {
  //         const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //         if (itemIndex > -1) {
  //           this.storeArray[itemIndex] = element;
  //         } else {
  //           this.storeArray = this.storeArray.push(element);
  //         }
  //       });
  //       this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //       this.dataSource.paginator = this.paginator;
  //       this.dataSource.sort = this.sort;

  //     }

  //   }, error => {

  //     healthCheckAPI.name = serviceName;
  //     healthCheckAPI.lastExecution = lastExecutionTime;
  //     healthCheckAPI.health = false;

  //     newArray.push(healthCheckAPI);

  //     newArray.forEach(element => {
  //       const itemIndex = this.storeArray.findIndex(o => o.name === element.name);
  //       if (itemIndex > -1) {
  //         this.storeArray[itemIndex] = element;
  //       } else {
  //         this.storeArray = this.storeArray.push(element);
  //       }
  //     });
  //     this.dataSource = new MatTableDataSource<healthCheck>(this.storeArray);
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;

  //   });
  // }


}
