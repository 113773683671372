import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdiUserService } from 'src/app/services/edi_user.service';

@Component({
  selector: 'app-contract-replace-or-no',
  templateUrl: './contract-replace-or-no.component.html',
  styleUrls: ['./contract-replace-or-no.component.css']
})
export class ContractReplaceOrNoComponent implements OnInit {
  // counter: number = 100;
  // showCounter: number = 20;
  public colorValue: string = "primary";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ContractReplaceOrNoComponent>, private userService: EdiUserService) { }

  ngOnInit(): void {
    // this.counter = 100;
    // const interval = setInterval(() => {
    //   console.log(this.counter);
    //   this.counter -= 5;
    //   this.showCounter -= 1;

    //   if (this.counter == 65) {
    //     this.colorValue = "accent";
    //   }
    //   if (this.counter == 35) {
    //     this.colorValue = "warn";
    //   }
    //   if (this.counter == 0) {
    //     clearInterval(interval);
    //     this.colorValue = "primary";
    //     this.dialogRef.close();
    //     this.userService.logOutUserFromTimeOut()
    //   }
    // }, 1000);
  }
  onCancelClick() {
    this.dialogRef.close({event:'Cancel'});
  }

  replaceContract() {
    this.dialogRef.close({event:'Replace'});

    // this.dialogRef.close({ data: 'data' }
    // );
  }

}
