import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './login/login.component';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { authInterceptorProviders } from './services/http-auth-interceptor.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AuthGuard } from './gaurds/auth.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'loginWithoutCaptcha', component: LoginWithoutCaptchaComponent },
  { path: '', component: LoginComponent, canActivate: [AuthGuard] },
  // { path: '', redirectTo: '/organization', pathMatch: 'full' },

  // { path: 'edi/ediUser', loadChildren: () => import('./ediComponents/edi/edi.module').then(m => m.EdiModule) },
  {
    path: '',
    component: LayoutComponent,
    children: [

      {
        path: '',
        loadChildren: () => import('./storeConfiguratorComponents/store-configurator.module').then(m => m.StoreConfiguratorModule)
      },
      {
        path: 'edi',
        loadChildren: () => import('./ediComponents/edi.module').then(m => m.EdiModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }, authInterceptorProviders],
})
export class AppRoutingModule { }
