import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, map, of } from "rxjs";
import { constant } from "../constant/constant";
import { ediconstant } from "../constant/ediconstant";
import { ErrorHandlerService } from "./error-handler.service";
@Injectable({
  providedIn: 'root'
})

export class EDIStoreService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }




  SearchStore(store: any) {

    return this.httpClient.post<any>((constant.EDI_STORE_SERVICE_URL + constant.GET_STORE_ON_SEARCH), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Insert(store: any) {

    return this.httpClient.post<any>((constant.EDI_STORE_SERVICE_URL + constant.INSERT_EDI_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  Update(store: any) {

    return this.httpClient.post<any>((constant.EDI_STORE_SERVICE_URL + constant.UPDATE_EDI_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Delete(store: any) {

    return this.httpClient.post<any>((constant.EDI_STORE_SERVICE_URL + constant.DELETE_EDI_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  DeleteAccess(store: any) {

    return this.httpClient.post<any>((constant.EDI_STORE_SERVICE_URL + constant.DELETE_EDI_STORE_ACCESS), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getAllNPINo() {
    return this.httpClient.get<any>(ediconstant.getAllNPINoUrl, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  RollBackStore(store: any) {


    return this.httpClient.post<any>((constant.EDI_STORE_SERVICE_URL + constant.RollBack_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.logHttpError(error);
        return of(null); // Return an empty observable to complete the stream
      })
    );
  }
}