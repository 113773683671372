import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DetailsService } from 'src/app/services/Details/details.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-details-screen',
  templateUrl: './details-screen.component.html',
  styleUrls: ['./details-screen.component.css']
})
export class DetailsScreenComponent implements OnInit {
  details: any;
  displayedColumns!: string[];

  constructor(
    private detailsService: DetailsService,
    private dialogRef: MatDialogRef<DetailsScreenComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if (this.data && this.data.details) {
      // If data is passed (dialog scenario), use it directly
      this.details = this.data.details;
    } else {
      // If no data passed, subscribe to the service (regular component scenario)
      this.detailsService.details$.subscribe(details => {
        this.details = details;
        console.log('Details:', details);
        sessionStorage.setItem('details', JSON.stringify(details));
      });
    }

    // Determine displayed columns based on file type
    this.displayedColumns = this.details && this.details.length > 0
      ? this.details[0].fileType === '832'
        ? ['Description', 'Item Code', 'File Id', 'Vendor Code', 'Net Item Price']
        : ['itemCode', 'qty', 'cost', 'ackQty', 'invoiceQuantity']
      : [];
  }

  closeDetails() {
    sessionStorage.removeItem('details');
    this.dialogRef.close();
  }
}
