import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService as Auth0Service } from '@auth0/auth0-angular'
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user: string;
  constructor(private router: Router, private auth: Auth0Service, private utilityService: UtilityService, private tokenStorageService: TokenStorageService) { }

  ngOnInit(): void {
    this.auth.user$.subscribe(user => {
      if (user) {
        this.user = 'Welcome ' + user.name
      }
    });
    if (this.user == null || this.user === "") {
      const user = this.tokenStorageService.getUser();
      this.user = 'Welcome ' + user.fName + ' ' + user.lName
    }
  }

  logOutUser() {
    sessionStorage.clear();
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin // This is where the user will be redirected after logout
      }
    });
    this.router.navigateByUrl('/login');
  }
}
