<form #vendorInformationForm="ngForm" autocomplete="off">
    <br>

    <div fxLayout="row wrap" *ngIf="isMMSAdmin && isFromMapping && !isUpdate">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

              <!-- EPPOS-3895-->
                <mat-form-field>
                  <mat-label>Select NPINo</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="npiControl"
                    [matAutocomplete]="auto"
                    required
                    maxlength="10"
                    inputmode="numeric"
                    pattern="\d*"
                    oninput="this.value = this.value.replace(/[^0-9]/g, '')">

                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayNPINos"
                    (optionSelected)="setNPINoToModel($event); npiControl.setErrors(null);">

                    <ng-container *ngIf="filteredOptions | async as options">
                      <mat-option *ngFor="let option of options" [value]="option">
                        {{option.NPINo}} -- {{option.storeName}}
                      </mat-option>
                      <!-- Show "No data found" when there are no options -->
                      <mat-option *ngIf="options.length === 0" disabled class="no-data-option">
                        No data found
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>

                  <!-- Error message -->
                  <mat-error *ngIf="npiControl.invalid && (npiControl.dirty || npiControl.touched)">
                    Please select a valid NPI
                  </mat-error>
                </mat-form-field>
              <!--EPPOS-3895 -->
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"></div>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <!-- THIS FIELD WILL BE SHOWN ON THE MASTER PAGE FOR MMS ADMIN BOTH CREATE AND UPDATE PAGE-->
            <!-- THIS FIELD WILL BE SHOWN ON THE MASTER PAGE FOR MMS ADMIN BOTH CREATE AND UPDATE PAGE-->
            <div *ngIf="isMMSAdmin  && !isFromMapping" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100"
                fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Vendor Name" name="vendorName" #vendorName="ngModel" id="vendorName"
                        [(ngModel)]="vendorInformation.vendorName" required [disabled]="disableFields">

                    <mat-error *ngIf="vendorName.invalid && (vendorName.dirty || vendorName.touched)">
                        Please enter vendor name
                    </mat-error>
                </mat-form-field>

            </div>
            <!-- THIS FIELD WILL BE SHOWN ONLY  UPDATE PAGE FOR MAPPING PAGE-->
            <div *ngIf="isFromMapping && isUpdate" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Vendor Name" name="vendorName" #vendorName="ngModel" id="vendorName"
                        [(ngModel)]="vendorInformation.vendorName" required [disabled]="disableFields">

                    <mat-error *ngIf="vendorName.invalid && (vendorName.dirty || vendorName.touched)">
                        Please enter vendor name
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- this dropdown option will be shown only to store admin not to mms admin -->
            <div *ngIf="isFromMapping && !isUpdate" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                  <mat-label>Vendor Name</mat-label>
                  <input type="text" matInput [formControl]="vendorNameControl" [matAutocomplete]="autoVendor" required onblur="displayVendorName">
                  <mat-autocomplete #autoVendor="matAutocomplete" [displayWith]="displayVendorName" (optionSelected)="setVendorNameToModel($event);">
                    <mat-option *ngFor="let option of filteredVendorOptions | async" [value]="option">
                        {{option.vendorCode}}--{{option.vendorName}}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="vendorNameControl.hasError('required') && vendorNameControl.touched">
                    Please enter a vendor name
                  </mat-error>
                  
                </mat-form-field>
            </div>

            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Vendor Code" name="vendorCode" #vendorCode="ngModel" id="vendorCode"
                        [(ngModel)]="vendorInformation.vendorCode" required [disabled]="disableFields">

                    <mat-error *ngIf="vendorCode.invalid && (vendorCode.dirty || vendorCode.touched)"
                        class="invalid-feedback">
                        Please enter vendor code
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
                <input matInput placeholder="Address1" name="address1" #address1="ngModel" id="address1"
                    [(ngModel)]="vendorInformation.address1" required [disabled]="disableFields">

                <mat-error *ngIf="address1.invalid && (address1.dirty || address1.touched)" class="invalid-feedback">
                    Please enter address1
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
                <input matInput placeholder="Address2" name="address2" #address2="ngModel" id="address2"
                    [(ngModel)]="vendorInformation.address2" [disabled]="disableFields">
                <mat-error *ngIf="address2.invalid && (address2.dirty || address2.touched)" class="invalid-feedback">
                    Please enter address2
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- Div For State, City, and Zip --><!--EPPOS-3755-->
    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

        <!--Zip--><!--EPPOS-3826-->
        <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Zip" (keypress)="AllowOnlyNumber($event)" minlength="5" maxlength="5"
              name="zip" #zip="ngModel" id="zip" [(ngModel)]="vendorInformation.zip" required
              (blur)="fetchStateAndCity()"
              [disabled]="disableFields">
            <mat-error *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="invalid-feedback">
              Please enter zip
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <!--City-->
        <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="City" (keypress)="AllowOnlyCharacter($event)" name="city"
              #city="ngModel" id="city" [(ngModel)]="vendorInformation.city" required
              [disabled]="disableFields">
            <mat-error *ngIf="city.invalid && (city.dirty || city.touched)" class="invalid-feedback">
              Please enter city
            </mat-error>
          </mat-form-field>
        </div>

        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <!--State-->
        <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <mat-label>Select State</mat-label>
            <mat-select #state="ngModel" name="state" [(ngModel)]="vendorInformation.state" required
              [disabled]="disableFields">
              <mat-option *ngFor="let eachState of stateArray" [value]="eachState.abbreviation">
                {{ eachState.stateName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="state.invalid && (state.dirty || state.touched)" class="invalid-feedback">
              Please select state
            </mat-error>
          </mat-form-field>
        </div>

      </div>
    </div>

    <!-- Div For State, City, and Zip -->


    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput required minlength="10" maxlength="13" mask='(000)000-0000'
                        placeholder="Phone (Office)" name="phoneOff" #phoneOff="ngModel" id="phoneOff"
                        [(ngModel)]="vendorInformation.phoneOff" [disabled]="disableFields">
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Cell No" minlength="10" maxlength="13" mask="(000)000-0000"
                        name="cellNo" #cellNo="ngModel" id="cellNo" [(ngModel)]="vendorInformation.cellNo"
                        [disabled]="disableFields">
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Fax No" minlength="10" maxlength="13" mask="(000)000-0000" name="faxNo"
                        #faxNo="ngModel" id="faxNo" [(ngModel)]="vendorInformation.faxNo" [disabled]="disableFields">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput type="email" placeholder="Email" name="emailAddress" #emailAddress="ngModel"
                        id="emailAddress" [(ngModel)]="vendorInformation.emailAddress"
                        (keydown.enter)="$event.preventDefault()" required
                        pattern="^(?!.*@.*@)[a-zA-Z0-9]+(?:[._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$"
                        appTrimSpaceInput [disabled]="disableFields">
                    <mat-error *ngIf="emailAddress.invalid && (emailAddress.dirty || emailAddress.touched) || emailAddress.errors?.pattern"
                        class="invalid-feedback">
                        Please enter valid email
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <input matInput placeholder="Website" name="webAddress" #webAddress="ngModel" id="webAddress"
                        [(ngModel)]="vendorInformation.webAddress" [disabled]="disableFields">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
                <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">Qualifiers</label>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Price Qualifier</mat-label>
                    <mat-select #priceQualifiers="ngModel" name="priceQualifier"
                        [(ngModel)]="vendorInformation.priceQualifier" required>
                        <mat-option *ngFor="let eachQualifier of priceQualifier" [value]="eachQualifier">
                            {{ eachQualifier }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="priceQualifiers.invalid && (priceQualifiers.dirty || priceQualifiers.touched)"
                        class="invalid-feedback">
                        Please select Price Qualifier
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Cost Qualifier</mat-label>
                    <mat-select #costQualifiers="ngModel" name="costQualifier"
                        [(ngModel)]="vendorInformation.costQualifier" required>
                        <mat-option *ngFor="let eachQualifier of costQualifier" [value]="eachQualifier">
                            {{ eachQualifier }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="costQualifiers.invalid && (costQualifiers.dirty || costQualifiers.touched)"
                        class="invalid-feedback">
                        Please select costQualifier
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>Sale Qualifier</mat-label>
                    <mat-select #saleQualifiers="ngModel" name="saleQualifier"
                        [(ngModel)]="vendorInformation.saleQualifier" required>
                        <mat-option *ngFor="let eachQualifier of saleQualifier" [value]="eachQualifier">
                            {{ eachQualifier }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="saleQualifiers.invalid && (saleQualifiers.dirty || saleQualifiers.touched)"
                        class="invalid-feedback">
                        Please select saleQualifier
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">File Process
                    Settings</label>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap" style="padding-top: 15px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>EDI 810 Process</mat-label>
                    <mat-select #edi810="ngModel" name="edi810" [(ngModel)]="vendorInformation.processSwitch810"
                        required>
                        <mat-option *ngFor="let option of fileOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="edi810.invalid && (edi810.dirty || edi810.touched)" class="invalid-feedback">
                        Please select Process
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>EDI 832 Process</mat-label>
                    <mat-select #edi832="ngModel" name="edi832" [(ngModel)]="vendorInformation.processSwitch832"
                        required>
                        <mat-option *ngFor="let option of fileOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="edi832.invalid && (edi832.dirty || edi832.touched)" class="invalid-feedback">
                        Please select Process
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>EDI 835 Process</mat-label>
                    <mat-select #edi835="ngModel" name="edi835" [(ngModel)]="vendorInformation.processSwitch835"
                        required>
                        <mat-option *ngFor="let option of fileOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="edi835.invalid && (edi835.dirty || edi835.touched)" class="invalid-feedback">
                        Please select Process
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-form-field>
                    <mat-label>EDI 855 Process</mat-label>
                    <mat-select #edi855="ngModel" name="edi855" [(ngModel)]="vendorInformation.processSwitch855"
                        required>
                        <mat-option *ngFor="let option of fileOptions" [value]="option">
                            {{ option }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="edi855.invalid && (edi855.dirty || edi855.touched)" class="invalid-feedback">
                        Please select Process
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>
        </div>
    </div>
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">Other
                    Settings</label>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap" style="padding-top: 15px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">

                <mat-checkbox #isActive="ngModel" id="isActive" name="isActive" class="custom-checkbox"
                    [(ngModel)]="vendorInformation.isActive">Active
                </mat-checkbox>
            </div>
            <!-- <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox #isEPO="ngModel" id="isEPO" name="isEPO"
                        [(ngModel)]="vendorInformation.isEPO">Is EPO?
                    </mat-checkbox>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox #salePriceUpdate="ngModel" id="salePriceUpdate" name="salePriceUpdate"
                        [(ngModel)]="vendorInformation.salePriceUpdate">Sale Price Update
                </mat-checkbox>
            </div> -->
        </div>
    </div>

    <!-- <div fxLayout="row wrap" style="padding-top: 15px;">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox #vendorCostPrice="ngModel" id="vendorCostPrice" name="vendorCostPrice"
                        [(ngModel)]="vendorInformation.vendorCostPrice">Vendor Cost Price
                    </mat-checkbox>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox #autoClose="ngModel" id="autoClose" name="autoClose"
                        [(ngModel)]="vendorInformation.autoClose">Auto Close
                    </mat-checkbox>
            </div>
            <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox #reduceSellingPriceWith832File="ngModel" id="reduceSellingPriceWith832File" name="reduceSellingPriceWith832File"
                        [(ngModel)]="vendorInformation.reduceSellingPriceWith832File">Reduce selling price with 832 file
                    </mat-checkbox>
            </div>
        </div>
      </div> -->
    <div fxLayout="row wrap">
        <div fxFlex.gt-lg="70" fxFlex.gt-md="70" fxFlex.gt-xs="70" fxFlex="70" style="padding-top: 10px;">
            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100">
                <label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;"></label>
            </div>
        </div>
    </div>
</form>
