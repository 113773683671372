import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { ediconstant } from '../constant/ediconstant';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }



  getRolesOnLoad(roleFilter: any) {
    return this.httpClient.post<any>(constant.ROLE_SERVICE_URL + constant.getRolesOnLoadUrl, roleFilter, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  getRoles(roleFilter:any){
    return this.httpClient.post<any>(ediconstant.getRoleUrl, roleFilter, constant.httpClientHeader).pipe(
      map((response: any) => {
        
        return response;
      })
    )

  }

}
