import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'epos-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.css']
})
export class StatusBadgeComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }

  @Input() btnLabel: string;
  @Input() type: 'inprogress' | 'success' | 'pending' | 'error' = 'success'; // Default to primary type
  @Input() size: 'sm' | 'lg' = 'sm'; // Default to primary type
  @Input() disabled: boolean = false;

  @Input() needCursor: boolean = false; // Default to 'not-allowed' for disabled buttons

}
