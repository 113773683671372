import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileDownload } from 'src/app/models/FileDownload';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { FileDownloadService } from 'src/app/services/FileDownload/file-download.service';
import { EdiUserService } from 'src/app/services/edi_user.service';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { DetailsService } from 'src/app/services/Details/details.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Store } from 'src/app/models/Store';
import { Vendor } from 'src/app/models/Vendor';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { ediconstant } from 'src/app/constant/ediconstant';
import { PharmacyCreateRequest } from 'src/app/models/Pharmacy/PrimeRxCloudPharmacyCreateRequest';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { DetailsScreenComponent } from '../details-screen/details-screen.component';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.css']
})

export class FileDownloadComponent implements OnInit, AfterViewInit {

  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['NPINo', 'PharmacyName', 'vendorCode', 'contractName', 'fileName', 'status', 'fileProcessedDate', 'fileType', 'remark']; //EPPOS-3766

  pageSizeOptions: number[] = [10, 25, 50, 100];
  currentPage = 0;
  pageSize = 10;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<FileDownload> = new MatTableDataSource();

  //=========variable Declaration============
  fileDownload: any = {};
  resultData: any;
  fileDownloadArray: FileDownload[] = [];
  fileDownloadRequest: FileDownloadRequest = new FileDownloadRequest;
  userAccessData!: EdiUserAccess;
  currentDate!: any;
  fileStatus: any[] = [];
  storeNpiList: Store[] = [];

  vendor: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810: '',
    processSwitch832: '',
    processSwitch835: '',
    processSwitch855: ''

  };

  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;
  selectedNPINo: string = "";
  existingMappedVendor: Vendor[] = [];
  vendorMasterArray: Vendor[] = [];
  filteredVendorOptions: Observable<Vendor[]> | undefined;
  vendorNameControl = new FormControl('');
  storeArray!: Store[];

  constructor(private fileDownloadService: FileDownloadService, private utilityService: EdiUtilityService, private ediStoreService: EDIStoreService, private detailsService: DetailsService, private router: Router, private dialog: MatDialog, private tokenStorageService: TokenStorageService, private vendorService: VendorService) {
    // this.userAccessData = userService.getEdiUsersAccessData();
    // this.fileDownload.NPINo = this.userAccessData.NPINo;
    // this.fileDownload.pseudoName = this.userAccessData.pseudoName;

    const user = this.tokenStorageService.getUser();

    if (!this.userAccessData) {
      this.userAccessData = {} as any;
    }

    this.userAccessData.NPINo = user.NPINo;
    this.userAccessData.pseudoName = user.pseudoName;
  }

  ngAfterViewInit(): void {
    //FETCHING THE FILE DOWNLOAD DATA ON PAGE VIEW INIT
    this.getFileDownloadData();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<FileDownload>(this.fileDownloadArray);
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;

    this.fileDownload.fileType = 'All';
    this.fileDownload.fileStatus = 'All';

    //SETTING THE DATE IN THE DATE PICKER
    this.currentDate = new Date();
    var previousMonth = new Date(this.currentDate);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    this.fileDownload.fromDate = previousMonth;
    this.fileDownload.toDate = new Date();
    var fStatusAll = { "fStatus": "All" };
    var fStatus = { "fStatus": "Processed" };
    var fStatus1 = { "fStatus": "Downloaded" };
    var fStatus2 = { "fStatus": "Error" }
    this.fileStatus.push(fStatusAll);
    this.fileStatus.push(fStatus);
    this.fileStatus.push(fStatus1);
    this.fileStatus.push(fStatus2);

    this.loadStoreNpi();

    if (this.userAccessData.NPINo != "" && this.userAccessData.NPINo == "0000000000") {
      //this.isMMSAdmin = true;
      this.loadAllNPINo();
    }
    this.loadMasterVendors();

  }

  loadMasterVendors() {

    let vendorData = {};
    this.vendorService.getMasterVendor(vendorData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.vendorMasterArray = receivedVendorResponse;
          this.vendorMasterArray = this.vendorMasterArray.filter(vendor => vendor.isActive);
          this.getUnMappedVendors(this.userAccessData.NPINo);
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  loadAllNPINo() {

    this.ediStoreService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.storeArray = receivedVendorResponse;
          this.setValueToNPIDropdown();
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  setValueToNPIDropdown() {

    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }

  onFromDateChange() {

    if ((Date.parse(this.fileDownload.fromDate) > Date.parse(this.fileDownload.toDate)) && this.fileDownload.toDate) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than To date").subscribe(result => {
        this.fileDownload.fromDate = "";
      });

    } else if (Date.parse(this.fileDownload.fromDate) > Date.parse(this.currentDate)) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than current date").subscribe(result => {
        this.fileDownload.fromDate = "";
      });
    }
  }

  onInput(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (!value.trim()) {
      // Clear the value of this.vendor.NPINo if the input field is empty
      this.vendor.NPINo = '';
      this.selectedNPINo = ''; // Optionally clear other related properties
    }
  }

  onToDateChange() {


    if ((Date.parse(this.fileDownload.toDate) < Date.parse(this.fileDownload.fromDate)) && this.fileDownload.fromDate) {
      this.utilityService.showAlert("WARNING", "Warning", "To date should be greater than From date").subscribe(result => {
        this.fileDownload.toDate = "";
      });

    } else if (Date.parse(this.fileDownload.toDate) > Date.parse(this.currentDate)) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select To date lower than current date").subscribe(result => {
        this.fileDownload.toDate = "";
      });
    }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    this.getFileDownloadData();
  }

  getFileDownloadData() {

    // setting request object
    //this.fileDownloadRequest.NPINo = this.userAccessData.NPINo;
    //this.fileDownloadRequest.NPINo = "8001001230";
    this.fileDownloadRequest.NPINo = this.vendor.NPINo;
    if (this.fileDownloadRequest.NPINo == 'All' || this.fileDownloadRequest.NPINo == "") this.fileDownloadRequest.NPINo = '0000000000';

    if (this.fileDownload.fileType == 'All') this.fileDownload.fileType = '';
    if (this.fileDownload.fileStatus == 'All') this.fileDownload.fileStatus = '';

    this.fileDownloadRequest.startDate = new Date(moment(this.fileDownload.fromDate).format('YYYY-MM-DD')).toDateString();
    this.fileDownloadRequest.endDate = new Date(moment(this.fileDownload.toDate).format('YYYY-MM-DD')).toDateString();

    // this.fileDownloadRequest.startDate = moment(this.fileDownload.fromDate).utc().format('YYYY-MM-DDT00:00:00[Z]');
    // this.fileDownloadRequest.endDate = moment(this.fileDownload.toDate).utc().format('YYYY-MM-DDT23:59:59[Z]');

    this.fileDownloadRequest.fileType = this.fileDownload.fileType;
    this.fileDownloadRequest.fileStatus = this.fileDownload.fileStatus;
    this.fileDownloadRequest.pagination.pageNumber = this.currentPage + 1;
    this.fileDownloadRequest.pagination.pageSize = this.pageSize;


    this.fileDownloadService.getFileDowloadData(this.fileDownloadRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedFileResponse = JSON.parse(response.data);

          this.dataSource = new MatTableDataSource<FileDownload>(receivedFileResponse.records);

          this.dataSource.paginator = this.paginator!;
          this.dataSource.sort = this.sort!;
          setTimeout(() => {

            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = receivedFileResponse.totalRecords;

          });
          if (receivedFileResponse.records.length < 1) {
            this.utilityService.showAlert(
              constant.KEY_WARNING,
              constant.ALERT_WARNING_TITLE,
              "No data found"
            );
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching the file download data")
        }

        if (this.fileDownload.fileType == '') this.fileDownload.fileType = 'All';
        if (this.fileDownload.fileStatus == '') this.fileDownload.fileStatus = 'All';
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  getPODetails(element: any): void {
    const processedDate = new Date(element.fileProcessedDate);
    this.fileDownloadRequest.NPINo = this.userAccessData.NPINo;
    this.fileDownloadRequest.fromDate = processedDate;
    this.fileDownloadRequest.toDate = new Date();
    this.fileDownloadRequest.fileType = element.fileType;

    this.fileDownloadService.getPODetails(this.fileDownloadRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          const receivedResponse = JSON.parse(response.data);
          console.log('getPODetails Response:', receivedResponse);
          this.detailsService.setDetails(receivedResponse);

          const dialogRef = this.dialog.open(DetailsScreenComponent, {
            width: '60%',
            height: '80%',
            data: { details: receivedResponse }
          });

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'Error while fetching vendor data')
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  toggleCommentExpansion(element: any): void {
    element.isExpanded = !element.isExpanded;
  }

  loadStoreNpi() {
    this.ediStoreService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.storeNpiList = receivedVendorResponse;

          const pharmacyCreateRequest: PharmacyCreateRequest = {
            adminFirstName: '',
            adminLastName: '',
            pharmacyEmail: '',
            pharmacyPhoneNumber: '',
            contactPerson: '',
            pharmacyFaxNumber: '',
            rxGenSys: 0, // Assuming rxGenSys is a number property, initialize with appropriate default value
            pharmacyAddressLine1: '',
            pharmacyAddressLine2: '', // If you want to set it to null, remove this line
            masterDbName: '',
            pharmacyNpiNumber: '',
            pharmacyZipCode: '',
            pharmacyNABPNum: 0, // Assuming pharmacyNABPNum is a number property, initialize with appropriate default value
            PharmacyStateCode: "", // Assuming PharmacyStateCode is a number property, initialize with appropriate default value
            pharmacyCityId: 0, // Assuming pharmacyCityId is a number property, initialize with appropriate default value
            pharmacyRegistrationNum: '',
            pharmacyDEARegistration: '',
            pharmacyName: '',
            organizationId: 0,
            adminUserName: '',
            adminPassword: '',
            isPrimeRxCloudEmptyPharmacy:false
          };

          const storeAll: Store = {
            mId: '',
            NPINo: 'All',
            pseudoName: '',
            storeName: '',
            address1: '',
            address2: '',
            city: '',
            zipCode: '',
            state: '',
            owner: '',
            contactNo: '',
            contactName: '',
            website: '',
            fax: '',
            selectedPayType: '',
            isActive: false,
            terminal: '',
            isEdiv2: false,
            isMpos: false,
            isStoreAdminCreated: false,
            createdBy: '',
            updatedBy: '',
            createdDate: '',
            updatedDate: '',
            email: '',
            isPrimeRxCloud: false,
            primeRxCloudData: pharmacyCreateRequest,
            isInfoLog: false, // #3787
            allowDefaultItems: false //#3788
          }

          this.storeNpiList.unshift(storeAll);
          this.vendor.NPINo = 'All';
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error ehile fetching store NPI list")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  setNPINoToModel(event: any) {
    this.vendor.NPINo = event.option.value.NPINo;

    this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

    //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
    this.getUnMappedVendors(this.vendor.NPINo);

  }

  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.existingMappedVendor = receivedVendorResponse;

          //now getting the diffrence between the master vendor and mapped  vendor
          this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
            !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

          //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
          this.setValueToVendorDropdown();

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching mapped vendors data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}


