
<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header
            [icon]="'assets/images/icons/icon-EDI-blue.jpeg'"
            [title]="'File Parsing Error'"
            [breadcrumb]="''"
            ></app-page-header>
        </div>
    </div>
</div>
<div fxLayout="row wrap" style="padding: 10px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;">
        <mat-form-field>
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fileParsingError.fromDate"
                 (dateChange)="onFromDateChange()" #fromDate="ngModel" name="fromDate" required readonly>
          <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
  
        <mat-error *ngIf="fromDate.invalid && (fromDate.dirty || fromDate.touched)" class="invalid-feedback">
          Please select from date
        </mat-error>
      </div>
  
      <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
  
      <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="toPicker" [(ngModel)]="fileParsingError.toDate" #toDate="ngModel"
                 (dateChange)="onToDateChange()" name="toDate" required readonly>
          <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
  
        <mat-error *ngIf="toDate.invalid && (toDate.dirty || toDate.touched)" class="invalid-feedback">
          Please select end date
        </mat-error>
      </div>
  
      <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
  
      <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <mat-label>Select file type</mat-label>
          <mat-select #fileType="ngModel" name="fileType" [(ngModel)]="fileParsingError.fileType">
            <mat-option value="All">All</mat-option>
            <mat-option value="832">832</mat-option>
            <mat-option value="855">855</mat-option>
            <mat-option value="810">810</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
  
      <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
  
      <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <mat-label>Select NPINo</mat-label>
          <input type="text" matInput [formControl]="npiControl" [matAutocomplete]="auto" (input)="onInput($event)" (keypress)="keyPressNumeric($event)">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNPINos"
                            (optionSelected)="setNPINoToModel($event);">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option.NPINo}}--{{option.storeName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
  
      <div fxFlex.gt-lg="1" fxFlex.gt-md="1" fxFlex.gt-xs="100" fxFlex="100"></div>
  
      <div fxFlex="auto" fxLayout="row" >
        <button class="custom-blue-button" type="button" id="btnCreate" style="width: 85px;"
                (click)="getFileParseErrorData()">
          <mat-icon color="white">search</mat-icon>Search
        </button>
      </div>
    </div>
  </div>
  

<!--table-->
<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                            <!-- Checkbox Column -->
                
                            <ng-container color="primary" matColumnDef="NPINo">
                                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>NPINo</b> </mat-header-cell>
                                <mat-cell color="primary" *matCellDef="let element"> {{element.NPINo}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="vendorCode">
                                <mat-header-cell *matHeaderCellDef mat-sort-header><b> Vendor Code</b> </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.vendorCode}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="contractName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header><b> Vendor Account</b></mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.contractName}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="fileName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> <b>File Name</b></mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.fileName}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="fileType">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> <b>File Type</b></mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.fileType}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="errorDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header><b> Error Date</b></mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.errorDate | date: "short"}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="errorMessages">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> <b>Error Messages</b></mat-header-cell>
                                <!-- <mat-cell class="break-word" style="color:black ;" *matCellDef="let element"> {{element.errorMessages}}
                                </mat-cell> -->
                
                                <mat-cell
                                    style="color: red;" 
                                    [class.truncate-comments]="!element.isExpanded" 
                                    [class.expanded-comments]="element.isExpanded"
                                    *matCellDef="let element"
                                    (click)="toggleCommentExpansion(element)">
                                    <div style="width: 200px;">
                                        {{ element.errorMessages }}
                                        <span *ngIf="!element.isExpanded">...</span>
                                    </div>
                                </mat-cell>
                            </ng-container>
                
                
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
                
                        </mat-table>
                    </div>
                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>