



<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
      
        <div fxFlex.gt-lg="15" fxFlexAlign="center" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header
            [icon]="'assets/images/icons/icon-EDI-blue.jpeg'"
            [title]="'Vendor Master'"
            [breadcrumb]="''"
            ></app-page-header>
        </div>
            
        <div fxFlex.gt-lg="25" fxFlex.gt-md="25" fxFlex.gt-xs="100" fxFlex="100" style="padding-top:10px;">
            <mat-form-field>
                <mat-label>Select Vendor</mat-label>
                <mat-select #vendorCode="ngModel" name="vendorCode" [(ngModel)]="vendorMaster.vendorCode">
                    <mat-option *ngFor="let eachVendor of vendorMasterArray" [value]="eachVendor.vendorCode">
                        {{ eachVendor.vendorCode}}
                    </mat-option>

                </mat-select>
            </mat-form-field>
        </div>
            
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;">
                <button  class="custom-blue-button"   type="button" id="btnCreate" style="width: 85px;"
                (click)="getMasterVendor()">
                <mat-icon color="white">search</mat-icon>Search
            </button>
            </div>
        </div>

        <div fxFlex="auto" fxLayoutAlign="end center">
            <button  class="custom-orange-button"   type="button" id="btnCreate" style="width: 85px;"
                (click)="openVendorMasterCreateDialog()">
                <mat-icon color="white">add</mat-icon>Vendor
                
                </button>
        </div>
   
    </div>
</div>





<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                            <!-- Checkbox Column -->
                
                            <ng-container color="primary" matColumnDef="vendorCode">
                                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header>Vendor Code </mat-header-cell>
                                <mat-cell color="primary" *matCellDef="let element"> {{element.vendorCode}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="vendorName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Vendor Name </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.vendorName}} </mat-cell>
                            </ng-container>
                
                
                            <ng-container matColumnDef="address1">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Address1</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.address1}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="city">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> City</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="state">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> State</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.state}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="zip">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Zip</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.zip}} </mat-cell>
                            </ng-container>
                
                            <ng-container matColumnDef="isActive">
                                <mat-header-cell *matHeaderCellDef mat-sort-header> Is Active?</mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.isActive}} </mat-cell>
                            </ng-container>
                
                
                            <ng-container matColumnDef="Action">
                                <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <!-- <button mat-icon-button matTooltip="Edit {{element.vendorCode}}" (click)="openVendorMasterUpdateDialog(element);" color="primary">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button> -->
                                    <app-table-edit-button
                                    [imageSrc]="''"
                                    [tooltip]="'Edit ' + element.vendorCode"
                                    [color]="'primary'"
                                    (buttonClick)="openVendorMasterUpdateDialog(element)">
                                    </app-table-edit-button>

                                    <!-- <button mat-icon-button matTooltip="Delete {{element.vendorCode}}" color="warn" (click)="deleteVendorMaster(element)">
                                        <mat-icon>delete_outline</mat-icon>
                                    </button> -->
                                    <app-table-delete-button
                                    [imageSrc]="''"
                                    [tooltip]="'Delete ' + element.vendorCode"
                                    [color]="'primary'"
                                    (buttonClick)="deleteVendorMaster(element)">
                                    </app-table-delete-button>
                                </mat-cell>
                            </ng-container>
                
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
                
                        </mat-table>
                    </div>
                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                    </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>