import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'console';
import { catchError, map } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class VendorPriceUpdateService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  UpdateCronScheduler(cronExpression: any) {

    return this.httpClient.post<any>(ediconstant.updatePriceUpdateCronScheduler, cronExpression, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

}