import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { StoreGeneralConfig } from 'src/app/models/StoreGeneralConfig';
import { MatTableDataSource } from '@angular/material/table';
import { errorMonitor } from 'events';
import { MatDialog } from '@angular/material/dialog';
import { CronDialogComponent } from '../scheduler/cron-dialog/cron-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GetFilesNowService } from 'src/app/services/getFilesNow/get-files-now.service';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-get-file-now',
  templateUrl: './get-file-now.component.html',
  styleUrls: ['./get-file-now.component.css']
})
export class GetFileNowComponent implements OnInit {

  getSchedularColumn = [
    { IntervalType: 'FileDownload', Action: this.getNowFileDownload },
    { IntervalType: 'PriceUpdate832', Action: this.getNowPriceUpdate },
    { IntervalType: 'Invoice810', Action: this.getNow810Schedular },
    { IntervalType: 'Acknowledgements855', Action: this.getNow855Schedular }
  ];

  message: String = '';
  displayedColumns = ['IntervalType', 'Action'];
  dataSource = this.getSchedularColumn;
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  // @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  // @ViewChild(MatSort) sort: MatSort | undefined;
  // //  sort: MatSort | undefined;
  // getSchedularColumn: MatTableDataSource<StoreGeneralConfig> = new MatTableDataSource();

  storegeneralconfig: any = {};

  public schdulerForm: FormGroup = new FormGroup({});

  constructor(
    private vendorService: VendorService,
    private utilityService: EdiUtilityService,
    private GetFilesNowService: GetFilesNowService,
    public dialogService: MatDialog
  ) { }

  ngOnInit(): void {
    this.initForm();
    // this.getScheduler();
  }

  initForm(): void {
    this.schdulerForm = new FormGroup({
    });
  }

  getNowServices(element: any): void {
    const intervalType = element.IntervalType;

    switch (intervalType) {
      case 'FileDownload':
        this.getNowFileDownload(element);
        break;
      case 'PriceUpdate832':
        this.getNowPriceUpdate(element);
        break;
      case 'Invoice810':
        this.getNow810Schedular(element);
        break;
      case 'Acknowledgements855':
        this.getNow855Schedular(element);
        break;
      default:
        console.error(`Unsupported IntervalType: ${intervalType}`);
    }
  }



  getNowFileDownload(element: any): void {
    this.GetFilesNowService.getNowFileDownload().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedResponse = JSON.parse(response.data);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  getNowPriceUpdate(element: any): void {
    this.GetFilesNowService.getNowPriceUpdate832().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedResponse = JSON.parse(response.data);
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }
  getNow855Schedular(element: any): void {
    this.GetFilesNowService.getNowOrder855().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedResponse = JSON.parse(response.data);
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }
  getNow810Schedular(element: any): void {
    this.GetFilesNowService.getNowOrder810().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedResponse = JSON.parse(response.data);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  openCronDialog(element: any) {
    const mapDialogRef = this.dialogService.open(CronDialogComponent, {
      width: '60%',
      height: '35%',
      disableClose: true,
      data: {
        receivedCronExpression: element.CronExpression,
        intervalType: element.IntervalType
      }
    });

  }
}
