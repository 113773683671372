import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { OTCBenifits } from 'src/app/models/OTCBenifits';
import { NBSProcessorService } from 'src/app/services/nbsprocessor.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-otcbenifits-modal',
  templateUrl: './otcbenifits-modal.component.html',
  styleUrls: ['./otcbenifits-modal.component.css']
})
export class OTCBenifitsModalComponent implements OnInit {

  AppsArray: any = constant.Apps;

  OTCBenifits: OTCBenifits = {
    IsActive: false,
    IsNBSAccess: false
  };
  userAccess: any = {};
  checked: boolean = false; // Variable to hold the toggle state

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, public NBSService: NBSProcessorService,
    private utilityService: UtilityService,
    private dialogService: MatDialogRef<OTCBenifitsModalComponent>,) {

    if (receivedData != undefined && receivedData.OTCBenifitsData != undefined) {
      this.OTCBenifits = receivedData.OTCBenifitsData;
    }
  }

  ngOnInit(): void {
  }

  onToggleClick() {
    this.OTCBenifits.IsActive = this.checked;
    console.log("toggle value :" + JSON.stringify(this.checked));
  }

  checkNPINoExists() {

    if (this.OTCBenifits != undefined && this.OTCBenifits.NPINo != undefined && this.OTCBenifits.NPINo != "" && this.OTCBenifits.NPINo != null) {


      this.NBSService.checkNPINoExists(this.OTCBenifits).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {


          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
            this.OTCBenifits.NPINo = "";
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });

    }
  }

  AddOTCBenifitsAccess(OTCBenifits: OTCBenifits) {

    this.NBSService.AddOTCBenifitsAccess(OTCBenifits).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "OTC Benifit added successfully").subscribe(result => {
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
          this.OTCBenifits.NPINo = "";
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  UpdateOTCBenifitsAccess(OTCBenifits: OTCBenifits) {

    this.NBSService.UpdateOTCBenifitsAccess(OTCBenifits).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "OTC Benifit updated successfully").subscribe(result => {
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  DeleteOTCBenifitsAccess(OTCBenifits: OTCBenifits) {
    this.NBSService.DeleteOTCBenifitsAccess(OTCBenifits).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_DELETE_TITLE, "OTC Benifit deleted successfully").subscribe(result => {
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

}
