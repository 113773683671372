import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appLowercase]'
})
export class LowercaseDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event) {
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
    this.el.nativeElement.value = this.el.nativeElement.value.toLowerCase();
    event.target.setSelectionRange(start, end);
  }

}
