import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorHandlerService } from './error-handler.service';
import { catchError } from 'rxjs';
import { constant } from '../constant/constant';
import { ApiResponse } from '../models/ApiResponse';

@Injectable({
  providedIn: 'root'
})
export class PrimerxCloudPharmacyService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  SearchPharmacy(pharmacyRequest: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.GET_PHARMACY), pharmacyRequest, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Insert(pharmacyRequest: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.INSERT_PHARMACY), pharmacyRequest, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Update(pharmacyRequest: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.UPDATE_PHARMACY), pharmacyRequest, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Delete(pharmacyRequest: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.DELETE_PHARMACY), pharmacyRequest, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetMasterDBName() {

    return this.httpClient.get<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.GET_MASTERDB), constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  loadCityForCurrentState(cityRequest:any) {

    return this.httpClient.post<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.GET_CITIES),cityRequest, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  RestartMigration(migrationRequest:any) {

    return this.httpClient.post<ApiResponse<any>>((constant.PHARMACY_SERVICE_URL + constant.RESTART_DB_MIGRATION),migrationRequest, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
}
