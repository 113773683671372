<!-- <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; height: 30px; display: flex; align-items: center;">
    <mat-icon color="" style="margin-right: 8px;">{{ icon }}</mat-icon>
    <span style="font-size: 18px; font-weight: bold; text-align: left; color: #0C7BB8;">
        {{ title }}
    </span>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" style="display: flex; align-items: center;">
    <label style="margin: 0;">{{ breadcrumb }}</label>
</div> -->


<!-- For Image -->
<div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; height: 30px; display: flex; align-items: center;">
    <img [src]="icon" [alt]="title" style="margin-right: 8px;width:24px">
    <h2 style="display: inline-block; margin: 0;color: #0C7BB8;font-weight: bold;font-size: 18px">{{ title }}</h2>
</div>

<div fxLayout="row wrap" fxLayoutAlign="start center" style="display: flex; align-items: center;">
    <label style="margin: 0;">{{ breadcrumb }}</label>
</div>

<!--<label style="font-size: 16px; font-weight: bold; text-align: left; color: #0C7BB8;">Applications</label>-->