import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { constant } from '../constant/constant';
import { ErrorHandlerService } from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class NBSProcessorService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }


  checkNPINoExists(store: any) {


    return this.httpClient.post<any>((constant.NBS_SERVICE_URL + constant.CHECK_NBS_NPINO_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  DeleteOTCBenifitsAccess(store: any) {


    return this.httpClient.post<any>((constant.NBS_SERVICE_URL + constant.DELETE_OTCBENIFIT_ACCESS), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  UpdateOTCBenifitsAccess(store: any) {


    return this.httpClient.post<any>((constant.NBS_SERVICE_URL + constant.UPDATE_OTCBENIFIT_ACCESS), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  AddOTCBenifitsAccess(store: any) {


    return this.httpClient.post<any>((constant.NBS_SERVICE_URL + constant.ADD_OTCBENIFIT_ACCESS), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetOtcBenifits(store: any) {


    return this.httpClient.post<any>((constant.NBS_SERVICE_URL + constant.GET_OTCBENIFITS_PHARMACY), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
  
  
  SearchOtcBenifits(store: any) {


    return this.httpClient.post<any>((constant.NBS_SERVICE_URL + constant.SEARCH_OTCBENIFITS_PHARMACY), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
}
