// auth.service.ts
import{AuthService as Auth0Service} from '@auth0/auth0-angular'
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedInValue = false;
  public user$=this.auth.user$;
  public accesstoken=this.auth.getAccessTokenSilently();
constructor(private http: HttpClient,private auth:Auth0Service){}
  isLoggedIn() {
    return this.isLoggedInValue;
  }

  login() {
    this.auth.loginWithRedirect();
    // Implement your login logic here
  }

  logout() {
    this.auth.logout({
      logoutParams:{
        returnTo:document.location.origin
      }
    });
  }
}
