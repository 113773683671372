import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService as Auth0Service } from '@auth0/auth0-angular'
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private authService: Auth0Service, private auth: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.authService.isAuthenticated$.pipe(map(isAuthenticated => {

            var userName = sessionStorage.getItem('userName');
            if ((isAuthenticated) || (userName != null || userName != undefined)) {
                this.auth.isLoggedInValue = true;
                return true;
            }
            else {
                this.auth.isLoggedInValue = false;
                return this.router.createUrlTree(['/login']);
            }
        }));
    }
}
