<mat-drawer-container class="example-container mat-typography" autosize>
  <mat-drawer #drawer mode="side" disableClose="true" opened="true">
    <button mat-mini-fab (click)="isExpanded = !isExpanded" color="warn" style="margin: 10px;">
      <mat-icon aria-label="Menu">menu</mat-icon>
    </button>

    <mat-nav-list>
      <mat-list-item>
        <mat-icon mat-list-icon>person</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Management A</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>assignment</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Management B</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>domain</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Management C</h4>
      </mat-list-item>
      <mat-list-item>
        <mat-icon mat-list-icon>folder_shared</mat-icon>
        <h4 mat-line *ngIf="isExpanded">Management X</h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>

  <!-- <div class="example-sidenav-content">
    You cards and screen Contents goes here..
    Will be pushed towards right on expanding side navbar.
  </div> -->
</mat-drawer-container>