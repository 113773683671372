import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup-close-button',
  templateUrl: './popup-close-button.component.html',
  styleUrls: ['./popup-close-button.component.css']
})
export class PopupCloseButtonComponent {

  @Input() label: string = 'Button';
  @Input() disabled: boolean = false;
  

  @Output() buttonClick = new EventEmitter<void>();

  handleClick() {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
  }

}
