import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, map } from "rxjs";
import { constant } from "../constant/constant";
import { ErrorHandlerService } from "./error-handler.service";
@Injectable({
  providedIn: 'root'
})

export class MPOSStoreAdminService implements OnInit {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }


  Insert(store: any) {

    return this.httpClient.post<any>((constant.MPOS_USER_SERVICE_URL + constant.INSERT_MPOS_STORE_ADMIN), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  Update(store: any) {

    return this.httpClient.post<any>((constant.MPOS_USER_SERVICE_URL + constant.UPDATE_MPOS_STORE_ADMIN), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  Delete(store: any) {

    return this.httpClient.post<any>((constant.MPOS_USER_SERVICE_URL + constant.DELETE_MPOS_STORE_ADMIN), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getPrimeRxPOSAdminByUserName(user: any) {

    return this.httpClient.post<any>((constant.MPOS_USER_SERVICE_URL + constant.GET_MPOS_STORE_ADMIN_BY_USERNAME), user, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

}