<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex.gt-lg="20" fxFlexAlign="center" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
            <app-page-header
            [icon]="'assets/images/icons/icon-EDI-blue.jpeg'"
            [title]="'File Download'"
            [breadcrumb]="''"
            ></app-page-header>
        </div>
    </div>
</div>

<div fxLayout="row wrap" style="padding: 10px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex.gt-lg="12" fxFlex.gt-md="12" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fileDownload.fromDate"
                    (dateChange)="onFromDateChange()" #fromDate="ngModel" name="fromDate" required readonly>
                <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-error *ngIf="fromDate.invalid && (fromDate.dirty || fromDate.touched)"
                class="invalid-feedback">
                Please select from date
            </mat-error>
        </div>
        <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="toPicker" [(ngModel)]="fileDownload.toDate" #toDate="ngModel"
                    (dateChange)="onToDateChange()" name="toDate" required readonly>
                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>

            <mat-error *ngIf="toDate.invalid && (toDate.dirty || toDate.touched)" class="invalid-feedback">
                Please select end date
            </mat-error>
        </div>
        <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
                <mat-label>Select file type</mat-label>
                <mat-select #fileType="ngModel" name="fileType" [(ngModel)]="fileDownload.fileType">
                    <mat-option value="All">All</mat-option>
                    <mat-option value="832">832</mat-option>
                    <mat-option value="855">855</mat-option>
                    <mat-option value="810">810</mat-option>
                    <mat-option value="835">835</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="12" fxFlex.gt-md="12" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 10px;">
            <mat-form-field>
                <mat-label>Select file status</mat-label>
                <mat-select id="userId" #userId="ngModel" name="userId" [(ngModel)]="fileDownload.fileStatus">
                    <mat-option *ngFor="let status of fileStatus" [value]="status.fStatus">
                        {{ status.fStatus}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex.gt-lg="3" fxFlex.gt-md="3" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 9px;">
            <mat-form-field>
                <mat-label>Select NPINo</mat-label>
                <input type="text" matInput [formControl]="npiControl" [matAutocomplete]="auto" (input)="onInput($event)" (keypress)="keyPressNumeric($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNPINos"
                    (optionSelected)="setNPINoToModel($event);">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option.NPINo}}--{{option.storeName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxFlex.gt-lg="1" fxFlex.gt-md="1" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex="auto" >
            <button  class="custom-blue-button"   type="button" id="btnCreate" style="width: 85px;"
                    (click)="getFileDownloadData()">
            <mat-icon color="white">search</mat-icon>Search</button>

        </div>

    </div>

</div>


<!-- <div class="example-container mat-elevation-z8">

    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
        style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">

        <mat-card>
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                    style="padding-top: 10px;padding-right: 10px;">
                    <button mat-raised-button matTooltip="Export to excel file"
                        (click)="exporter.exportTable('xlsx', {fileName:'filed_download_Data', sheet: 'filed_download_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Excel
                    </button>
                    <button mat-raised-button matTooltip="Export to csv file"
                        (click)="exporter.exportTable('csv',{fileName:'filed_download_Data', sheet: 'filed_download_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Csv
                    </button>
                    <button mat-raised-button matTooltip="Export to json file"
                        (click)="exporter.exportTable('json',{fileName:'filed_download_Data', sheet: 'filed_download_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Json
                    </button>
                    <button mat-raised-button matTooltip="Export to txt file"
                        (click)="exporter.exportTable('txt',{fileName:'filed_download_Data', sheet: 'filed_download_Data', Props: {Author: 'Ediv2Web'}})">
                        <mat-icon>description</mat-icon>Txt
                    </button>
                </div>

                <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>

            </div>

        </mat-card>
    </div>
</div> -->
<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                            <!-- Checkbox Column -->

                            <ng-container color="primary" matColumnDef="NPINo">
                                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header
                                    style="padding: 2px 15px 2px 15px;"><b>NPINo</b> </mat-header-cell>
                                <mat-cell color="primary" *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.NPINo}} </mat-cell>
                            </ng-container>
                            
                            <!--EPPOS-3766-->
                            <ng-container color="primary" matColumnDef="PharmacyName">
                                <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header
                                    style="padding: 2px 15px 2px 15px;"><b>Pharmacy Name</b> </mat-header-cell>
                                <mat-cell color="primary" *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.PharmacyName}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="vendorCode">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b> Vendor
                                        Code</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.vendorCode}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="contractName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b> Vendor
                                        Account</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.contractName}} </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="fileName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b> File
                                        Name</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.fileName}} </mat-cell>
                            </ng-container>

                            <!-- <ng-container matColumnDef="fileName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b> File Name</b></mat-header-cell>
                                <mat-cell class="text-info break-word file-name-container"
                                    [class.truncate-file-names]="!element.isFileNameExpanded"
                                    [class.expanded-file-names]="element.isFileNameExpanded"
                                    *matCellDef="let element"
                                    (click)="toggleFileNameExpansion(element)">
                                    {{element.fileName}}
                                </mat-cell>
                            </ng-container> -->

                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b>
                                        Status</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.status}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fileProcessedDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b> File
                                        Processed Date</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.fileProcessedDate | date:'MM/dd/yyyy  HH:mm:ss'}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fileType">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b> File
                                        Type</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    {{element.fileType}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="remark">
                                <mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 2px 15px 2px 15px;"><b>
                                        Comment</b></mat-header-cell>
                                <mat-cell
                                    [class.truncate-comments]="!element.isExpanded"
                                    [class.expanded-comments]="element.isExpanded"
                                    *matCellDef="let element"
                                    (click)="toggleCommentExpansion(element)">
                                    <div>
                                        {{ element.remark }}
                                        <span *ngIf="!element.isExpanded">...</span>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="Details">
                                <!-- Add style for boldness in the header cell -->
                                <mat-header-cell *matHeaderCellDef style="padding: 2px 15px 2px 15px;"><b>Details</b></mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-align:left;padding: 2px 15px 2px 15px;">
                                    <div class="details-button-container">

                                    <!-- <button mat-stroked-button color="primary"
                                            [disabled]="!(element.status === 'Processed' && (element.fileType === '810' || element.fileType === '855'|| element.fileType === '832' ))"
                                            (click)="getPODetails(element)">

                                        {{ element.fileType === '832' ? 'Details' : 'PODetails' }}
                                    </button> -->
                                    <button mat-stroked-button color="primary"
                                            [disabled]="true"
                                            (click)="getPODetails(element)">
                                        {{ element.fileType === '832' ? 'Details' : 'PODetails' }}
                                    </button>
                                    <!-- [disabled]="!(element.status === 'Processed' && (element.fileType === '810' || element.fileType === '855'|| element.fileType === '832' ))"
                                            (click)="getPODetails(element)" -->
                                    </div>
                                </mat-cell>
                                </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

                        </mat-table>
                    </div>

                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                </mat-paginator>
                </div>
            </mat-card>
        </div>
    </div>
</div>




