import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from 'src/app/services/organization.service';
import { UtilityService } from 'src/app/services/utility.service';
import { OrganizationModalComponent } from './organization-modal/organization-modal.component';
import { OrganizationRequest } from 'src/app/models/Organization/OrganizationRequest';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { OrganizationSearchRequest } from 'src/app/models/RequestModel/OrganizationSearchRequest';
import { StoreRequest } from 'src/app/models/RequestModel/StoreRequest';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {
  organizationName: string = '';
  currentPage = 0;
  pageSize = 10;
  totalRows = 0;

  displayedColumns = ['organizationId', 'name', 'contactPerson', 'city', 'Action'];
  pageSizeOptions = [10, 20, 30];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<OrganizationRequest>;
  selection = new SelectionModel<OrganizationRequest>(true, []);
  organizationArray: any = [];
  organizationSearch:OrganizationSearchRequest=new OrganizationSearchRequest;
  storeRequest: StoreRequest = new StoreRequest();
  constructor(
    public modalService: NgbModal,
    private dialogService: MatDialog,
    private organizationService: OrganizationService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.getOrganizations();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.getOrganizations();
    });
  }

  getOrganizations() {

    this.organizationSearch.pagination.pageNumber=this.currentPage + 1;
    this.organizationSearch.pagination.pageSize=this.pageSize;

    this.organizationService.GetOrganizationByPagination(this.organizationSearch).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          this.organizationArray = response.data.organizations;

          //this.organizationArray.sort((a, b) => a.organizationId - b.organizationId);
          //this.organizationArray=this.organizationArray.sort((a, b) => b.organizationId - a.organizationId);
          //this.organizationArray.sort((a, b) => Number(b.organizationId) - Number(a.organizationId));

          if (this.organizationArray.length > 0) {
            this.dataSource = new MatTableDataSource<OrganizationRequest>(this.organizationArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            setTimeout(() => {
              this.paginator!.pageIndex = this.currentPage;
              this.paginator!.length = response.data.totalRecords;
            });
          } else {
            this.dataSource = new MatTableDataSource<OrganizationRequest>(this.organizationArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.utilityService.showAlert('ERROR', 'Failed', 'No Data Found');
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }
  

  applyFilter(filterValue: any) {
    filterValue = filterValue.value.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  OpenOrganizationUpdateModal(passOrganization: any) {
    const OrganizationUpdateDialogRef = this.dialogService.open(OrganizationModalComponent, {
      width: '50%',
      disableClose: true,
      data: {
        organizationData: passOrganization,
        headerTitle: 'Update Organization'
      }
    });

    OrganizationUpdateDialogRef.afterClosed().subscribe(result => {
      this.getOrganizations();
    });
  }

  OpenOrganizationCreateModal(): void {
    const OrganizationCreateDialogRef = this.dialogService.open(OrganizationModalComponent, {
      width: '50%',
      disableClose: true,
      data: {
        headerTitle: 'Create Organization'
      },
    });

    OrganizationCreateDialogRef.afterClosed().subscribe(result => {
      this.getOrganizations();
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getOrganizations();
  }
}
