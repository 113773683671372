import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-edit-button',
  templateUrl: './table-edit-button.component.html',
  styleUrls: ['./table-edit-button.component.css']
})
export class TableEditButtonComponent {
  
  @Input() imageSrc: string;
  @Input() tooltip: string;
  @Input() color: string = 'primary';
  @Output() buttonClick = new EventEmitter<void>();

  onClick() {
    this.buttonClick.emit();
  }

}
