    <div class="dialog-headline">
    <mat-label style="float:left;">Add Custom Pay Type</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
  </div>
  <mat-dialog-content class="mat-typography" style="padding-top: 20px;">
  
    <form #payTypeModalForm="ngForm" autocomplete="off">
  
      <div class="form-group row">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="30">
          <mat-form-field>
            <input matInput placeholder="Pay Type Description" name="payTypeDesc" #payTypeDesc="ngModel"
              id="payTypeDesc" [(ngModel)]="payTypeData.payTypeDesc" ng-pattern="[0-9-_!@#$%&*-+()=,.?/';:]"
              required>
          </mat-form-field>
          <mat-error *ngIf="payTypeDesc.invalid && (payTypeDesc.dirty || payTypeDesc.touched)" class="invalid-feedback">
            Please enter valid Pay Type Description
          </mat-error>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">
    <button mat-stroked-button mat-dialog-close matTooltip="Close this dialog" style="background-color: #6C747E;width: 
        80px;height: 40px;color: #FFFFFF; border-radius: 
        4px;font-size: 14px;text-align: center;line-height: 20px;">
        Close
    </button>
    
      <button mat-flat-button color="primary"
      type="submit" *ngIf="!payTypeData.ID"
      [disabled]="payTypeModalForm.invalid || payTypeData.payTypeDesc.toString().trim().length==0" [mat-dialog-close]="payTypeData"
        style="background-color: #F59120;width: 
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
        >Create</button>

  </mat-dialog-actions>
  
  
  
  