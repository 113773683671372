

<div fxLayout="row wrap" style="padding-left: 15px; gap: 15px;">
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center" style="gap: 5px;">
    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex="100">
      <app-page-header
        [icon]="'assets/images/icons/icon-EDI-blue.jpeg'"
        [title]="'User'"
        [breadcrumb]="''"
      ></app-page-header>
    </div>
    <div fxFlex.gt-lg="12" fxFlex.gt-md="12" fxFlex="100" style="margin-top: 15px;">
      <mat-form-field style="width: 100%;">
        <input matInput placeholder="User Name" [(ngModel)]="user.userName" name="UserName">
      </mat-form-field>
    </div>
    <div fxFlex.gt-lg="2" fxFlex.gt-md="2" fxFlex="100"></div>
    <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex="100" style="margin-top: 15px;">
      <mat-form-field>
        <mat-label>Select NPINo</mat-label>
        <input type="text" matInput [formControl]="npiControl" [matAutocomplete]="auto" (input)="onInput($event)" (keypress)="keyPressNumeric($event)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNPINos"
            (optionSelected)="setNPINoToModel($event);">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.NPINo}}--{{option.storeName}}
            </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxFlex.gt-lg="1" fxFlex.gt-md="1" fxFlex="100"></div>
    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex="100" style="margin-top: 15px;">
      <button  class="custom-blue-button"   type="button" id="btnCreate" style="width: 85px;"
              (click)="getUsers()">
          <mat-icon color="white">search</mat-icon>Search</button>
    </div>
    <div fxFlex="auto" fxLayoutAlign="end center" style="margin: 9px; padding: 10px;">
      <button class="custom-orange-button" type="button" id="btnCreate" 
                style="width: 100px; height: 45px; margin: 0; padding: 0;" (click)="openUserCreateModal()">
            Create New
        </button>
  </div>
  </div>
</div>



<!-- <div fxLayout="row wrap" style="padding: 10px;">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100" >
      <mat-form-field>
        <input matInput placeholder="User Name" [(ngModel)]="user.userName" name="UserName">
      </mat-form-field>
    </div>
    <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" >
      <mat-form-field>
        <mat-label>Select NPINo</mat-label>
        <input type="text" matInput [formControl]="npiControl" [matAutocomplete]="auto" (input)="onInput($event)" (keypress)="keyPressNumeric($event)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNPINos"
            (optionSelected)="setNPINoToModel($event);">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.NPINo}}--{{option.storeName}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
        
    </div>
    
  </div>
  
</div> -->



<!-- <div class="example-container mat-elevation-z8">

  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100"
    style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">
    <mat-card>
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100"
                  style="padding-top: 10px;padding-right: 10px;">
                  <button mat-raised-button matTooltip="Export to excel file"
                      (click)="exporter.exportTable('xlsx', {fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Excel
                  </button>
                  <button mat-raised-button matTooltip="Export to csv file"
                      (click)="exporter.exportTable('csv',{fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Csv
                  </button>
                  <button mat-raised-button matTooltip="Export to json file"
                      (click)="exporter.exportTable('json',{fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Json
                  </button>
                  <button mat-raised-button matTooltip="Export to txt file"
                      (click)="exporter.exportTable('txt',{fileName:'user_Data', sheet: 'user_Data', Props: {Author: 'Ediv2Web'}})">
                      <mat-icon>description</mat-icon>Txt
                  </button>
              </div>
  
              <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100"></div>
  
      </div>

    </mat-card>
    
  
    
  </div>
</div> -->
<div fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
          <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
              <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                  <div class="table-container" style="width: 100%;">
                    <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                      <!-- Checkbox Column -->
                
                      <ng-container color="primary" matColumnDef="NPINo">
                        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>NPINo</b> </mat-header-cell>
                        <mat-cell color="primary" *matCellDef="let element"> {{element.NPINo}} </mat-cell>
                      </ng-container>
                
                      <ng-container color="primary" matColumnDef="userName">
                        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>User Name</b> </mat-header-cell>
                        <mat-cell color="primary" *matCellDef="let element"> {{element.userName}} </mat-cell>
                      </ng-container>
                
                      <ng-container color="primary" matColumnDef="lName">
                        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>Name</b> </mat-header-cell>
                        <mat-cell color="primary" *matCellDef="let element"> {{element.lName}} </mat-cell>
                      </ng-container>
                
                      <ng-container color="primary" matColumnDef="noOfAttempt">
                        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>No of Login Attempts</b>
                        </mat-header-cell>
                        <mat-cell color="primary" style="text-align: right;" *matCellDef="let element"> {{element.noOfAttempt}}
                        </mat-cell>
                      </ng-container>
                
                      <ng-container color="primary" matColumnDef="isLocked">
                        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>Is Locked?</b> </mat-header-cell>
                        <mat-cell color="primary" *matCellDef="let element"> {{element.isLocked}} </mat-cell>
                      </ng-container>
                
                      <ng-container color="primary" matColumnDef="isActive">
                        <mat-header-cell color="primary" *matHeaderCellDef mat-sort-header><b>Is Active?</b> </mat-header-cell>
                        <mat-cell color="primary" *matCellDef="let element"> {{element.isActive}} </mat-cell>
                      </ng-container>
                
                      <ng-container matColumnDef="Action">
                        <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                          <!-- <button mat-icon-button matTooltip="Edit user" color="primary" (click)="openUpdateUserModal(element);">
                            <mat-icon>mode_edit</mat-icon>
                          </button> -->
                          <app-table-edit-button
                          [imageSrc]="''"
                          [tooltip]="'Edit ' + element.userName"
                          [color]="'primary'"
                          (buttonClick)="openUpdateUserModal(element)">
                          </app-table-edit-button>
                          <!-- <button mat-icon-button matTooltip="Delete user" color="warn" (click)="deleteUser(element);">
                            <mat-icon>delete_outline</mat-icon>
                          </button> -->
                          <app-table-delete-button
                          [imageSrc]="''"
                          [tooltip]="'Delete ' + element.userName"
                          [color]="'warn'"
                          (buttonClick)="openUpdateUserModal(element)">
                          </app-table-delete-button>
                        </mat-cell>
                      </ng-container>
                
                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>
                
                    </mat-table>
                  </div>

                  <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                  </mat-paginator>

              </div>
          </mat-card>
      </div>
  </div>
</div>