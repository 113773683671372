import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, map } from "rxjs";
import { constant } from "../constant/constant";
import { ErrorHandlerService } from "./error-handler.service";
import { ApiResponse } from "../models/ApiResponse";
@Injectable({
  providedIn: 'root'
})

export class OrganizationService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }


  GetOrganizationOnLoad() {

    return this.httpClient.get<ApiResponse<any>>((constant.ORGANIZATION_SERVICE_URL + constant.GET_ALL_ORGANIZATIONS), constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetOrganizationByPagination(pageData: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.ORGANIZATION_SERVICE_URL + constant.GET_ALL_ORGANIZATIONS), pageData, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  SearchOrganization(organization: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.ORGANIZATION_SERVICE_URL + constant.GET_ORGANIZATION_ON_SEARCH), organization, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Insert(organization: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.ORGANIZATION_SERVICE_URL + constant.INSERT_ORGANIZATION), organization, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Update(organization: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.ORGANIZATION_SERVICE_URL + constant.UPDATE_ORGANIZATION), organization, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Delete(organization: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.ORGANIZATION_SERVICE_URL + constant.DELETE_ORGANIZATION), organization, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
}