import { Pagination } from "./Pagination";


export class GetProviderRequest {
    identifier: string;
    providerID: number;
    pharmacyName: string;
    isActive: boolean;
    tollFreePhoneNumber: string;
    nabp: string;
    // identifierType: number; //•	NPI (1), NABP (2), Patient (3), Prescriber (4)
    // identifier: string;
    // providerID: number; //Twilio (1), Bandwidth (2)
    // pharmacyName: string;
    // tollFreePhoneNumber: string;
    // statusID: number;
    // //pagination: Pagination = new Pagination;
}