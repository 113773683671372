import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './materialComponents/material.module';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PromptDialogComponent } from './utility/prompt-dialog/prompt-dialog.component';
import { AlertDialogComponent } from './utility/alert-dialog/alert-dialog.component';
import { StoreConfiguratorModule } from './storeConfiguratorComponents/store-configurator.module';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { CountdownAlertComponent } from './utility/countdown-alert/countdown-alert/countdown-alert.component';
import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { LoginWithoutCaptchaComponent } from './loginWithoutCaptcha/login-without-captcha.component';
import { RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NavbarComponentComponent } from './layout/navbar/navbar-component/navbar-component.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EdiModule } from './ediComponents/edi.module';
import { AuthModule } from '@auth0/auth0-angular';




@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    PromptDialogComponent,
    AlertDialogComponent,
    CountdownAlertComponent,
    LoginWithoutCaptchaComponent,
    NavbarComponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    StoreConfiguratorModule,
    EdiModule,
    HttpClientModule,
    MatTableModule,
    MatSelectModule,
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxUiLoaderHttpModule.forRoot({
        showForeground: true,
    }),
    RecaptchaFormsModule,
    RecaptchaModule,
    MatButtonModule,
    MatIconModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    AuthModule.forRoot({
        domain: environment.domain,
        clientId: environment.clientId,
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: environment.audience
        }
    })
  ],

  providers: [
    [{ provide: LocationStrategy, useClass: HashLocationStrategy}],
    [{ provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings, },],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }