import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ediconstant } from 'src/app/constant/ediconstant';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';
import { Observable, catchError } from 'rxjs';
import { ErrorHandlerService } from '../error-handler.service';


@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }


  getFileDowloadData(fileData: FileDownloadRequest) {
    return this.httpClient.post<any>(ediconstant.getfileDownloadUrl, fileData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  getPODetails(fileData: FileDownloadRequest): Observable<any> {
    return this.httpClient.post<any>(ediconstant.getPODetails, fileData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
}