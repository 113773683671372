<div class="main-container">



    <mat-toolbar color="#FFFFFF" class="topbar telative" style="background-color: #FFFFFF;">

        <div class="navbar-header" style="text-align: left;">

            <a class="navbar-brand">
                <b>

                    <img src="assets/images/logos/PrimeRx-Logo.jpg" width="90%" alt="homepage" class="light-logo">

                </b>

                <span fxShow="false" fxShow.gt-xs>

                </span> </a>
        </div>

        <!-- <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed" color="primary" matTooltip="Show/Hide Menu">
            <mat-icon>menu</mat-icon>
        </button> -->



        <span fxFlex></span>

        <app-header></app-header>

    </mat-toolbar>



    <!-- <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <mat-sidenav #snav class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'"
            style="background-color: #1B2033;"  fixedTopGap="0" 
            [opened]="mobileQuery.matches"  [disableClose]="mobileQuery.matches " >
           
            <app-sidebar>
            </app-sidebar>
            
        </mat-sidenav>
        
        <mat-sidenav-content class="page-wrapper">
            <div>
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container> -->
    <mat-drawer-container class="example-container mat-typography" autosize>
        <mat-drawer #drawer mode="side" disableClose="true" opened="true" style="background-color: #1B2033;"
            [ngStyle]="{'width.px': isExpanded ? 50 : 250}">

            <app-sidebar [sidenavOpened]="isExpanded">
            </app-sidebar>

            <div style="position: absolute; bottom: 50px; left: 45%; transform: translateX(-50%); text-align: center;">
                <button mat-icon-button (click)="isExpanded = !isExpanded" value="sidebarclosed" color="#F59120"
                    [matTooltip]="getTooltipText()" style="padding-left: 10px;color: #F59120;">
                    <mat-icon>chevron_left</mat-icon>
                </button>
            </div>

            <div *ngIf="!isExpanded"
                style="position: absolute; bottom: 30px; left: 45%; transform: translateX(-50%); text-align: center;">
                <mat-label style="color: #ADADAD;font-size:x-small;">Version No.: 1.0.0</mat-label>
            </div>
            <!-- Move the mat-label to the bottom -->
            <div *ngIf="!isExpanded"
                style="position: absolute; bottom: 10px; left: 10%; transform: translateX(-5%); text-align: center;">
                <mat-label style="color: #ADADAD;font-size:x-small;">2024 © EPrimePOS All rights reserved</mat-label>
            </div>


        </mat-drawer>

        <mat-drawer-content class="page-wrapper">
            <div>
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>

    </mat-drawer-container>
</div>