import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  userIdle: any;

  constructor(private router: Router) { }

  //open prompt

  
  showAlert(alertData:any) {
    this.userIdle.startWatching();
    
     // Start watching when user idle is starting.
     this.userIdle.onTimerStart().subscribe((count: any) => console.log(count));
     
     // Start watch when time is up.
     this.userIdle.onTimeout().subscribe(() =>
     Swal.fire({
      title: "Timeout !",
      text: "You will be redirected to Login Page",
      icon: 'warning'
    }).then(function () {
    })
    );

  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }
}
