<button mat-flat-button
        [disabled]="disabled"
        (click)="handleClick()"
        style="background-color: #6C747E;
        width: 80px;
        height: 40px;
        color: #FFFFFF; 
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
        line-height: 20px;">
  {{ label }}
</button>
