//#region EPPOS-3788
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})

export class ItemService {

  constructor(private httpClient: HttpClient) { }

  insertDefaultItem(item: any) {
    return this.httpClient.post<any>(constant.insertDefaultItem, item, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
//#endregion
