import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../utility/alert-dialog/alert-dialog.component';
import { CountdownAlertComponent } from '../utility/countdown-alert/countdown-alert/countdown-alert.component';
import { PromptDialogComponent } from '../utility/prompt-dialog/prompt-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(public dialog: MatDialog) { }

  //open prompt

  openPrompt(promptTitle: string, promptMessage: string) {

    const promptDialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        promptTitle: promptTitle,
        promptMessage: promptMessage
      },width: '25%'
    });

    return promptDialogRef.afterClosed();
  }

  //show respective alerts
  showAlert(alertType: any, alertTitle: any, alertMessage: any) {
    const alertDialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        alertType: alertType,
        alertTitle: alertTitle,
        alertMessage: alertMessage
      }, width: '25%',
      disableClose:true 
    });
    return alertDialogRef.afterClosed();

  }

  showCountDownAlert(alertType: any, alertTitle: any, alertMessage: any) {
    const alertDialogRef = this.dialog.open(CountdownAlertComponent, {
      data: {
        alertType: alertType,
        alertTitle: alertTitle,
        alertMessage: alertMessage
      }, width: '20%'
    });

    return alertDialogRef.afterClosed();

  }
 

  AllowOnlyNumber(event: any) {

    var inputValue = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inputValue)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowOnlyCharacter(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[A-Za-z]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowAlphaNumericChar(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[A-Za-z0-9]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowAlphaNumericCharWithSpace(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[A-Za-z0-9\s]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowCharacterWithSpace(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[A-Za-z ]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AllowDecimal(event: any) {

    // Allow numbers, alpahbets, space, underscore
    if (/^[0-9-.]$/.test(event.key)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

   IsNullOrWhiteSpace(str: string | null | undefined): boolean {
    return !str || str.trim() === '';
  }

}
