<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<!-- <button [matMenuTriggerFor]="notifications" mat-icon-button class="m-r-5"> <mat-icon color="primary">notifications</mat-icon> </button> -->
<div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; height: 30px; display: flex; align-items: center;">
    <h2 style="display: inline-block; margin: 0;color: #0C7BB8;font-weight: bold;font-size: 18px">{{ this.user }}</h2>
    <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <mat-icon color="primary">account_circle</mat-icon>
    </button>
</div>


<mat-menu #profile="matMenu" class="mymegamenu">
    <!-- <button mat-menu-item>
        <mat-icon>settings</mat-icon> Settings </button>
    <button mat-menu-item>
        <mat-icon>account_box</mat-icon> Profile </button>
    <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon> Disable notifications </button> -->
    <button mat-menu-item (click)="logOutUser()">
        <mat-icon>exit_to_app</mat-icon> Logout </button>
</mat-menu>

<mat-menu #notifications="matMenu" class="mymegamenu">
    <!-- <li style="padding: 5px;">
        Message 1
    </li>
    <li style="padding: 5px;">
        Message 2
    </li> -->

</mat-menu>