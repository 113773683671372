<h2 mat-dialog-title>{{data.alertTitle}}</h2>
<div>


    <section fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="30px">
        <!-- <div fxFlex>
            <mat-progress-spinner mode="determinate" diameter="120" [color]="colorValue" [value]="counter">
            </mat-progress-spinner>
            <h1 style="padding-top: 15px;">{{showCounter}}</h1>
        </div> -->
        <div fxFlex>
            <label>{{data.alertMessage}}</label>
        </div>
    </section>
</div>

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-stroked-button mat-dialog-close color="warn" (click)="onCancelClick()">Cancel</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="true" (click)="replaceContract()"
        cdkFocusInitial>Replace</button>
</div>