import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-countdown-alert',
  templateUrl: './countdown-alert.component.html',
  styleUrls: ['./countdown-alert.component.css']
})
export class CountdownAlertComponent implements OnInit {

  counter: number = 100;
  showCounter: number = 20;
  public colorValue: string = "primary";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CountdownAlertComponent>, private userService: UserService) { }

  ngOnInit(): void {
    this.counter = 100;
    const interval = setInterval(() => {
      console.log(this.counter);
      this.counter -= 5;
      this.showCounter -= 1;

      if (this.counter == 65) {
        this.colorValue = "accent";
      }
      if (this.counter == 35) {
        this.colorValue = "warn";
      }
      if (this.counter == 0) {
        clearInterval(interval);
        this.colorValue = "primary";
        this.dialogRef.close();
        this.userService.logOutUserFromTimeOut()
      }
    }, 1000);
  }

  onCancelClick() {

    window.location.reload();
  }

  logoutUser() {
    this.dialogRef.close();
    this.userService.logOutUserFromTimeOut()
  }


}
