import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { table } from 'console';
import { StoreGeneralConfig } from 'src/app/models/StoreGeneralConfig';
import { Vendor } from 'src/app/models/Vendor';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { VendorOrderService } from 'src/app/services/VendorOrder/vendorOrder.service';
import { VendorPollerService } from 'src/app/services/VendorPoller/vendorPoller.service';
import { VendorPriceUpdateService } from 'src/app/services/VendorPriceUpdate/vendorPriceUpdate.service';

@Component({
  selector: 'app-cron-dialog',
  templateUrl: './cron-dialog.component.html',
  styleUrls: ['./cron-dialog.component.css']
})
export class CronDialogComponent implements OnInit {

  schedulerCronExpr: string = '';
  tabId: string = '';
  cronText: string = '';
  storegeneralconfig: any = {};
  cronExpressionInput: any={};

  selectedIndex: number = 0;
  maxNumberOfTabs: number = 6;
  minutesArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
  hoursArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  minutesFrom0Array: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
  hoursFrom0Array: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  daysArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  daysWeekArray: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  monthsNumberArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  monthsArray: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Decemer'];
  isSunChecked: boolean = false;
  isMonChecked: boolean = false;
  isTueChecked: boolean = false;
  isWedChecked: boolean = false;
  isThuChecked: boolean = false;
  isFriChecked: boolean = false;
  isSatChecked: boolean = false;

  schedulerText: string[] = [];
  expressionParts: string[] = [];
  tabPanelId: string = '';
  days: string = "";

  minTabMinValue: number = 1;
  hourTabHourValue: number = 1;
  dailyTabDayValue: number = 1;
  dailyTabMinValue: number = 0;
  dailyTabHourValue: number = 1
  weeklyTabMinValue: number = 0;
  weeklyTabHourValue: number = 1;
  monthlyTabDayValue: number = 1;
  monthlyTabMonthValue: number = 1;
  monthlyTabMinValue: number = 0;
  monthlyTabHourValue: number = 1


  receivedCronExpression: string = '';
  intervalType: string = '';
  constructor(private vendorService: VendorService, private utilityService: EdiUtilityService, @Inject(MAT_DIALOG_DATA) public data: any, private cronDialogComponentRef: MatDialogRef<CronDialogComponent>,
    private vendorPriceUpdateService: VendorPriceUpdateService, private vendorOrderService: VendorOrderService, private vendorPollerService: VendorPollerService) {
    this.receivedCronExpression = data.receivedCronExpression;
    this.intervalType = data.intervalType;

  }
  ngOnInit(): void {
    this.loadCronScreen();
  }

  loadCronScreen() {
    this.schedulerText = this.receivedCronExpression.split('|'); //Split the text to seperate panel name and cron string
    this.expressionParts = this.schedulerText[1].split(' ');
    this.tabPanelId = this.schedulerText[0].split(',')[0].toString();
    this.selectedIndex = +this.tabPanelId;

    switch (this.tabPanelId) {
      case '0'://Minute
        this.minTabMinValue = +(this.expressionParts[1].split('/')[1]);
        break;
      case '1'://Hourly
        this.hourTabHourValue = +(this.expressionParts[2].split('/')[1]);
        break;
      case '2'://Daily
        this.dailyTabMinValue = +(this.expressionParts[1]);
        this.dailyTabHourValue = +(this.expressionParts[2]);
        this.dailyTabDayValue = +(this.expressionParts[3].split('/')[1]);
        break;
      case '3'://Weekly
        this.weeklyTabMinValue = +(this.expressionParts[1]);
        this.weeklyTabHourValue = +(this.expressionParts[2]);
        this.days = this.expressionParts[5];

        this.isSunChecked = this.days.includes('SUN');
        this.isMonChecked = this.days.includes('MON');
        this.isTueChecked = this.days.includes('TUE');
        this.isWedChecked = this.days.includes('WED');
        this.isThuChecked = this.days.includes('THU');
        this.isFriChecked = this.days.includes('FRI');
        this.isSatChecked = this.days.includes('SAT');
        break;
      case '4'://Monthly
        this.monthlyTabDayValue = +(this.expressionParts[3]);
        this.monthlyTabMonthValue = +(this.expressionParts[4].split('/')[1]);
        this.monthlyTabHourValue = +(this.expressionParts[2]);
        this.monthlyTabMinValue = +(this.expressionParts[1]);
        break;
    }
  }
  generateCronExpressionAndSave() {
    this.schedulerCronExpr = '';
    this.tabId = '';
    {
      try {
        switch (this.selectedIndex) {
          case 0: //Minutes tab
            this.schedulerCronExpr = "0 0/" + this.minTabMinValue.toString() + " * 1/1 * ? *";
            this.tabId = "0,0|";
            this.cronText = "Runs after every " + this.minTabMinValue.toString() + " minute(s) starts from today";
            break;
          case 1: //Hourly tab
            this.schedulerCronExpr = "0 0 0/" + this.hourTabHourValue.toString() + " 1/1 * ? *";
            this.tabId = "1,0|";
            this.cronText = "Runs after every " + this.hourTabHourValue.toString() + " hour(s) starts from today";
            break;
          case 2: //Daily tab
            this.schedulerCronExpr = "0 " + this.dailyTabMinValue.toString() + " " + this.dailyTabHourValue.toString() + " 1/" + this.dailyTabDayValue.toString() + " * ? *";
            this.tabId = "2,0|";
            this.cronText = "Runs after every " + this.dailyTabDayValue.toString() + " day(s) at " + this.dailyTabHourValue.toString() + " hour and " + this.dailyTabMinValue.toString() + " minute(s) starting from today";
            break;
          case 3: //Weekly tab
            this.tabId = "3,0|";
            var days = "";
            if (this.isSunChecked)
              days = ',SUN';
            if (this.isMonChecked)
              days = days + ',MON';
            if (this.isTueChecked)
              days = days + ',TUE';
            if (this.isWedChecked)
              days = days + ',WED';
            if (this.isThuChecked)
              days = days + ',THU';
            if (this.isFriChecked)
              days = days + ',FRI';
            if (this.isSatChecked)
              days = days + ',SAT';
            days = days.substring(1);
            this.schedulerCronExpr = "0 " + this.weeklyTabMinValue.toString() + " " + this.weeklyTabHourValue.toString() + " ? * " + days + " *";
            this.cronText = "Runs on " + days + " at " + this.weeklyTabHourValue.toString() + " hour(s) and " + this.weeklyTabMinValue.toString() + " minute(s) starting from today";
            break;
          case 4: //Monthly tab
            this.schedulerCronExpr = "0 " + this.monthlyTabMinValue.toString() + " " + this.monthlyTabHourValue.toString() + " " + this.monthlyTabDayValue.toString() + " 1/" + this.monthlyTabMonthValue.toString() + " ? *";
            this.tabId = "4,0|";
            this.cronText = "Runs on every " + this.monthlyTabDayValue.toString() + " day after every " + this.monthlyTabMonthValue.toString() + " month(s) at " + this.monthlyTabHourValue.toString() + " hour(s) and " + this.monthlyTabMinValue.toString() + " minute(s) starting from today";
            break;
          // case 5: //Yearly tab
          //   this.schedulerCronExpr = "0 " + numYearlyMinutes.Value.toString() + " " + numYearlyHour.Value.toString() + " " + numYearlyDays.Value.toString() + " " + ultracboMonths.Value.toString() + " ? *";
          //   this.tabId = "5,0|";
          //   this.cronText = "Fires on " + numYearlyDays.Value.toString() + " of month " + ultracboMonths.Text + " at " + numYearlyHour.Value.toString() + " hour(s) and " + numYearlyMinutes.Value.toString() + " minute(s) starting from today";
          //   break;
        }
      }
      catch (error) {
        console.log(error);
      }
      this.cronExpressionInput.cronExpression = this.schedulerCronExpr;
      
      this.schedulerCronExpr = this.tabId + this.schedulerCronExpr;
      this.storegeneralconfig.IntervalType = this.intervalType;
      this.storegeneralconfig.CronExpression = this.schedulerCronExpr;
      this.storegeneralconfig.CronDescription = this.cronText;



      this.vendorService.SaveFTPScheduler(this.storegeneralconfig).subscribe(response => {
        if (response.result == "SUCCESS") {
          if (this.intervalType == "PriceUpdate832") {
            this.vendorPriceUpdateService.UpdateCronScheduler(this.cronExpressionInput).subscribe(response => {
              if (response.result == "SUCCESS") {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "PriceUpdate832 scheduler interval saved and service have been registered with new interval."
                )
              }
              else {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "PriceUpdate832 scheduler interval saved but failed to register service with new interval. Need to restart service."
                )
              }
            })
          }
          else if (this.intervalType == "Acknowledgement855") {
            this.vendorOrderService.UpdatePOResponse855CronScheduler(this.cronExpressionInput).subscribe(response => {
              if (response.result == "SUCCESS") {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "Acknowledgement855 scheduler interval saved and service have been registered with new interval."
                )
              }
              else {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "Acknowledgement855 scheduler interval saved but failed to register service with new interval. Need to restart service."
                )
              }
            })
          }
          else if (this.intervalType == "Invoice810") {
            this.vendorOrderService.UpdatePOResponse810CronScheduler(this.cronExpressionInput).subscribe(response => {
              if (response.result == "SUCCESS") {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "Invoice810 scheduler scheduler interval saved and service have been registered with new interval."
                )
              }
              else {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "Invoice810 scheduler interval saved but failed to register service with new interval. Need to restart service."
                )
              }
            })
          }
          else if (this.intervalType == "PurchaseOrder850") {
            this.vendorOrderService.UpdatePORequestCronScheduler(this.cronExpressionInput).subscribe(response => {
              if (response.result == "SUCCESS") {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "PurchaseOrder850 scheduler scheduler interval saved and service have been registered with new interval."
                )
              }
              else {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "PurchaseOrder850 scheduler interval saved but failed to register service with new interval. Need to restart service."
                )
              }
            })
          }
          else if (this.intervalType == "FileDownload") {
            this.vendorPollerService.UpdateCronScheduler(this.cronExpressionInput).subscribe(response => {
              if (response.result == "SUCCESS") {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "FileDownload scheduler scheduler interval saved and service have been registered with new interval."
                )
              }
              else {
                this.utilityService.showAlert(
                  "SUCCESS",
                  "Saved",
                  "FileDownload scheduler interval saved but failed to register service with new interval. Need to restart service."
                )
              }
            })
          }

          this.cronDialogComponentRef.close(true);
        }
        else {
          this.utilityService.showAlert(
            "ERROR",
            "Failed",
            "Error occurred while saving scheduler interval."
          )
        }
      })
    }
  }

  onTabChanged($event: any) {
    this.selectedIndex = $event.index;

  }
}