import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { FileDownloadRequest } from 'src/app/models/RequestModel/FileDownloadRequest';
import { FileParseErrorRequest } from 'src/app/models/RequestModel/FileParseErrorRequest';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class FileParsingErrorService {

  constructor(private httpClient: HttpClient,private errorHandlerService: ErrorHandlerService) { }


  getFileParseErrorData(fileData: FileParseErrorRequest) {
    // return this.httpClient.post<any>(constant.getfileParseErrorUrl, fileData, constant.httpClientHeader);

    return this.httpClient.post<any>(ediconstant.getfileParseErrorUrl, fileData, ediconstant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

}
