<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div aria-hidden="true">
    <div class="dialog-headline">
        <mat-label class="headline-title">{{receivedData.headerTitle}}</mat-label>
        <mat-icon class="pointer close-icon" matTooltip="Close this dialog" mat-dialog-close>close</mat-icon>
    </div>
    <mat-dialog-content class="mat-typography" style="padding-top: 20px;">
        <form #providerModalForm="ngForm" autocomplete="off" data-keyboard="false" data-backdrop="static" aria-hidden="true">
        
            <!-- <div fxLayout="row wrap" style="padding-bottom: 10px;">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxLayout="row" fxLayoutAlign="start center" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-radio-group [(ngModel)]="pharmacyConfiguration.providerID" name="selection" fxLayout="row" fxLayoutGap="15px">
                            <mat-radio-button [value]="1" style="margin: 0px; padding-top: 5px;" class="custom-radio">
                                Twilio
                            </mat-radio-button>
                            <mat-radio-button [value]="2" style="margin: 0; padding-top: 5px;padding-left: 10px;" class="custom-radio">
                                Bandwidth
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        
                    </div>
                </div>
            </div> -->
            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field >
                            <!-- <mat-label>Provider</mat-label> -->
                            <mat-select #providerID="ngModel" placeholder="Provider" name="providerID" [(ngModel)]="pharmacyConfiguration.providerID" >
                                <mat-option *ngFor="let eachProvider of providersArray" [value]="eachProvider.providerID">
                                    {{ eachProvider.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="NABP" name="PharmNabp" id="PharmNabp" #PharmNabp="ngModel"  aria-label="PharmNabp"
                            [(ngModel)]="pharmacyConfiguration.nabp" required maxlength="7" minlength="7" mask='0000000'
                            [disabled]="receivedData.headerTitle=='Update Pharmacy Provider Configuration'" 
                            (ngModelChange)="onPharmacyNpiChange()" (blur)="checkAndCallGetPharmacyName()" >
                            <mat-error *ngIf="PharmNabp.invalid && (PharmNabp.dirty || PharmNabp.touched)" class="invalid-feedback">
                                Please enter NABP
                              </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        
                        <mat-form-field>
                            <input matInput placeholder="Pharmacy NPI #" name="Identifier" #Identifier="ngModel" id="Identifier"
                              [(ngModel)]="pharmacyConfiguration.identifier"readonly [readonly]="true" disabled="true"
                              
                              autocomplete="off">
                            <mat-error *ngIf="Identifier.invalid && (Identifier.dirty || Identifier.touched)" class="invalid-feedback">
                              Please enter Pharmacy NPI #
                            </mat-error>
                          </mat-form-field>
                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="Pharmacy Name" name="pharmName" id="pharmName" #pharmName="ngModel"  aria-label="pharmName"
                            [(ngModel)]="pharmacyConfiguration.pharmacyName" readonly [readonly]="true" disabled="true">
                            <mat-error *ngIf="pharmName.invalid && (pharmName.dirty || pharmName.touched)" class="invalid-feedback">
                                Please enter Pharmacy Name
                              </mat-error>
                        </mat-form-field>
                        <!-- <label style="text-align: left;  padding-top: 10px;">{{pharmacyConfiguration.pharmacyName}}</label> -->
                    </div>
                    
                </div>
            </div>
            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="Pharmacy Address" name="pharmacyAdd" id="pharmacyAdd" #pharmacyAdd="ngModel"  aria-label="PharmacyAdd"
                            [(ngModel)]="pharmacyConfiguration.address"  
                            disabled="true"
                            required>
                            <mat-error *ngIf="pharmacyAdd.invalid && (pharmacyAdd.dirty || pharmacyAdd.touched)" class="invalid-feedback">
                                Please enter Pharmacy Adderss
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div fxLayout="row wrap" *ngIf="pharmacyConfiguration.providerID==1">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <div style="display: flex; align-items: center;">
                                <input appTrimSpaceInput matInput placeholder="Account SID" name="AccountSID" #AccountSID="ngModel" id="AccountSID"
                                    [(ngModel)]="pharmacyConfiguration.accountSID" required [type]="hideAccountSID ? 'password' : 'text'" autocomplete="new-account-sid" autocomplete="off">
                                <!-- <button mat-icon-button [matMenuTriggerFor]="infoMenu" class="info-button">
                                    <mat-icon class="info-icon">info</mat-icon>
                                </button>
                                <mat-menu #infoMenu="matMenu">
                                    <div style="padding: 10px; max-width: 200px;">
                                        A String Identifier (SID) is a unique key that is used to identify specific resources.
                                        Every Twilio resource has a 34 digit SID. You can use the first two characters of the SID to identify its type. 
                                        Many of our API calls will return a JSON object containing another resource's SID. Using this SID, you can retrieve more information about the specific resource.
                                    </div>
                                </mat-menu> -->
                                <img class="pointer" *ngIf="!hideAccountSID" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
                                    src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
                                    (click)="hideAccountSID = !hideAccountSID">
                                <img class="pointer" *ngIf="hideAccountSID" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
                                    src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
                                    (click)="hideAccountSID = !hideAccountSID">
                            </div>
                            
                            <mat-error *ngIf="AccountSID.invalid && (AccountSID.dirty || AccountSID.touched)" class="invalid-feedback">
                                Please enter Account SID
                            </mat-error>
                        </mat-form-field>
                        
                        
                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <div style="display: flex; align-items: center;">
                                <input appTrimSpaceInput matInput placeholder="Auth. Token" name="AuthToken" #AuthToken="ngModel" id="AuthToken"
                                [(ngModel)]="pharmacyConfiguration.authToken" required [type]="hideAuthToken ? 'password' : 'text'">
                                
                                <img class="pointer" *ngIf="!hideAuthToken" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
                                src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility"
                                (click)="hideAuthToken = !hideAuthToken">
                                <img class="pointer" *ngIf="hideAuthToken" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
                                src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility"
                                (click)="hideAuthToken = !hideAuthToken">
                            </div>
                            
                            <mat-error *ngIf="AuthToken.invalid && (AuthToken.dirty || AuthToken.touched)" class="invalid-feedback">
                                Please enter Auth. Token
                            </mat-error>
                            
                        </mat-form-field>
                       
                    </div>
                </div>
            </div>
            <div fxLayout="row wrap" *ngIf="pharmacyConfiguration.providerID==1">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input appTrimSpaceInput matInput placeholder="Toll Free #" name="TollFreePhoneNumber" #TollFreePhoneNumber="ngModel" id="TollFreePhoneNumber"
                                [(ngModel)]="pharmacyConfiguration.tollFreePhoneNumber" required minlength="10" mask='(000)000-0000'>
                                <mat-error *ngIf="TollFreePhoneNumber.invalid && (TollFreePhoneNumber.dirty || TollFreePhoneNumber.touched)" class="invalid-feedback">
                                    Please enter Toll Free Number
                                </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 20px;">
                        <!-- <mat-form-field>
                            <input appTrimSpaceInput matInput placeholder="Phone Number" name="PhoneNumber" #PhoneNumber="ngModel" id="PhoneNumber"
                                [(ngModel)]="pharmacyConfiguration.phoneNumber" minlength="10" mask='(000)000-0000'>
                            
                        </mat-form-field> -->
                        <mat-checkbox [(ngModel)]="pharmacyConfiguration.active" name="Activated" class="custom-checkbox">
                            Active
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div fxLayout="row wrap" *ngIf="pharmacyConfiguration.providerID===2">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <!-- <mat-form-field>
                            <input appTrimSpaceInput matInput placeholder="Phone Number" name="PhoneNumber" #PhoneNumber="ngModel" id="PhoneNumber"
                                [(ngModel)]="pharmacyConfiguration.phoneNumber" minlength="10" mask='(000)000-0000'>
                                
                        </mat-form-field> -->
                        <mat-form-field>
                            <input appTrimSpaceInput matInput placeholder="Toll Free #" name="TollFreePhoneNumber" #TollFreePhoneNumber="ngModel" id="TollFreePhoneNumber"
                                [(ngModel)]="pharmacyConfiguration.tollFreePhoneNumber" required minlength="10" mask='(000)000-0000'>
                                <mat-error *ngIf="TollFreePhoneNumber.invalid && (TollFreePhoneNumber.dirty || TollFreePhoneNumber.touched)" class="invalid-feedback">
                                    Please enter Toll Free Number
                                </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 20px;">
                        <mat-checkbox [(ngModel)]="pharmacyConfiguration.active" name="Activated" class="custom-checkbox">
                            Active
                        </mat-checkbox>
                    </div>
                </div>
            </div>

           
            <div fxLayout="row wrap" style="padding-top: 10px;">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                  <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        
                  </div>
                </div>
              </div>
       
        </form>
    
    </mat-dialog-content>
    <hr>
    <mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">

       
    
        <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width: 
            80px;height: 40px;color: #FFFFFF; border-radius: 
            4px;font-size: 14px;text-align: center;line-height: 20px;">
          Close
        </button>

        <button mat-flat-button color="primary" (click)="UpdateProvider()"
        *ngIf="receivedData.headerTitle=='Update Pharmacy Provider Configuration'"
         style="background-color: #F59120;width: 
            80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
          [disabled]="providerModalForm.invalid"
          >Update</button>
    
        <button mat-flat-button color="primary" (click)="CreateProvider(pharmacyConfiguration)"
        *ngIf="receivedData.headerTitle=='Add New Pharmacy Provider Configuration'"
         style="background-color: #F59120;width: 
            80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
          [disabled]="providerModalForm.invalid"
          >Create</button>

          <!-- <button mat-flat-button color="warn" (click)="DeleteProviderDetails()"
          *ngIf="receivedData.headerTitle=='Update Provider'"
              style="background-color: #6C747E;width: 
              80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;text-align: center;"
             >Delete</button> -->
    
    
      </mat-dialog-actions>
</div>