import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, map, of } from "rxjs";
import { constant } from "../constant/constant";
import { ErrorHandlerService } from "./error-handler.service";
@Injectable({
  providedIn: 'root'
})

export class StoreService  {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

 
  GetStoreAndAdmin(data: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE_AND_ADMIN), data, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }



  SearchStore(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE_ON_SEARCH), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetStoreByNPI(store: any) {


    let storeData = {
      NPINo: store
    }
    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE__BY_NPI), storeData, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Insert(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.INSERT_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  Update(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.UPDATE_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Delete(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.DELETE_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  InsertEPrimeRxStore(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.INSERT_EPRIMERX_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  checkNPINoExists(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.CHECK_NPINO_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  CheckShortNameExists(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.CHECK_SHORTNAME_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetStoreByOrganization(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.GET_STORE_BY_ORGANIZATION), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  RollBackStore(store: any) {


    return this.httpClient.post<any>((constant.STORECONFIG_STORE_SERVICE_URL + constant.RollBack_STORE), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.logHttpError(error);
        return of(null); // Return an empty observable to complete the stream
      })
    );
  }
}