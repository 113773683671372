import { data } from 'jquery';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs';
import { constant } from 'src/app/constant/constant';
import { User } from 'src/app/models/User';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';
import { UtilityService } from './utility.service';
import { Idle } from '@ng-idle/core';
import { ErrorHandlerService } from './error-handler.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userLoginStatus: any;

  constructor(private httpClient: HttpClient, private router: Router, private utilityService: UtilityService, private tokenStorageService: TokenStorageService,
    private idle: Idle, private errorHandlerService: ErrorHandlerService, private auth: Auth0Service) { }

  getUsers(user: any) {
    return this.httpClient.post<any>(constant.MPOS_USER_SERVICE_URL + constant.getUsersUrl, user, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  refreshToken(refToken: string) {
    const params = new URLSearchParams();
    params.set('refToken', refToken);
    return this.httpClient.post<any>(constant.STORECONFIG_USER_SERVICE_URL + constant.refreshToken, params, constant.httpClientHeader).pipe(
      map((response: any) => {
        const receivedUser: any = JSON.parse(response.data);
        return receivedUser;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.errorHandlerService.handleHttpError(error);
    })
    )

  }
  logOutUserFromTimeOut() {
    //removing the user session and then user will log out 
    sessionStorage.clear();
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin // This is where the user will be redirected after logout
      }
    });
    this.idle.watch();//restart watching the user for idleness
    this.router.navigateByUrl('/login');
    this.utilityService.showAlert("SUCCESS", "Logged Out", "You have been logged out due to inactivity");
  }

  login(user: any) {
    return this.httpClient.post<any>(constant.STORECONFIG_USER_SERVICE_URL + constant.LOGIN_URL, user, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  loginUsingAuth0(user: any) {
    return this.httpClient.post<any>(constant.STORECONFIG_USER_SERVICE_URL + constant.LoginUsingAuth0_URL, user, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }

  // getUsersAccessData(): any {
  //   let NPINo = sessionStorage.getItem(constant.NPINO);
  //   let userName = sessionStorage.getItem(constant.USER_NAME);

  //   var returnUserData = {
  //     NPINo: NPINo,
  //     userName: userName
  //   };

  //   return returnUserData;
  // }

  getUsersAccessData(): any {
    let NPINo = sessionStorage.getItem('NPINo');
    let userName = sessionStorage.getItem('userName');
    let storeName = sessionStorage.getItem('storeName');
    let pseudoName = sessionStorage.getItem('pseudoName');
    let userRights = sessionStorage.getItem('userRights');

    var returnUserData = {
      NPINo: NPINo,
      userName: userName,
      storeName: storeName,
      pseudoName: pseudoName,
      userRights: userRights,
    };
    return returnUserData;
  }

  deleteUsers(user: User) {
    return this.httpClient.post<any>(constant.deleteUserUrl, user, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );

  }
}
