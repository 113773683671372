import { Component, OnInit } from '@angular/core';
import { constant } from 'src/app/constant/constant';
import { stateConstant } from 'src/app/constant/stateConstant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { StoreService } from 'src/app/services/store.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {

  constructor(private storeService: StoreService, private utilityService: UtilityService,) { }

  store: any = {};
  storeAdmin: any = {};
  stateArray: any[] = stateConstant.states;//getting the item type
  ngOnInit(): void {

    this.getStoreAndAdmin()
  }


  getStoreAndAdmin() {


    let storeData = {
      NPINo: sessionStorage.getItem('NPINo')
    }
    let storeAdmin = {
      NPINo: sessionStorage.getItem('NPINo'),
      userName: sessionStorage.getItem('userName')
    }

    let passStoreAndUserData = {
      storeData: storeData,
      storeAdmin: storeAdmin
    }

    this.storeService.GetStoreAndAdmin(passStoreAndUserData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          console.log(response);

          var receivedResponse = JSON.parse(response.data);

          this.store = receivedResponse.store;
          this.storeAdmin = receivedResponse.storeAdmin;

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

}
