import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { error } from 'console';
import { each } from 'jquery';
import { Observable, map, startWith } from 'rxjs';
import { GenSys, constant } from 'src/app/constant/constant';
import { stateConstant } from 'src/app/constant/stateConstant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { OrganizationDeleteRequest } from 'src/app/models/Organization/OrganizationDeleteRequest';
import { OrganizationRequest } from 'src/app/models/Organization/OrganizationRequest';
import { OrganizationUpdateRequest } from 'src/app/models/Organization/OrganizationUpdateRequest';
import { UserAccess } from 'src/app/models/UserAccess';
import { OrganizationService } from 'src/app/services/organization.service';
import { PrimerxCloudPharmacyService } from 'src/app/services/primerx-cloud-pharmacy.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';
import { StoreRequest } from 'src/app/models/RequestModel/StoreRequest';
import { StoreService } from 'src/app/services/store.service';
import { Store } from 'src/app/models/Store';
import { StateMasterService } from 'src/app/services/StateMaster/statemaster.service';

@Component({
  selector: 'app-organization-modal',
  templateUrl: './organization-modal.component.html',
  styleUrls: ['./organization-modal.component.css']
})
export class OrganizationModalComponent implements OnInit {
  @Input() modal_title: any;
  @ViewChild('closebutton') closebutton!: { nativeElement: { click: () => void; }; };

  raw: never[];
  // userAccessData: any;
  stateArray: any[] = stateConstant.states;
  Organization: OrganizationRequest = {
    state: '',
    organizationId: 0
  };
  OrganizationDeleteRequest: OrganizationDeleteRequest = {
    organizationId: 0
  };
  OrganizationUpdateRequest: OrganizationUpdateRequest = {
    organizationId: 0
  };
  userAccessData!: UserAccess;
  cityArray: any[] = [];

  //#region DROPDOWN STATE CITY
  stateCtrl: FormControl;
  filteredState: Observable<any[]>;
  cityCtrl: FormControl;
  filteredCity: Observable<any[]>;
  //#endregion

  orgStore:Store;

  constructor(
    @Inject(MAT_DIALOG_DATA) public receivedData: any,
    private organizationService: OrganizationService,
    private utilityService: UtilityService,
    private dialogService: MatDialogRef<OrganizationModalComponent>,
    private userService: UserService,
    private primerxCloudPharmacyService: PrimerxCloudPharmacyService,
    private stateMasterService: StateMasterService, //EPPOS-3755
    private storeService: StoreService,
  ) {
    if (receivedData != undefined && receivedData.organizationData != undefined) {
      this.Organization = receivedData.organizationData;
    }

    this.userAccessData = this.userService.getUsersAccessData();
  }

  storeRequest: StoreRequest = new StoreRequest();

  ngOnInit(): void {
    // this.userAccessData = this.userService.getUsersAccessData();
    this.IntializeStateCtrl();
    this.IntializeCityCtrl();

    if (this.Organization != undefined && this.Organization != null && this.Organization.state != undefined && this.Organization.state != "")
      this.loadCityForCurrentState(this.Organization.state);
  }

  //#region EPPOS-3755
  // Method to fetch state and city based on ZIP code
  fetchStateAndCity(): void {
    if (this.Organization.zip && this.Organization.zip.length != 1) {
      this.stateMasterService.searchStateCityByZip(this.Organization.zip).subscribe({
        next: (response) => {
          if (response.result === ResponseStatus.SUCCESS) {
            const data = response.data;

            // Find state in stateArray and set it in the state control
            const state = this.stateArray.find(s => s.abbreviation === data.state.name);
            if (state) {
              this.stateCtrl.setValue(state.stateName);
              this.Organization.state = state.abbreviation;
              this.loadCityForCurrentState(state.abbreviation);
            }

            // Set city control and organization city from response
            this.cityCtrl.setValue(data.city.name || '');
            this.Organization.city = data.city.name || '';

          } else {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
          }
        },
        error: (error) => {
          console.error('Error fetching state and city:', error);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, 'Failed to fetch state and city data');
        }
      });
    }
  }
  //#endregion

  deleteStore(): Observable<boolean> {
    // Create an Observable to handle the asynchronous nature of the HTTP request
    return new Observable<boolean>((observer) => {
      let store = {
        organizationId: this.Organization.organizationId
      };
      this.storeService.GetStoreByOrganization(store).subscribe({
        next: (response) => {
          if (response.result === ResponseStatus.SUCCESS) {
            let responseData = JSON.parse(response.data);
            this.orgStore = responseData;

            // Check if orgStore is undefined (no pharmacy associated)
            const noPharmacyAssociated = this.orgStore != undefined;
            observer.next(noPharmacyAssociated);
            observer.complete();
          } else if (
            response.result === ResponseStatus.FAILURE ||
            response.result === ResponseStatus.ERROR
          ) {
            this.utilityService.showAlert(
              constant.KEY_ERROR,
              constant.FAILURE,
              response.message
            );
            // Notify observer that an error occurred
            observer.error(response.message);
          }
        },
        error: (error) => {
          // Handle HTTP request error
          console.error(error);
          // Notify observer that an error occurred
          observer.error(error);
        }
      });
    });
  }


  InsertOrganization(organization: any) {
    organization.city = this.cityCtrl.value;
    //organization.state = this.stateCtrl.value;

    const matchingState = this.stateArray.find(eachState => eachState.abbreviation === organization.state);
    if (!matchingState) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid state");
      return;
    }

    const matchingCity = this.cityArray.find(eachCity => eachCity.name === organization.city);
    if (!matchingCity) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid city");
      return;
    }

    if (organization.state == undefined || organization.state == "") {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select state");
      return;
    } if (organization.city == undefined || organization.city == "") {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select city");
      return;
    } else {
      organization.createdBy = this.userAccessData.userName;
      organization.createdDate = new Date();

      this.organizationService.Insert(organization).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {
            this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "Organization created successfully").subscribe(result => {
              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.close(true);
            });
          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    }
  }

  UpdateOrganization(organization: any) {
    organization.city = this.cityCtrl.value;
    //organization.state = this.stateCtrl.value;

    const matchingState = this.stateArray.find(eachState => eachState.abbreviation === organization.state);
    if (!matchingState) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid state");
      return;
    }

    const matchingCity = this.cityArray.find(eachCity => eachCity.name === organization.city);
    if (!matchingCity) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select a valid city");
      return;
    }

    if (organization.state == undefined || organization.state == "") {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select state");
      return;
    } if (organization.city == undefined || organization.city == "") {
      this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Please select city");
      return;
    } else {
      organization.updatedBy = this.userAccessData.userName;
      this.organizationService.Update(organization).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {
            this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "Organization updated successfully").subscribe(result => {
              //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
              this.dialogService.close(true);
            });
          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });
    }
  }

  // DeleteOrganization(organization: any) {
  //   let passData = {
  //     promptTitle: "Are you sure?",
  //     promptMessage: "You won't be able to revert this!"
  //   };

  //   this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
  //     if (result === true) {
  //       if (!this.deleteStore()) {
  //         this.utilityService.showAlert(
  //           constant.KEY_WARNING,
  //           constant.ALERT_WARNING_TITLE,
  //           "You cannot delete this organization because it has a pharmacy associated with it."
  //         )

  //       }
  //       else
  //       {
  //         this.OrganizationDeleteRequest.organizationId = organization.organizationId;
  //         this.organizationService.Delete(this.OrganizationDeleteRequest).subscribe({
  //           next: (response) => {
  //             if (response.result === ResponseStatus.SUCCESS) {
  //               this.utilityService.showAlert(
  //                 constant.KEY_SUCCESS,
  //                 constant.ALERT_DELETE_TITLE,
  //                 "Organization deleted successfully"
  //               ).subscribe(() => {
  //                 // Close the dialog and pass true as the result
  //                 this.dialogService.close(true);
  //               });
  //             } else if (response.result === ResponseStatus.FAILURE || response.result === ResponseStatus.ERROR) {
  //               this.utilityService.showAlert(
  //                 constant.KEY_ERROR,
  //                 constant.FAILURE,
  //                 response.message
  //               );
  //             }
  //           },
  //           error: (errorResponse) => {
  //             console.log(errorResponse);
  //             this.utilityService.showAlert(
  //               constant.FAILURE,
  //               constant.ALERT_FAILED_TITLE,
  //               errorResponse
  //             );
  //           }
  //         });
  //       }


  //     }
  //   });
  // }
  DeleteOrganization(organization: any) {
    let passData = {
      promptTitle: "Are you sure?",
      promptMessage: "You won't be able to revert this!"
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result === true) {
        // Call deleteStore() method to check if a pharmacy is associated with the organization
        this.deleteStore().subscribe(hasPharmacyAssociated => {
          if (hasPharmacyAssociated) {
            // Show warning message if a pharmacy is associated
            this.utilityService.showAlert(
              constant.KEY_WARNING,
              constant.ALERT_WARNING_TITLE,
              "You cannot delete this organization because it has a pharmacy associated with it."
            );
          } else {
            // Proceed with organization deletion if no pharmacy is associated
            this.OrganizationDeleteRequest.organizationId = organization.organizationId;
            this.organizationService.Delete(this.OrganizationDeleteRequest).subscribe({
              next: (response) => {
                if (response.result === ResponseStatus.SUCCESS) {
                  this.utilityService.showAlert(
                    constant.KEY_SUCCESS,
                    constant.ALERT_DELETE_TITLE,
                    "Organization deleted successfully"
                  ).subscribe(() => {
                    // Close the dialog and pass true as the result
                    this.dialogService.close(true);
                  });
                } else if (response.result === ResponseStatus.FAILURE || response.result === ResponseStatus.ERROR) {
                  this.utilityService.showAlert(
                    constant.KEY_ERROR,
                    constant.FAILURE,
                    response.message
                  );
                }
              },
              error: (errorResponse) => {
                console.log(errorResponse);
                this.utilityService.showAlert(
                  constant.FAILURE,
                  constant.ALERT_FAILED_TITLE,
                  errorResponse
                );
              }
            });
          }
        });
      } else if (result.isDenied) {

      }
    });
  }



  AllowOnlyNumber(event: any) {
    return this.utilityService.AllowOnlyNumber(event);
  }

  omit_special_char(val: any) {
    var k;
    document.all ? (k = val.keyCode) : (k = val.which);
    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57);
  }
  AllowAlphaNumericCharSpace(event: any) {

    this.utilityService.AllowAlphaNumericCharWithSpace(event);
  }

  //#region DROPDOWN STATE CITY

  IntializeStateCtrl() {

    if (this.Organization && this.Organization.state) {
      const stateCode = this.stateArray.find(eachState => eachState.abbreviation == this.Organization.state)
      this.stateCtrl = new FormControl(stateCode.stateName);
    } else {

      this.stateCtrl = new FormControl();
    }

    if (!this.stateCtrl.value) {
      this.stateCtrl.setValue("");
    }

    this.filteredState = this.stateCtrl.valueChanges.pipe(
      startWith(this.stateCtrl.value),
      map((state) =>
        state ? this.filterState(state) : this.stateArray.slice()
      )
    );
  }
  IntializeCityCtrl() {

    if (this.Organization && this.Organization.city) {
      this.cityCtrl = new FormControl(this.Organization.city);

    } else {

      this.cityCtrl = new FormControl();
    }

    if (!this.cityCtrl.value) {
      this.cityCtrl.setValue("");
    }

    this.filteredCity = this.cityCtrl.valueChanges.pipe(
      startWith(this.cityCtrl.value),
      map((city) =>
        city ? this.filterCity(city) : this.cityArray.slice()
      )
    );
  }



  filterState(name: string) {
    let arr = this.stateArray.filter(
      (state) => state.stateName.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ stateName: 'No Matching State Found', abbreviation: 'null' }];
  }

  filterCity(name: string) {
    let arr = this.cityArray.filter(
      (city) => city.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ name: 'No Matching City Found', id: 0 }];
  }


  ClearState() {
    this.stateCtrl.setValue("");
    this.Organization.state = this.stateCtrl.value;
    this.ClearCity();
  }

  ClearNotMatchingState() {

    const stateName = this.stateCtrl.value;
    const selctedState = this.stateArray.find(eachState => eachState.stateName == stateName);
    if (selctedState == undefined || selctedState == "" || selctedState == null) {
      this.stateCtrl.setValue("");
      this.Organization.state = this.stateCtrl.value;
    }
  }

  ClearCity() {
    this.cityCtrl.setValue("");
    this.Organization.city = this.cityCtrl.value;
  }

  ClearNotMatchingCity() {

    const cityName = this.cityCtrl.value;
    const selctedCity = this.cityArray.find(eachCity => eachCity.name == cityName);
    if (selctedCity == undefined || selctedCity == "" || selctedCity == null) {
      this.cityCtrl.setValue("");
      this.Organization.city = this.cityCtrl.value;
    }
  }

  loadCityForCurrentState(stateCode: string) {

    const stateName = this.stateCtrl.value;
    const selctedState = this.stateArray.find(eachState => eachState.stateName == stateName);
    const cityRequest = {
      stateCode: selctedState.abbreviation
    }

    //updating the state to org
    this.Organization.state = selctedState.abbreviation;

    this.primerxCloudPharmacyService.loadCityForCurrentState(cityRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.cityArray = response.data;

          this.IntializeCityCtrl();

          if (this.cityArray.length < 1) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'No City found');
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  OnCitySelection() {

    this.Organization.city = this.cityCtrl.value;
  }
  //#endregion





}
