import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnDestroy,OnInit {
  shouldRun: boolean=true;
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  isHovered: boolean = false;
  isExpanded = false;
  show = true;
  selectedItem: any;
  @Input() sidenavOpened: boolean;
  
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngOnInit(): void {
    this.menuItems.setAccessLinks();
    // if (sessionStorage.getItem("roleType")=="Configurator"){
    //   this.selectedItem={ state: 'organization', type: 'link', name: 'Organizations', icon: 'business', imageUrl: 'assets/images/icons/icon-organization.png', expanded: false, isHovered:false,childMenu: [] };
    // }
    // else if(sessionStorage.getItem("roleType")=="Administrator"){
    //   this.selectedItem="storeAdmin";
    // }
  }

  toggleSidenav(event: Event): void {
    event.stopPropagation();  // Prevent the parent click event from firing
    this.sidenavOpened = !this.sidenavOpened;
  }

  onMenuItemClick(item: any) {
    this.selectedItem = item;
  }

  
}
