import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, map } from "rxjs";
import { constant } from "../constant/constant";
import { ErrorHandlerService } from "./error-handler.service";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: 'root'
})
export class PayTypeMasterService {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  ngOnInit(): void {
  }

  GetPayTypeMasterAsync() {
    return this.httpClient.get<any>((constant.GETMASTERPAYTYPE), constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetPayTypeMasterAsyncByNPI(data: any) {
    return this.httpClient.post<any>((constant.GETMASTERPAYTYPEBYNPI), data, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  InsertPayTypeMasterAsyncByNPI(data: any) {
    return this.httpClient.post<any>((constant.INSERTMASTERPAYTYPE), data, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  // InsertManyPayTypeMasterAsyncByNPI(data: any) {
  //   return this.httpClient.post<any>((constant.INSERTMANYMASTERPAYTYPE), data, constant.httpClientHeader).pipe(
  //     catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
  //   );
  // }

  InsertManyPayTypeMasterAsyncByNPI(data: any) {

    return this.httpClient.post<ApiResponse<any>>((constant.INSERTMANYMASTERPAYTYPE), data, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  UpdatePayTypeMasterAsyncByNPI(data: any) {
    return this.httpClient.put<any>((constant.UPDATEMASTERPAYTYPEBYNPI), data, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  DeletePayTypeMasterAsyncByNPI(npi: string){
    return this.httpClient.post<any>((constant.DELETEMASTERPAYTYPEBYNPI), npi, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

}
