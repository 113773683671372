export interface ApiResponse<T> {
    result: ResponseStatus;
    message: string;
    timeStamp: Date;
    data: T;
  }
  
  export enum ResponseStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    ERROR = 'ERROR'
  }
  