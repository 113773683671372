import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { catchError, map } from "rxjs";
import { constant } from "../constant/constant";
import { ErrorHandlerService } from "./error-handler.service";
@Injectable({
  providedIn: 'root'
})

export class StoreAdminService implements OnInit {

  constructor(private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }

  GetStoreAdminOnLoad() {

    return this.httpClient.get<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_STORES_ADMIN_ON_LOAD), constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  GetStoreAdminOnLoadPagination(pageData: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_ALL_STORES_ADMIN_ON_LOAD), pageData, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  SearchStoreAdmin(store: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_STORE_ADMIN_ON_SEARCH), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }
  GetStoreAdminByNPI(store: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.GET_STORE_ADMIN_BY_NPI), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }

  Insert(store: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.INSERT_STORE_ADMIN), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  Update(store: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.UPDATE_STORE_ADMIN), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


  Delete(store: any) {

    return this.httpClient.post<any>((constant.STORECONFIG_USER_SERVICE_URL + constant.DELETE_STORE_ADMIN), store, constant.httpClientHeader).pipe(
      catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
    );
  }


}