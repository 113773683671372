<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">

        <div fxFlex="auto">
            <app-page-header [icon]="'assets/images/icons/icon-organization-blue.png'" [title]="'Organizations'"
                [breadcrumb]="''">
            </app-page-header>
        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;"></div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;"></div>
        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="end center" class="search-container"
            style="text-align: right;">
            <fieldset>
                <input type="text" name="organizationName" #organizationName="ngModel" id="organizationName"
                    [(ngModel)]="organizationSearch.Name" style="width: 300px;" placeholder="Organization Name" />
                <button class="search-button" type="button" id="btnOpenCreateOrganizationModal"
                    style="width: 80px; height: 40px;" (click)="getOrganizations()">
                    <mat-icon>search</mat-icon>
                </button>
            </fieldset>
        </div>

        <div fxFlex="auto" fxLayoutAlign="end center">
            <button class="custom-orange-button" type="button" id="btnOpenCreateOrganizationModal"
                style="width: 100px; height: 45px;" (click)="OpenOrganizationCreateModal()">
                Create New
            </button>
        </div>

    </div>
</div>


<!-- card for Table-->

<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
            <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                <div class="table-container" style="width: 100%;">
                    <!-- <table mat-table [dataSource]="dataSource" 
                             multiTemplateDataRows matSort >

                             <ng-container matColumnDef="OrganizationId">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Organization Id</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.organizationId}}
                                </td>
                            </ng-container>
                    </table> -->
                    <mat-table matTableExporter matSort [dataSource]="dataSource" #exporter="matTableExporter">

                        <ng-container color="primary" matColumnDef="organizationId">
                            <mat-header-cell  *matHeaderCellDef mat-sort-header> Organization Id </mat-header-cell>
                            <mat-cell color="primary" *matCellDef="let element"> {{element.organizationId}}
                            </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="contactPerson">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> Contact Person </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.contactPerson}} </mat-cell>
                        </ng-container>
            
                        <ng-container matColumnDef="city">
                            <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
                            <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="Action">
                            <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <app-table-edit-button [imageSrc]="''" [tooltip]="'Update Organization'"
                                    [color]="'primary'" (buttonClick)="OpenOrganizationUpdateModal(element)">
                                </app-table-edit-button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-row"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;"
                            [ngClass]="{hovered: row.hovered, highlighted: selection.isSelected(row)}"
                            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></mat-row>

                    </mat-table>
                </div>
                <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                </mat-paginator>
            </div>
        </mat-card>
    </div>
</div>