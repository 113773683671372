import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Console } from 'console';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }


  handleHttpError(error: HttpErrorResponse): Observable<never> {
    let message = "";
    console.log("Error in Json Format" + JSON.stringify(error));

    if (error?.error instanceof ErrorEvent) {
      console.log('An error occurred on client :', error?.error?.message);
      message = error?.error?.message;
    } else {

      console.log(`Backend returned code ${error?.status}`);
      console.log(`Backend server message ${error?.error?.message}`);

      if (error?.error?.data) {
        console.log(`Backend server exception message ${error?.error?.data?.message}`);
      }

      message = error?.error?.message != undefined ? error?.error?.message : error?.message;
    }

    return throwError(() => message);
  }

  // handleGeneralError(error: any): Observable<never> {
  //   console.error('An unexpected error occurred:', error);

  //   return throwError(() => new Error('Something bad happened; please try again later.'));
  // }

  logHttpError(error: HttpErrorResponse) {
    let message = "";
    console.log("Error in Json Format" + JSON.stringify(error));

    if (error?.error instanceof ErrorEvent) {
      console.log('An error occurred on client :', error?.error?.message);
      message = error?.error?.message;
    } else {

      console.log(`Backend returned code ${error?.status}`);
      console.log(`Backend server message ${error?.error?.message}`);

      if (error?.error?.data) {
        console.log(`Backend server exception message ${error?.error?.data?.message}`);
      }

      message = error?.error?.message != undefined ? error?.error?.message : error?.message;
    }

  }
}
