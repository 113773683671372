import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/models/User';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { Vendor } from 'src/app/models/Vendor';
import { VendorConnection } from 'src/app/models/VendorConnection';
import { VendorFields } from 'src/app/models/VendorFields';
import { UserService } from 'src/app/services/user.service';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { VendorInformationComponent } from '../vendorComponents/vendor-information/vendor-information.component';
import { VendorMapDialogComponent } from './vendor-map-dialog/vendor-map-dialog.component';
import { ContractModalComponent } from './vendorContract/contract-modal.component';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Store } from 'src/app/models/Store';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { ediconstant } from 'src/app/constant/ediconstant';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs';
import { PharmacyCreateRequest } from 'src/app/models/Pharmacy/PrimeRxCloudPharmacyCreateRequest';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-vendor-mapping',
  templateUrl: './vendor-mapping.component.html',
  styleUrls: ['./vendor-mapping.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VendorMappingComponent implements OnInit {

  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['Expand/Collapse', 'NPINo', 'PharmacyName', 'vendorCode', 'vendorName', 'address1', 'city', 'state', 'zip', 'isActive', 'Action']; //EPPOS-3766
  innerDisplayedColumns = ['contractName', 'contractUsername', 'contractPassword', 'contractIsaInterchangeSenderId', 'contractAppSenderCode'
    , 'contractIdentificationCodeBy', 'contractOutboundDirName', 'contractInboundDirName', 'contractPriceFileFormat', 'contractAckFileFormat', 'contractFileFormat810', 'Edit'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('outerSort', { static: true }) outersort!: MatSort;
  @ViewChildren('innerSort') innerSort!: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables!: QueryList<MatTable<ContractDetail>>;
  //  sort: MatSort | undefined;
  // dataSource: MatTableDataSource<Vendor> = new MatTableDataSource();
  //=========variable Declaration============
  hideOldPwd: boolean = true;
  VendorContractDataSource!: MatTableDataSource<VendorWithContract>;
  dbVendorArray: VendorWithContract[] = [];
  vendorDetails: any[] = [];
  expandedElement!: VendorWithContract | null;
  showNested: boolean = false;
  resultData: any;
  dbVendorConnection!: VendorConnection;
  dbVendorFields!: VendorFields;
  dbVendorInfo!: Vendor;
  userAccessData!: EdiUserAccess;
  vendorArray: VendorWithContract[] = [];
  passVendorData: any = {}
  selectedNPINo: string = "";
  storeNpiList: Store[] = [];

  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;

  pharmacyCreateRequest: PharmacyCreateRequest = {
    adminFirstName: '',
    adminLastName: '',
    pharmacyEmail: '',
    pharmacyPhoneNumber: '',
    contactPerson: '',
    pharmacyFaxNumber: '',
    rxGenSys: 0, // Assuming rxGenSys is a number property, initialize with appropriate default value
    pharmacyAddressLine1: '',
    pharmacyAddressLine2: '', // If you want to set it to null, remove this line
    masterDbName: '',
    pharmacyNpiNumber: '',
    pharmacyZipCode: '',
    pharmacyNABPNum: 0, // Assuming pharmacyNABPNum is a number property, initialize with appropriate default value
    PharmacyStateCode: "", // Assuming PharmacyStateCode is a number property, initialize with appropriate default value
    pharmacyCityId: 0, // Assuming pharmacyCityId is a number property, initialize with appropriate default value
    pharmacyRegistrationNum: '',
    pharmacyDEARegistration: '',
    pharmacyName: '',
    organizationId: 0,
    adminUserName: '',
    adminPassword: '',
    isPrimeRxCloudEmptyPharmacy:false
  };

  store: Store = {
    mId: '',
    NPINo: '',
    pseudoName: '',
    storeName: '',
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    state: '',
    owner: '',
    contactNo: '',
    contactName: '',
    website: '',
    fax: '',
    selectedPayType: '',
    isActive: false,
    terminal: '',
    isEdiv2: false,
    isMpos: false,
    isStoreAdminCreated: false,
    createdBy: '',
    updatedBy: '',
    createdDate: '',
    updatedDate: '',
    email: '',
    isPrimeRxCloud: false,
    primeRxCloudData: this.pharmacyCreateRequest,
    isInfoLog: false, // #3787
    allowDefaultItems: false //#3788
  }

  vendor: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810: '',
    processSwitch832: '',
    processSwitch835: '',
    processSwitch855: ''

  };
  contract: any = {
    contractName: '',
    contractUsername: '',
    contractPassword: '',
    contractIsaInterchangeSenderId: '',
    contractAppSenderCode: '',
    contractIdentificationCodeBy: '',
    contractOutboundDirName: '',
    contractInboundDirName: '',
    contractPriceFileFormat: '',
    contractAckFileFormat: '',
    contractFileFormat810: '',
  };
  vendorWithContract: VendorWithContracts = {
    vendor: this.vendor,
    contractDetail: this.contract,
  };

  existingMappedVendor: Vendor[] = [];
  vendorMasterArray: Vendor[] = [];
  filteredVendorOptions: Observable<Vendor[]> | undefined;
  vendorNameControl = new FormControl('');
  storeArray!: Store[];

  constructor(public dialogService: MatDialog, private changeDetector: ChangeDetectorRef, private ediStoreService: EDIStoreService, private vendorService: VendorService, private utilityService: EdiUtilityService, private tokenStorageService: TokenStorageService) {

    //this.userAccessData = userService.getUsersAccessData();
    const user = this.tokenStorageService.getUser();

    if (!this.userAccessData) {
      this.userAccessData = {} as any;
    }

    this.userAccessData.NPINo = user.NPINo;
    this.userAccessData.pseudoName = user.pseudoName;


    if (this.userAccessData.NPINo && this.userAccessData.NPINo != "0000000000")
      this.vendor.NPINo = this.userAccessData.NPINo;

    this.vendor.pseudoName = this.userAccessData.pseudoName;
  }

  ngOnInit(): void {
    //for loading the vendors on page  load
    this.getVendors();
    this.loadStoreNpi() // for loading NPI list in drodown
    this.loadVendorsByNPI();


    if (this.userAccessData.NPINo != "" && this.userAccessData.NPINo == "0000000000") {
      //this.isMMSAdmin = true;
      this.loadAllNPINo();
    }
    this.loadMasterVendors();


  }


  loadAllNPINo() {

    this.ediStoreService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.storeArray = receivedVendorResponse;
          this.setValueToNPIDropdown();

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }


  setValueToNPIDropdown() {


    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }
  // onSearchClear() {
  //   this.searchKey = "";
  //   this.applyFilter();
  // }



  // applyFilter() {
  //   this.VendorContractDataSource.filter = this.searchKey.trim().toLowerCase();

  //   if (this.paginator) {
  //     this.paginator.pageIndex = this.currentPage;
  //     this.paginator.length = this.resultData.totalRecords;
  //   }
  // }

  //   applyFilter() {
  //     console.log('Search Key:', this.searchKey);
  //     const filterValue = this.searchKey.trim().toLowerCase();
  //     console.log('Filter Value:', filterValue);

  //     this.VendorContractDataSource.filter = filterValue;

  //     if (this.paginator) {
  //         this.paginator.firstPage();
  //     }
  // }

  applyFilter(filterValue: any) {
    filterValue = filterValue.value.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.VendorContractDataSource.filter = filterValue;
  }






  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    // this.getMatUser();
  }

  openMappingDialog() {

    const mapDialogRef = this.dialogService.open(VendorMapDialogComponent, {
      width: '60%',
      disableClose: true,
      data: {
        isUpdate: false,
        isCreate: true
      }
    });

    mapDialogRef.afterClosed().subscribe(result => {
      //NOT USING THE RESULT BECAUSE IF THE CLICKS ON BACKGROUND THEN RESULT IS UNDEFINED
      //for loading the vendors on page  load
      if (result == true) {
        this.getVendors();
        this.loadStoreNpi();
        this.loadVendorsByNPI();
      }
    });
  }

  openVendorMappingUpdateDialog(receivedVendor: Vendor) {

    this.vendorService.getVendorConnectionAndField(receivedVendor).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.dbVendorConnection = receivedVendorResponse.vendorConnection;
          this.dbVendorFields = receivedVendorResponse.vendorFields;

          //OPENING THE MODAL AFTER GETTING THE REQUIRED DATA FROM DB
          const mapDialogRef = this.dialogService.open(VendorMapDialogComponent, {
            width: '60%',
            disableClose: true,
            data: {
              isUpdate: true,
              isCreate: false,
              receivedVendorInfo: receivedVendor,
              dbVendorFields: this.dbVendorFields,
              dbVendorConnection: this.dbVendorConnection,
            }
          });

          mapDialogRef.afterClosed().subscribe(result => {

            //for loading the vendors on page  load
            if (result == true) {
              this.getVendors();
              this.loadStoreNpi();
              this.loadVendorsByNPI();
            }
          });

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  //==========getting the vendor ===============
  getVendors() {
    if (this.vendor.NPINo == 'All') this.vendor.NPINo = ''
    if (this.vendor.vendorCode == 'All') this.vendor.vendorCode = '';
    this.vendorService.getVendorWithContractData(this.vendor).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.vendorDetails = JSON.parse(response.data);

          // if (this.vendorDetails.length > 0)
          this.mapModelForContractTable();

          if (this.vendorDetails.length == 0)
            this.utilityService.showAlert(ediconstant.KEY_WARNING, ediconstant.ALERT_WARNING_TITLE, "No data found");
          // this.VendorContractDataSource = new MatTableDataSource<VendorWithContract>(this.vendorDetails);
          // this.VendorContractDataSource.paginator = this.paginator!;
          // this.VendorContractDataSource.sort = this.sort!;
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  mapModelForContractTable() {
    this.dbVendorArray = [];
    if (this.vendorDetails.length > 0) {
      this.vendorDetails.forEach(eachContract => {
        if (eachContract.contractDetail && Array.isArray(eachContract.contractDetail)) {
          this.dbVendorArray = [...this.dbVendorArray, { ...eachContract, contractDetail: new MatTableDataSource(eachContract.contractDetail) }];
        } else {
          delete eachContract.contractDetail;
          this.dbVendorArray = [...this.dbVendorArray, eachContract];
        }
      });
    }


    this.VendorContractDataSource = new MatTableDataSource(this.dbVendorArray);
    this.VendorContractDataSource.paginator = this.paginator!;
    this.VendorContractDataSource.sort = this.outersort;

  }

  loadVendorsByNPI() {

    if (this.userAccessData.NPINo && this.userAccessData.NPINo != "0000000000") {

      this.passVendorData = {
        NPINo: this.userAccessData.NPINo
      };
    } else {

      //HERE PASSING THE BLANK NPI TO GET THE LIST OF ALL THE VENDORS
      this.passVendorData = {
        NPINo: ''
      };
    }


    this.vendorService.getVendorWithContractData(this.passVendorData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.vendorDetails = JSON.parse(response.data);

          if (this.vendorDetails.length > 0)
            this.mapModelForContractTable();
          // this.VendorContractDataSource = new MatTableDataSource<VendorWithContract>(this.vendorDetails);
          // this.VendorContractDataSource.paginator = this.paginator!;
          // this.VendorContractDataSource.sort = this.sort!;

          this.vendorArray = this.vendorDetails;
          let distinctVendor = [
            ...new Map(this.vendorArray.map((item) => [item.vendor["vendorCode"], item])).values(),
          ];

          this.vendorArray = distinctVendor;
          let Vendor: Vendor = {
            mId: '',
            NPINo: '',
            pseudoName: '',
            vendorId: '',
            vendorCode: 'All',
            vendorName: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            phoneOff: '',
            cellNo: '',
            faxNo: '',
            webAddress: '',
            emailAddress: '',
            isActive: true,
            POExpiresInDays: '',
            overdueTime: '',
            process810: false,
            isDelete: false,
            priceQualifier: '',
            costQualifier: '',
            saleQualifier: '',
            isEPO: false,
            salePriceUpdate: false,
            vendorCostPrice: false,
            autoClose: false,
            updatePrice832: false,
            ackPriceUpdate: false,
            reduceSellingPriceWith832File: false,
            processSwitch810: '',
            processSwitch832: '',
            processSwitch835: '',
            processSwitch855: ''
          };
          let contractDetail: ContractDetail = {
            contractName: '',
            contractUsername: '',
            contractPassword: '',
            contractIsaInterchangeSenderId: '',
            contractAppSenderCode: '',
            contractIdentificationCodeBy: '',
            contractOutboundDirName: '',
            contractInboundDirName: '',
            contractPriceFileFormat: '',
            contractAckFileFormat: '',
            contractFileFormat810: '',
            NPINO: '',
            vendorCode: '',
            contractURL: '',
            contractConnectionType: '',
            contractPort: '',
            contractInboundDir: '',
            contractOutboundDir: '',
            isEdit: false
          }

          // let expandedElement: VendorWithContract | null;
          // expandedElement.vendor=Vendor

          let allVendor: VendorWithContract =
          {
            vendor: Vendor,
            contractDetail: undefined
          }

          if (this.vendorArray.length > 0) {
            this.vendorArray.unshift(allVendor);
            this.vendor.vendorCode = 'All';
          }

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching vendor data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  toggleRow(element: VendorWithContract) {
    element.contractDetail && (element.contractDetail as MatTableDataSource<ContractDetail>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    this.changeDetector.detectChanges();
    this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<ContractDetail>).sort = this.innerSort.toArray()[index]);
  }
  onEdit(item: any) {
    item.isEdit = true;
  }
  // onUpdate(item: any) {
  //   item.isEdit = false;
  // }
  onCancel(item: any) {
    item.isEdit = false;
  }
  AddContract(NPI: string, vendorCode: string) {
    const contractModalRef = this.dialogService.open(ContractModalComponent, {
      width: '40%',
      disableClose: true,
      data: { NPI: NPI, vendorCode: vendorCode }
    });

    contractModalRef.afterClosed().subscribe(result => {
      //getting tax on load for showing in the table after creating the user
      if (result == true) {
        this.getVendors();
      }
    });

  }

  onUpdate(element: any) {
    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.vendor.NPINo = element.NPINO;
    this.vendorWithContract.vendor.vendorCode = element.vendorCode;
    // this.vendorWithContract.contractDetail!.NPINO = element.NPINO;
    // this.vendorWithContract.contractDetail!.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.contractName = element.contractName;
    this.vendorWithContract.contractDetail!.contractUsername = element.contractUsername;
    this.vendorWithContract.contractDetail!.contractPassword = element.contractPassword;
    this.vendorWithContract.contractDetail!.contractIsaInterchangeSenderId = element.contractIsaInterchangeSenderId;
    this.vendorWithContract.contractDetail!.contractAppSenderCode = element.contractAppSenderCode;
    this.vendorWithContract.contractDetail!.contractIdentificationCodeBy = element.contractIdentificationCodeBy;
    this.vendorWithContract.contractDetail!.contractOutboundDirName = element.contractOutboundDirName;
    this.vendorWithContract.contractDetail!.contractInboundDirName = element.contractInboundDirName;
    this.vendorWithContract.contractDetail!.contractPriceFileFormat = element.contractPriceFileFormat;
    this.vendorWithContract.contractDetail!.contractAckFileFormat = element.contractAckFileFormat;
    this.vendorWithContract.contractDetail!.contractFileFormat810 = element.contractFileFormat810;

    this.vendorService.saveVendorWithContractData(this.vendorWithContract).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "Account updated successfully").subscribe((result: any) => {
            element.isEdit = false;
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while updating the account");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  onDelete(element: any) {
    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.vendor.NPINo = element.NPINO;
    this.vendorWithContract.vendor.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.NPINO = element.NPINO;
    this.vendorWithContract.contractDetail!.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.contractName = element.contractName;

    this.vendorService.deleteVendorContract(this.vendorWithContract).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_DELETE_TITLE, "Account deleted successfully").subscribe((result: any) => {
            element.isEdit = false;
            this.loadVendorsByNPI();
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
          });

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while deleting the account");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  testVendorFtpSftpConnection(element: any) {

    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.vendor.NPINo = element.NPINO;
    this.vendorWithContract.vendor.vendorCode = element.vendorCode;
    this.vendorWithContract.contractDetail!.contractURL = element.contractURL;
    this.vendorWithContract.contractDetail!.contractPort = element.contractPort;
    this.vendorWithContract.contractDetail!.contractConnectionType = element.contractConnectionType;
    this.vendorWithContract.contractDetail!.contractInboundDir = element.contractInboundDir;
    this.vendorWithContract.contractDetail!.contractOutboundDir = element.contractOutboundDir;
    this.vendorWithContract.contractDetail!.contractName = element.contractName;
    this.vendorWithContract.contractDetail!.contractUsername = element.contractUsername;
    this.vendorWithContract.contractDetail!.contractPassword = element.contractPassword;
    this.vendorWithContract.contractDetail!.contractIsaInterchangeSenderId = element.contractIsaInterchangeSenderId;
    this.vendorWithContract.contractDetail!.contractAppSenderCode = element.contractAppSenderCode;
    this.vendorWithContract.contractDetail!.contractIdentificationCodeBy = element.contractIdentificationCodeBy;
    this.vendorWithContract.contractDetail!.contractOutboundDirName = element.contractOutboundDirName;
    this.vendorWithContract.contractDetail!.contractInboundDirName = element.contractInboundDirName;
    this.vendorWithContract.contractDetail!.contractPriceFileFormat = element.contractPriceFileFormat;
    this.vendorWithContract.contractDetail!.contractAckFileFormat = element.contractAckFileFormat;
    this.vendorWithContract.contractDetail!.contractFileFormat810 = element.contractFileFormat810;

    this.vendorService.testVendorFtpConnection(this.vendorWithContract).subscribe({
      next: (response) => {
        var receivedConnectionResponse = JSON.parse(response.data);
        var message = "";
        if (response.result == ResponseStatus.SUCCESS) {

          if (receivedConnectionResponse.isFtpConnected == true) {

            if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == true) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory exists.\n" +
                "Outbound directory exists.\n";
            } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == true) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory does not exist.\n" +
                "Outbound directory exists.\n";
            } else if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == false) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory  exists.\n" +
                "Outbound directory does not exist.\n";
            } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == false) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory does not exist.\n" +
                "Outbound directory does not exist. \n";
            }
          } else if (receivedConnectionResponse.isFtpConnected == false) {

            this.utilityService.showAlert(
              constant.KEY_ERROR,
              constant.ALERT_FAILED_TITLE,
              "FTP connection failed"
            );
          }

          this.utilityService.showAlert(
            constant.KEY_SUCCESS_WITH_NO_RELOAD,
            "Info",
            message
          );
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while testing the connection");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(
          constant.FAILURE,
          constant.ALERT_FAILED_TITLE,
          errorResponse
        );

      }
    });

  }



  loadStoreNpi() {
    this.ediStoreService.getAllNPINo().subscribe(response => {
      if (response.result == ediconstant.KEY_SUCCESS) {
        var receivedVendorResponse = JSON.parse(response.data);
        this.storeNpiList = receivedVendorResponse;

        const pharmacyCreateRequest: PharmacyCreateRequest = {
          adminFirstName: '',
          adminLastName: '',
          pharmacyEmail: '',
          pharmacyPhoneNumber: '',
          contactPerson: '',
          pharmacyFaxNumber: '',
          rxGenSys: 0, // Assuming rxGenSys is a number property, initialize with appropriate default value
          pharmacyAddressLine1: '',
          pharmacyAddressLine2: '', // If you want to set it to null, remove this line
          masterDbName: '',
          pharmacyNpiNumber: '',
          pharmacyZipCode: '',
          pharmacyNABPNum: 0, // Assuming pharmacyNABPNum is a number property, initialize with appropriate default value
          PharmacyStateCode: "", // Assuming PharmacyStateCode is a number property, initialize with appropriate default value
          pharmacyCityId: 0, // Assuming pharmacyCityId is a number property, initialize with appropriate default value
          pharmacyRegistrationNum: '',
          pharmacyDEARegistration: '',
          pharmacyName: '',
          organizationId: 0,
          adminUserName: '',
          adminPassword: '',
          isPrimeRxCloudEmptyPharmacy:false
        };

        const storeAll: Store = {
          mId: '',
          NPINo: 'All',
          pseudoName: '',
          storeName: '',
          address1: '',
          address2: '',
          city: '',
          zipCode: '',
          state: '',
          owner: '',
          contactNo: '',
          contactName: '',
          website: '',
          fax: '',
          selectedPayType: '',
          isActive: false,
          terminal: '',
          isEdiv2: false,
          isMpos: false,
          isStoreAdminCreated: false,
          createdBy: '',
          updatedBy: '',
          createdDate: '',
          updatedDate: '',
          email: '',
          isPrimeRxCloud: false,
          primeRxCloudData: pharmacyCreateRequest,
          isInfoLog: false, // #3787
          allowDefaultItems: false //#3788
        }

        this.storeNpiList.unshift(storeAll);
        this.vendor.NPINo = 'All';
      }
      else {
        this.utilityService.showAlert(
          ediconstant.KEY_ERROR,
          ediconstant.ALERT_FAILED_TITLE,
          "Error ehile fetching store NPI list"
        );
      }
    })
  }

  setNPINoToModel(event: any) {
    this.vendor.NPINo = event.option.value.NPINo;

    this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

    //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
    this.getUnMappedVendors(this.vendor.NPINo);

  }

  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.existingMappedVendor = receivedVendorResponse;

          //now getting the diffrence between the master vendor and mapped  vendor
          this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
            !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

          //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
          this.setValueToVendorDropdown();
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching mapped vendors data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }

  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  loadMasterVendors() {

    let vendorData = {};
    this.vendorService.getMasterVendor(vendorData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.vendorMasterArray = receivedVendorResponse;
          this.vendorMasterArray = this.vendorMasterArray.filter(vendor => vendor.isActive);
          this.getUnMappedVendors(this.userAccessData.NPINo);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  onInput(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (!value.trim()) {
      // Clear the value of this.vendor.NPINo if the input field is empty
      this.vendor.NPINo = '';
      this.selectedNPINo = ''; // Optionally clear other related properties
    }
  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


}



export interface VendorWithContract {
  vendor: Vendor;
  contractDetail?: ContractDetail[] | MatTableDataSource<ContractDetail>;
}
//used just for sending data while saving vendor accounts
export interface VendorWithContracts {
  vendor: Vendor;
  contractDetail?: ContractDetail;
}

export interface ContractDetail {

  contractName: string
  contractUsername: string
  contractPassword: string
  contractIsaInterchangeSenderId: string
  contractAppSenderCode: string
  contractIdentificationCodeBy: string
  contractOutboundDirName: string;
  contractInboundDirName: string;
  contractPriceFileFormat: string
  contractAckFileFormat: string
  contractFileFormat810: string
  NPINO: string
  vendorCode: string
  contractURL: string
  contractConnectionType: string
  contractPort: string
  contractInboundDir: string
  contractOutboundDir: string
  isEdit: false
}

