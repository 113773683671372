import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileDownloadPageGaurd } from '../gaurds/fileDownload.guard';
import { VendorMappingPageGuard } from '../gaurds/vendor-mapping.guard';
import { VendorMasterPageGuard } from '../gaurds/vendor-master.guard';
import { UserComponent } from './ediUser/user.component';
import { FileDownloadComponent } from './fileDownload/file-download.component';
import { FileParsingErrorComponent } from './fileParsingError/file-parsing-error.component';
import { GetFileNowComponent } from './get-file-now/get-file-now.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { VendorMappingComponent } from './vendorMapping/vendor-mapping.component';
import { VendorMasterComponent } from './vendorMaster/vendor-master.component';
import { EdiComponent } from './edi.component';
import { FileParsingErrorGuard } from '../gaurds/file-parsing-error.guard';
import { GetFilesNowGuard } from '../gaurds/get-files-now.guard';
import { SchedulerGuard } from '../gaurds/scheduler.guard';
import { AuthGuard } from '../gaurds/auth.guard';

const routes: Routes = [
  {
    path: '', component: EdiComponent,

    children: [
      { path: '', component: VendorMasterComponent, canActivate: [AuthGuard] },
      { path: 'fileDownload', component: FileDownloadComponent, canActivate: [AuthGuard] },
      { path: 'vendorMapping', component: VendorMappingComponent, canActivate: [AuthGuard] },
      { path: 'vendorMaster', component: VendorMasterComponent, canActivate: [AuthGuard] },
      { path: 'scheduler', component: SchedulerComponent, canActivate: [AuthGuard] },
      { path: 'getFilesNow', component: GetFileNowComponent, canActivate: [AuthGuard] },
      { path: 'fileParsingError', component: FileParsingErrorComponent, canActivate: [AuthGuard] },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EdiRoutingModule { }
