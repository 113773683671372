
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatTableModule } from '@angular/material/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/materialComponents/material.module';
import { EdiRoutingModule } from './edi-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ReusableComponentsModule } from 'src/app/ReusableComponents/reusable-components.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserComponent } from './ediUser/user.component';
import { UserModalComponent } from './ediUserModal/user-modal.component';
import { FileDownloadComponent } from './fileDownload/file-download.component';
import { FileParsingErrorComponent } from './fileParsingError/file-parsing-error.component';
import { GetFileNowComponent } from './get-file-now/get-file-now.component';
import { CronDialogComponent } from './scheduler/cron-dialog/cron-dialog.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { VendorConnectionComponent } from './vendorComponents/vendor-connection/vendor-connection.component';
import { VendorFieldsComponent } from './vendorComponents/vendor-fields/vendor-fields.component';
import { VendorInformationComponent } from './vendorComponents/vendor-information/vendor-information.component';
import { VendorMapDialogComponent } from './vendorMapping/vendor-map-dialog/vendor-map-dialog.component';
import { VendorMappingComponent } from './vendorMapping/vendor-mapping.component';
import { ContractModalComponent } from './vendorMapping/vendorContract/contract-modal.component';
import { VendorMasterDialogComponent } from './vendorMaster/vendor-master-dialog/vendor-master-dialog.component';
import { VendorMasterComponent } from './vendorMaster/vendor-master.component';
import { AppRoutingModule } from '../app-routing.module';
import { RouterModule } from '@angular/router';
import { EdiComponent } from './edi.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    FileDownloadComponent,
    VendorInformationComponent,
    VendorConnectionComponent,
    VendorFieldsComponent,
    VendorMapDialogComponent,
    ContractModalComponent,
    VendorMappingComponent,
    VendorMasterComponent,
    VendorMasterDialogComponent,
    SchedulerComponent,
    CronDialogComponent,
    GetFileNowComponent,
    FileParsingErrorComponent,
    UserComponent,
    UserModalComponent,
    EdiComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([]),
    EdiRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbModule,
    DataTablesModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    MatTableModule,
    MatSelectModule,
    MatDialogModule,
    DataTablesModule,
    MatTableExporterModule,
    MatFormFieldModule,
    MatInputModule,
    MatPasswordStrengthModule,
    ReusableComponentsModule,
    NgxMaskModule.forRoot(maskConfig),
  ],
})
export class EdiModule { }
