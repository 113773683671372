import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { constant } from 'src/app/constant/constant';
//import { EdiUserRequest } from 'src/app/models/RequestModel/EdiUserRequest';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/User';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { UserModalComponent } from '../ediUserModal/user-modal.component';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserRequest } from 'src/app/models/UserRequest';
import { EdiUserService } from 'src/app/services/edi_user.service';
import { Vendor } from 'src/app/models/Vendor';
import { Store } from 'src/app/models/Store';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { ediconstant } from 'src/app/constant/ediconstant';
import { PharmacyCreateRequest } from 'src/app/models/Pharmacy/PrimeRxCloudPharmacyCreateRequest';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { ResponseStatus } from 'src/app/models/ApiResponse';

interface promptModel {
  promptMessage: string;
  promptTitle: string;
}


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})


export class UserComponent implements OnInit {

  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['NPINo', 'userName', 'lName', 'noOfAttempt', 'isLocked', 'isActive', 'Action'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<User> = new MatTableDataSource();

  isMMSAdmin: boolean = false;
  UserAccessMaster: any = {};
  passUserData!: User;
  user: any = {};
  promptData!: promptModel;
  alertData!: any;


  userArray!: User[];
  userRequest: UserRequest = new UserRequest;
  userAccessData!: EdiUserAccess;
  resultData!: any;

  vendor: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810:'',
    processSwitch832:'',
    processSwitch835:'',
    processSwitch855:''

  };

  storeNpiList: Store[] =[];
  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;
  selectedNPINo: string = "";
  existingMappedVendor: Vendor[] = [];
  vendorMasterArray: Vendor[] = [];
  filteredVendorOptions: Observable<Vendor[]> | undefined;
  vendorNameControl = new FormControl('');
  storeArray!: Store[];

  constructor(private userService:EdiUserService, private utilityService: EdiUtilityService, private dialogService: MatDialog, private tokenStorageService:TokenStorageService, private ediStoreService:EDIStoreService, private vendorService: VendorService) {
    const user=this.tokenStorageService.getUser();

    if (!this.userAccessData) {
        this.userAccessData = {} as any;
    }

    this.user.NPINo = user.NPINo;
    this.user.pseudoName = user.pseudoName;
  }

  ngOnInit(): void {

    //getting users on load for showing in the table
    this.getUsersOnLoad();

    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;

    this.loadStoreNpi() // for loading NPI list in drodown

    // if (this.userAccessData.NPINo != "" && this.userAccessData.NPINo == "0000000000") {
    //   this.isMMSAdmin = true;
    //   this.loadAllNPINo();
    // }
    this.loadAllNPINo();
    this.loadMasterVendors();
  }

  onInput(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (!value.trim()) {
        // Clear the value of this.vendor.NPINo if the input field is empty
        this.vendor.NPINo = '';
        this.selectedNPINo = ''; // Optionally clear other related properties
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator!;
    this.dataSource.sort = this.sort!;
  }

  loadAllNPINo() {

    this.ediStoreService.getAllNPINo().subscribe({
			next: (response) => {
			  if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.storeArray = receivedVendorResponse;
          this.setValueToNPIDropdown();

			  } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

				this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
			  }
			},
			error: (errorResponse) => {
			  console.log(errorResponse);
			  this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
			}
		  });
  }

  setValueToNPIDropdown() {

    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }

  loadMasterVendors() {

    let vendorData = {};
    this.vendorService.getMasterVendor(vendorData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.vendorMasterArray = receivedVendorResponse;
          this.vendorMasterArray = this.vendorMasterArray.filter(vendor => vendor.isActive);
          this.getUnMappedVendors(this.userAccessData.NPINo);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }
  //==========getting the user ===============
  getUsersOnLoad() {


    // setting request object
    //this.userRequest.NPINo = this.userAccessData.NPINo;
    if(this.vendor.NPINo=="" || this.vendor.NPINo=="All") this.vendor.NPINo="0000000000";
    this.userRequest.NPINo=this.vendor.NPINo;
    this.userRequest.pagination.pageNumber = this.currentPage + 1;
    this.userRequest.pagination.pageSize = this.pageSize;

    this.userService.getUsers(this.userRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedUserResponse = JSON.parse(response.data);

          this.dataSource = new MatTableDataSource<User>(receivedUserResponse.records);

          this.dataSource.paginator = this.paginator!;
          this.dataSource.sort = this.sort!;

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching the user data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }



  openUserCreateModal() {

    const userCreateDialogRef = this.dialogService.open(UserModalComponent, {
      width: '50%',
      disableClose: true,
      data: { headerTitle: "Create User" }
    });

    userCreateDialogRef.afterClosed().subscribe(result => {
      //getting users on load for showing in the table after creating the user
      if(result==true){
      this.getUsersOnLoad();
      }
    });

  }


  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }


  getUsers() {

    // setting request object
    //this.userRequest.NPINo = this.userAccessData.NPINo;
    if(this.vendor.NPINo=="" || this.vendor.NPINo=="All") this.vendor.NPINo="0000000000";
    this.userRequest.NPINo=this.vendor.NPINo;
    this.userRequest.userName = this.user.userName;
    this.userRequest.pagination.pageNumber = this.currentPage + 1;
    this.userRequest.pagination.pageSize = this.pageSize;

    this.userService.getUsers(this.userRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          this.resultData = JSON.parse(response.data);
          this.dataSource = new MatTableDataSource<User>(this.resultData.records);

          this.dataSource.paginator = this.paginator!;
          this.dataSource.sort = this.sort!;

          setTimeout(() => {

            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = this.resultData.totalRecords;

          });

          if (this.resultData.records.length < 1)
            this.utilityService.showAlert(
              "WARNING",
              "Warning",
              "Data is unavailable for the selected criteria"
            );

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching the user data")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    if (event.pageIndex != event.previousPageIndex)
      this.getUsers();


  }



  openUpdateUserModal(receivedUser: User) {

    console.log(receivedUser);

    const userUpdateDialogRef = this.dialogService.open(UserModalComponent, {
      width: '50%',
      disableClose: true,
      data: { userData: receivedUser, headerTitle: "Update User" }
    });

    userUpdateDialogRef.afterClosed().subscribe(result => {

      if(result==true)
      //getting users on load for showing in the table after updating the user
      this.getUsersOnLoad();
    });
  }

  deleteUser(receivedUser: User) {
    let passData = {
      promptTitle: "Are you sure ?",
      promptMessage: "You won't be able to revert this!",
    };

    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {

      if (result == true) {
        this.userService.deleteUsers(receivedUser).subscribe({
          next: (response) => {
            if (response.result == ResponseStatus.SUCCESS) {
              this.utilityService.showAlert(
                constant.KEY_SUCCESS,
                constant.ALERT_DELETE_TITLE,
                "User deleted successfully"
              ).subscribe(result => {
                if (result == true)
                  this.dialogService.closeAll()
                //getting users on load for showing in the table after deleting the user
                this.getUsersOnLoad();
              });


            } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

              this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while deleting the user data")
            }
          },
          error: (errorResponse) => {
            console.log(errorResponse);
            this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
          }
        });
      }
    });

  }

  loadStoreNpi() {
    this.ediStoreService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.storeNpiList = receivedVendorResponse;

          const pharmacyCreateRequest: PharmacyCreateRequest = {
            adminFirstName: '',
            adminLastName: '',
            pharmacyEmail: '',
            pharmacyPhoneNumber: '',
            contactPerson: '',
            pharmacyFaxNumber: '',
            rxGenSys: 0, // Assuming rxGenSys is a number property, initialize with appropriate default value
            pharmacyAddressLine1: '',
            pharmacyAddressLine2: '', // If you want to set it to null, remove this line
            masterDbName: '',
            pharmacyNpiNumber: '',
            pharmacyZipCode: '',
            pharmacyNABPNum: 0, // Assuming pharmacyNABPNum is a number property, initialize with appropriate default value
            PharmacyStateCode: "", // Assuming PharmacyStateCode is a number property, initialize with appropriate default value
            pharmacyCityId: 0, // Assuming pharmacyCityId is a number property, initialize with appropriate default value
            pharmacyRegistrationNum: '',
            pharmacyDEARegistration: '',
            pharmacyName: '',
            organizationId: 0,
            adminUserName: '',
            adminPassword: '',
            isPrimeRxCloudEmptyPharmacy:false
          };


          const storeAll: Store ={
            mId: '',
            NPINo: 'All',
            pseudoName: '',
            storeName: '',
            address1: '',
            address2: '',
            city: '',
            zipCode: '',
            state: '',
            owner: '',
            selectedPayType:'',
            contactNo: '',
            contactName: '',
            website: '',
            fax: '',
            isActive: false,
            terminal: '',
            isEdiv2: false,
            isMpos: false,
            isStoreAdminCreated: false,
            createdBy: '',
            updatedBy: '',
            createdDate: '',
            updatedDate: '',
            email: '',
            isPrimeRxCloud: false,
            primeRxCloudData: pharmacyCreateRequest,
            isInfoLog: false, // #3787
            allowDefaultItems: false //#3788
            }



          this.storeNpiList.unshift(storeAll);
          this.vendor.NPINo='All';
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error ehile fetching store NPI list")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  setNPINoToModel(event: any) {
    this.vendor.NPINo = event.option.value.NPINo;

    this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

    //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
    this.getUnMappedVendors(this.vendor.NPINo);

  }

  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.existingMappedVendor = receivedVendorResponse;

          //now getting the diffrence between the master vendor and mapped  vendor
          this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
            !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

          //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
          this.setValueToVendorDropdown();
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching mapped vendors data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
