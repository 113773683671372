import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { data } from 'jquery';
import { catchError, map } from 'rxjs';
import { ediconstant } from 'src/app/constant/ediconstant';
import { EdiUserModel } from '../models/EdiUserModel';
import { EdiUtilityService } from './edi-utility.service';
import { TokenStorageService } from './token-storage.service';
import { EDIUser } from '../models/EDIUser';
import { User } from '../models/User';
import { ErrorHandlerService } from './error-handler.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular'

@Injectable({
    providedIn: 'root'
  })
  export class EdiUserService {

    constructor(private httpClient: HttpClient, private router: Router, private dialogService: MatDialog,
       private utilityService: EdiUtilityService, private tokenStorageService: TokenStorageService,
        private idle: Idle,private errorHandlerService: ErrorHandlerService,private auth: Auth0Service) { }
  
    loginUser(user: any) {
      return this.httpClient.post<any>(ediconstant.userLoginUrl, user, ediconstant.httpClientHeader).pipe(
        map((response: any) => {
  
          const receivedDbData: any = JSON.parse(response.data);
  
          if (response.result == "FAILURE" || response.result == "ERROR") {
  
            this.utilityService.showAlert("ERROR", "Failed", receivedDbData.Message);
            return false;
  
           } 
          //else if (data.changePasswordAtLogin) {
          //   const changePasswordRef = this.dialogService.open(ChangePasswordComponent, {
          //     width: '40%',
          //     disableClose: false,
          //     data: { headerTitle: "Change Password at login", userData: data }
          //   });
          //   changePasswordRef.afterClosed().subscribe(result => {
  
          //     location.reload();
  
          //   });
  
          // }
          else {
  
            if (receivedDbData) {
              // ADDING THE DATA TO SESSION FOR USING IN THE APP
  
              this.tokenStorageService.saveToken(receivedDbData.token);
              this.tokenStorageService.saveRefreshToken(receivedDbData.user.refreshToken);
              this.tokenStorageService.saveUser(receivedDbData.user);
  
              sessionStorage.setItem('NPINo', receivedDbData.user.NPINo);
              sessionStorage.setItem('changePasswordAtLogin', receivedDbData.user.changePasswordAtLogin);
              sessionStorage.setItem('userName', receivedDbData.user.userName);
              sessionStorage.setItem('storeName', receivedDbData.storeDetails.storeName);
              sessionStorage.setItem('pseudoName', receivedDbData.storeDetails.pseudoName);
              sessionStorage.setItem('userRights', JSON.stringify(receivedDbData.userRights));
  
            }
            return response;
          }
        })
      )
  
    }
  
    getEdiUsersAccessData(): any {
      let NPINo = sessionStorage.getItem('NPINo');
      //let changePasswordAtLogin = JSON.parse(sessionStorage.getItem('changePasswordAtLogin') || '');
      let changePasswordAtLoginJson = sessionStorage.getItem('changePasswordAtLogin');
      let changePasswordAtLogin: any; // Define the type according to your expected structure
      if (changePasswordAtLoginJson) {
        try {
          changePasswordAtLogin = JSON.parse(changePasswordAtLoginJson);
        } catch (error) {
          console.error('Error parsing changePasswordAtLogin JSON:', error);
        }
      } else {
        console.warn('No changePasswordAtLogin data found in sessionStorage.');
      }
      let userName = sessionStorage.getItem('userName');
      let storeName = sessionStorage.getItem('storeName');
      let pseudoName = sessionStorage.getItem('pseudoName');
      let userRights = sessionStorage.getItem('userRights');
  
      var returnUserData = {
        NPINo: NPINo,
        userName: userName,
        storeName: storeName,
        pseudoName: pseudoName,
        userRights: userRights,
        changePasswordAtLogin: changePasswordAtLogin
      };
      return returnUserData;
    }

    
  
    getUsers(user: any) {
      return this.httpClient.post<any>(ediconstant.getUsersUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
    getUserByUserName(user: any) {
      return this.httpClient.post<any>(ediconstant.getUserByUserNameUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
    createUsers(user: EDIUser) {
      return this.httpClient.post<any>(ediconstant.insertUserUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
    changeUserPassword(user: EDIUser) {
      return this.httpClient.post<any>(ediconstant.changeUserPasswordUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
    updateUsers(user: EDIUser) {
      return this.httpClient.post<any>(ediconstant.updateUserUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
    }
  
    deleteUsers(user: User) {
      return this.httpClient.post<any>(ediconstant.deleteUserUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
    logOutUser() {
      //removing the user session and then user will log out 
      sessionStorage.clear();
      this.auth.logout({
        logoutParams: {
          returnTo: window.location.origin // This is where the user will be redirected after logout
        }
      });
      this.router.navigateByUrl('/login');
    }
  
    //getting the user from store configurator
    changePasswordAtLogin(user: EDIUser) {
      return this.httpClient.post<any>(ediconstant.changeUserPasswordAtLoginUrl, user, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
  
    refreshToken(refToken: string) {
      const params = new URLSearchParams();
      params.set('refToken', refToken);
      return this.httpClient.post<any>(ediconstant.refreshToken, params, ediconstant.httpClientHeader).pipe(
        catchError((error: HttpErrorResponse) => this.errorHandlerService.handleHttpError(error))
      );
  
    }
  
    logOutUserFromTimeOut() {
      //removing the user session and then user will log out 
      this.dialogService.closeAll();
      sessionStorage.clear();
      this.auth.logout({
        logoutParams: {
          returnTo: window.location.origin // This is where the user will be redirected after logout
        }
      });
      this.idle.watch();//restart watching the user for idleness
      this.router.navigateByUrl('/login');
      this.utilityService.showAlert("SUCCESS", "Logged Out", "You have been logged out due to inactivity");
    }
  
  }