import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimSpaceInput]'
})
export class TrimSpaceInputDirective {



  constructor(private el: ElementRef, private ngControl: NgControl) { }

  @HostListener('input') onInput() {
    this.trim();
  }

  @HostListener('blur') onBlur() {
    this.trimLength();
  }

  private trimLength() {
    const value = this.el.nativeElement.value;
    this.el.nativeElement.value = value.trim();
  }


  // private trim() {
  //   const value = this.el.nativeElement.value;
  //   if (value.trim().length < 1)
  //     this.el.nativeElement.value = value.trim();
  // }



  private trim() {
    const value = this.el.nativeElement.value;
    const trimmedValue = value.trim();
    if (trimmedValue.length < 1) {
      this.el.nativeElement.value = trimmedValue;
      this.ngControl?.control?.setValue(trimmedValue);
      this.ngControl?.control?.updateValueAndValidity();
    }
  }

}
