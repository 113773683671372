import { Injectable } from '@angular/core';
import { constant } from '../constant/constant';



@Injectable({
  providedIn: 'root'
})

export class TokenStorageService {
  constructor() { }
  signOut(): void {
    window.sessionStorage.clear();
  }
  public saveToken(token: string): void {
    window.sessionStorage.removeItem(constant.TOKEN_KEY);
    window.sessionStorage.setItem(constant.TOKEN_KEY, token);
    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }
  public saveAuth0Token(token: string): void {
    window.sessionStorage.removeItem(constant.AUTH0_TOKEN_KEY);
    window.sessionStorage.setItem(constant.AUTH0_TOKEN_KEY, token);
    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }
  public getToken(): string | null {
    return window.sessionStorage.getItem(constant.TOKEN_KEY);
  }
  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(constant.REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(constant.REFRESHTOKEN_KEY, token);
  }
  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(constant.REFRESHTOKEN_KEY);
  }
  
  public saveUser(user: any): void {
    window.sessionStorage.removeItem(constant.USER_KEY);
    window.sessionStorage.setItem(constant.USER_KEY, JSON.stringify(user));

    window.sessionStorage.removeItem(constant.USER_NAME);
    window.sessionStorage.setItem(constant.USER_NAME, user.userName);

    window.sessionStorage.removeItem(constant.NPINO);
    window.sessionStorage.setItem(constant.NPINO, user.NPINo);

    window.sessionStorage.removeItem(constant.USER_PSEUDONAME);
    window.sessionStorage.setItem(constant.USER_PSEUDONAME,user.pseudoName);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(constant.USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }
}
