<h2 mat-dialog-title>{{data.alertTitle}}</h2>
<div>


    <!-- <div fxLayout="row wrap">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="45" fxFlex.gt-md="45" fxFlex.gt-xs="100" fxFlex="100">
            </div>
            <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                <mat-progress-spinner mode="determinate" diameter="100" [color]="colorValue" [value]="counter"></mat-progress-spinner>
                <h1 class="countdown" style="padding-top:20px;">{{counter}}</h1>
            </div>
            <div fxFlex.gt-lg="45" fxFlex.gt-md="45" fxFlex.gt-xs="100" fxFlex="100">
            </div>
        </div>
    </div> -->

    <section fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="30px">

        <div fxFlex>
            <mat-progress-spinner mode="determinate" diameter="120" [color]="colorValue" [value]="counter">
            </mat-progress-spinner>
            <h1 style="padding-top: 15px;">{{showCounter}}</h1>

        </div>

        <div fxFlex>

            <label>{{data.alertMessage}}</label>

        </div>

    </section>
</div>
<!-- <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close color="warn" (click)="onCancelClick()">Cancel</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>LogOut</button>
</div> -->

<div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
    <button mat-stroked-button mat-dialog-close color="warn" (click)="onCancelClick()">Cancel</button>
    <button mat-flat-button color="warn" [mat-dialog-close]="true" (click)="logoutUser()" cdkFocusInitial>LogOut</button>
  </div>