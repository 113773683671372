//#region EPPOS-3755
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { constant } from 'src/app/constant/constant';

@Injectable({
  providedIn: 'root'
})

export class StateMasterService {

  constructor(private httpClient: HttpClient) { }

  searchStateCityByZip(zipCode: any) {
    return this.httpClient.post<any>(constant.stateCityByZip, zipCode, constant.httpClientHeader).pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
//#endregion
