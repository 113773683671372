<button class="p-button p-button-outlined" 
    [class.p-button-secondary]="type === 'secondary'"
    [class.p-button-success]="type === 'success'"
    [class.p-button-info]="type === 'info'"
    [class.p-button-help]="type === 'help'"
    [class.p-button-warning]="type === 'warning'"
    [class.p-button-tertiary]="type === 'tertiary'"
    [class.p-button-sm]="size === 'sm'"
    [style.cursor]="needCursor ? 'pointer':'default'"
     >

    {{ btnLabel }}
</button>


